export const ViolationTypes = {
  ATTENDANCE_ISSUES: 'ATTENDANCE_ISSUES',
  LATE_TO_WORK: 'LATE_TO_WORK',
  UNAUTHORIZED_ABSENCE: 'UNAUTHORIZED_ABSENCE',
  EXCESSIVE_ABSENTEEISM: 'EXCESSIVE_ABSENTEEISM',
  BEHAVIORAL_MISCONDUCT: 'BEHAVIORAL_MISCONDUCT',
  DISRESPECTFUL_BEHAVIOR: 'DISRESPECTFUL_BEHAVIOR',
  INSUBORDINATION: 'INSUBORDINATION',
  HARASSMENT: 'HARASSMENT',
  WORKPLACE_BULLYING: 'WORKPLACE_BULLYING',
  POLICY_VIOLATIONS: 'POLICY_VIOLATIONS',
  BREACH_OF_COMPANY_POLICIES: 'BREACH_OF_COMPANY_POLICIES',
  MISUSE_OF_COMPANY_RESOURCES: 'MISUSE_OF_COMPANY_RESOURCES',
  CONFIDENTIALITY_BREACH: 'CONFIDENTIALITY_BREACH',
  PERFORMANCE_ISSUES: 'PERFORMANCE_ISSUES',
  POOR_PERFORMANCE: 'POOR_PERFORMANCE',
  FAILURE_TO_MEET_DEADLINES: 'FAILURE_TO_MEET_DEADLINES',
  ETHICAL_VIOLATIONS: 'ETHICAL_VIOLATIONS',
  CONFLICT_OF_INTEREST: 'CONFLICT_OF_INTEREST',
  FRAUD: 'FRAUD',
  MISREPRESENTATION_OF_WORK: 'MISREPRESENTATION_OF_WORK',
  SAFETY_VIOLATIONS: 'SAFETY_VIOLATIONS',
  IGNORING_SAFETY_PROTOCOLS: 'IGNORING_SAFETY_PROTOCOLS',
  UNSAFE_WORK_PRACTICES: 'UNSAFE_WORK_PRACTICES',
  OTHER: 'OTHER',
  CUSTOM_VIOLATION: 'CUSTOM_VIOLATION',
};

export const QuartersTypes = {
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4',
};

export const GrievanceTypes = {
  WORKPLACE_HARASSMENT: 'WORKPLACE_HARASSMENT',
  DISCRIMINATION: 'DISCRIMINATION',
  POLICY_VIOLATION: 'POLICY_VIOLATION',
  BULLYING: 'BULLYING',
  SAFETY_CONCERNS: 'SAFETY_CONCERNS',
  UNFAIR_TREATMENT: 'UNFAIR_TREATMENT',
  WAGE_DISCREPANCY: 'WAGE_DISCREPANCY',
  RETALIATION: 'RETALIATION',
  INADEQUATE_TRAINING: 'INADEQUATE_TRAINING',
  HARASSMENT_OTHER: 'HARASSMENT_OTHER',
  DISRESPECT_FROM_COLLEAGUES: 'DISRESPECT_FROM_COLLEAGUES',
  WORKLOAD_ISSUES: 'WORKLOAD_ISSUES',
  JOB_SECURITY_CONCERNS: 'JOB_SECURITY_CONCERNS',
  CONFLICT_RESOLUTION_ISSUES: 'CONFLICT_RESOLUTION_ISSUES',
  OTHER: 'OTHER',
};

export const GrievanceStatusTypes = {
  PENDING: 'PENDING',
  UNDER_REVIEW: 'UNDER_REVIEW',
  RESOLVED: 'RESOLVED',
};

export const APPROVAL_STATUS_OPTIONS = [
  { value: 'PENDING_APPROVAL', label: 'Pending Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
];

export const UtilityConstants = {
  FILE_MISSING: 'File Missing',
  UNKNOWN: 'unknown',
  EVENT_PROP_INITIAL_VALUE: '--',
};

export const EventStatusConstants = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const EventTypes = {
  PROMOTION_WITH_HIKE: 'PROMOTION_WITH_HIKE',
  DESIGNATION_CHANGE: 'DESIGNATION_CHANGE',
  SALARY_HIKE: 'SALARY_HIKE',
  VIOLATION: 'VIOLATION',
  QUARTERLY_VARIABLE_PAY: 'QUARTERLY_VARIABLE_PAY',
  DISCUSSION_NOTE: 'DISCUSSION_NOTE',
  GRIEVANCE: 'GRIEVANCE',
} as const;

export const fileTypes = ['JPG', 'PNG', 'PDF', 'XLSX', 'DOCX'];

export const FormikConstants = {
  id: { ID: 'id', LABEL: 'Id:' },
  eventType: { ID: 'eventType', LABEL1: 'Event:', LABEL2: 'Event Type:' },
  effectiveDate: {
    ID: 'effectiveDate',
    LABEL1: 'Effective Date:',
    LABEL2: 'Date:',
  },
  supportingDocuments: {
    ID: 'supportingDocuments',
    LABEL: 'Supporting Documents:',
  },
  isNew: { ID: 'isNew', LABEL: 'Is New:' },
  referenceDocs: { ID: 'referenceDocs', LABEL: 'Reference Docs:' },
  previousDesignation: {
    ID: 'previousDesignation',
    LABEL: 'Previous Designation:',
  },
  currentDesignation: {
    ID: 'currentDesignation',
    LABEL1: 'Current Designation:',
    LABEL2: 'Designation:',
    LABEL3: 'New Designation:',
  },
  previousDepartment: {
    ID: 'previousDepartment',
    LABEL: 'Previous Department:',
  },
  currentDepartment: {
    ID: 'currentDepartment',
    LABEL1: 'Current Department:',
    LABEL2: 'Department:',
    LABEL3: 'New Department:',
  },
  revisionDate: { ID: 'revisionDate', LABEL: 'Revision Date:' },
  approvalStatus: { ID: 'approvalStatus', LABEL: 'Approval Status:' },
  approverName: { ID: 'approverName', LABEL: 'Approver Name:' },
  comments: { ID: 'comments', LABEL: 'Comments:' },
  hikePercentage: {
    ID: 'hikePercentage',
    LABEL1: 'Hike Percentage:',
    LABEL2: 'Hike Percentage Increment:',
  },
  violationType: {
    ID: 'violationType',
    LABEL: 'Violation Type:',
    DEFAULT_VALUE: 'Select Violation Type',
  },
  actionTaken: { ID: 'actionTaken', LABEL: 'Action Taken:' },
  quarter: { ID: 'quarter', LABEL: 'Quarter:' },
  quarterlyPayAmount: {
    ID: 'quarterlyPayAmount',
    LABEL: 'Quarterly Pay Amount:',
  },
  performanceScore: { ID: 'performanceScore', LABEL: 'Performance Score:' },
  grievanceType: {
    ID: 'grievanceType',
    LABEL: 'Grievance Type:',
    DEFAULT_VALUE: 'Select Grievance Type',
  },
  grievanceDescription: {
    ID: 'grievanceDescription',
    LABEL: 'Grievance Description:',
  },
  preferredResolution: {
    ID: 'preferredResolution',
    LABEL: 'Preferred Resolution:',
  },
  discussionType: { ID: 'discussionType', LABEL: 'Discussion Type:' },
  participants: { ID: 'participants', LABEL: 'Participants:' },
};
export const ProfileConstants = {
  DEPARTMENT: 'department',
  DESIGNATION: 'designation',
  LAST_REVISION_DATE: 'lastRevisionDate',
};
export const StorageBucketConstants = {
  EMP_MASTER: 'empMaster',
};
export interface EventAccordionProps {
  event: AnyOf7Events;
  onSave: any;
  updatePromotion: any;
  downloadFiles: any;
  deletePromotion: any;
  isAdmin: boolean;
}

export interface EventAccordionListProps {
  userData: any;
  setOpenFormExitPopup: any;
  isAdmin: boolean;
}

export interface EventAccordionWrapperProps extends EventAccordionProps {
  allFiles: any;
}

export type EventType = (typeof EventTypes)[keyof typeof EventTypes];

export type AnyOf7Events =
  | PromotionWithHike
  | DesignationChange
  | SalaryHike
  | QuarterlyVariablePay
  | Violation
  | EmployeeGrievance
  | DiscussionNote;

// 1. Promotion with Hike

interface BaseEvent {
  id: number;
  effectiveDate: string;
  supportingDocuments?: string[];
  isNew?: boolean;
  referenceDocs?: any[];
}
export interface PromotionWithHike extends BaseEvent {
  eventType: typeof EventTypes.PROMOTION_WITH_HIKE;
  previousDesignation: string;
  currentDesignation: string;
  previousDepartment: string;
  currentDepartment: string;
  hikePercentage: number;
  revisionDate: string;
  approvalStatus: string;
  approverName: string;
  comments: string;
}

// 2. Promotion without Hike
export interface DesignationChange extends BaseEvent {
  eventType: typeof EventTypes.DESIGNATION_CHANGE;
  previousDesignation: string;
  currentDesignation: string;
  previousDepartment: string;
  currentDepartment: string;
  revisionDate: string;
  approvalStatus: string;
  approverName: string;
  comments: string;
}

// 3. Salary Hike
export interface SalaryHike extends BaseEvent {
  eventType: typeof EventTypes.SALARY_HIKE;
  hikePercentage: number;
  revisionDate: string;
  approverName: string;
  approvalStatus: string;
  performanceScore: number;
  comments: string;
}

// 4. Violation
export interface Violation extends BaseEvent {
  eventType: typeof EventTypes.VIOLATION;
  violationType: string;
  actionTaken?: string;
}

// 5. Quarterly Variable Pay
export interface QuarterlyVariablePay extends BaseEvent {
  eventType: typeof EventTypes.QUARTERLY_VARIABLE_PAY;
  quarter: string; // e.g., "Q1", "Q2", etc.
  quarterlyPayAmount: number;
  performanceScore: number;
  comments: string;
}

// 6. Employee Grievance
export interface EmployeeGrievance extends BaseEvent {
  eventType: typeof EventTypes.GRIEVANCE;
  grievanceType: string;
  grievanceDescription: string;
  preferredResolution: string;
  comments: string;
}
export interface DiscussionNote extends BaseEvent {
  eventType: typeof EventTypes.DISCUSSION_NOTE;
  discussionType: string;
  participants: string;
  comments: string;
}
