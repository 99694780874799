import wretch from 'wretch';
import CONSTANTS from '../constants/constants';
import { getSessionStorageItem } from '../encrypt';

const COMMON_BACKEND_ENDPOINT = '/emp360-backend/v1';
const COMMON_PROMOTION_URL = 'event-history';
const GET_PROMOTION_BY_EMPLOYEE_ID = 'event-history';
const DELETE_SPECIFIC_PROMOTION = 'delete-event';

export const saveEventHistoryToDatabase = async (payload) => {
  const url = `${getSessionStorageItem(
    'gatewayURL'
  )}${COMMON_BACKEND_ENDPOINT}/${COMMON_PROMOTION_URL}`;

  const response = await wretch(url)
    .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
    .post(payload)
    .json();
  return { data: response, success: true };
};

export const fetchEventHistoryByEmployeeId = async (employeeId: string) => {
  const url = `${getSessionStorageItem(
    'gatewayURL'
  )}${COMMON_BACKEND_ENDPOINT}/${GET_PROMOTION_BY_EMPLOYEE_ID}/${employeeId}`;

  const response = await wretch(url)
    .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
    .get()
    .json();

  return { open: false, success: true, data: response };
};

export const deleteSpecificEventFromHistory = async (payload: any) => {
  const url = `${getSessionStorageItem(
    'gatewayURL'
  )}${COMMON_BACKEND_ENDPOINT}/${COMMON_PROMOTION_URL}/${DELETE_SPECIFIC_PROMOTION}`;

  const response = await wretch(url)
    .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
    .json(payload)
    .delete();

  return { data: response, success: true };
};
