import React from 'react';
import Box from '@mui/material/Box';
import FormsDetails from './formsDetail';
import { useDispatch } from 'react-redux';
import {
  setOfficialEmailId,
  setReportingToEmailId,
} from '../../../redux/actions';
import Modal from '@mui/material/Modal';
import { FormExitPopupActions } from '../../FormExitPopup';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: '#fff',
  boxShadow: 24,
  overflowY: 'scroll',
  height: '90vh',
  display: 'block',
};

const LeadModal = (props) => {
  const { show, setShow, userData } = props;

  const dispatch = useDispatch();

  const [openFormExitPopup, setOpenFormExitPopup] = React.useState(false);
  const [isOpenFormExitPopup, setIsOpenExitPopup] = React.useState(false);

  const handleCloseForm = () => {
    setIsOpenExitPopup(openFormExitPopup);
    if (!openFormExitPopup) {
      closeModal(false);
    }
  };
  const closeModal = (value: any) => {
    setShow(value);
    dispatch(setOfficialEmailId(''));
    dispatch(setReportingToEmailId(''));
  };

  const formExitCallBackAction = (action: any) => {
    if (action === FormExitPopupActions.CANCEL) setIsOpenExitPopup(false);
    else if (action === FormExitPopupActions.LEAVE) closeModal(false);
  };

  return (
    <>
      <Modal open={show} onClose={handleCloseForm}>
        <Box sx={style}>
          <FormsDetails
            setOpen={setShow}
            closeModal={setShow}
            openFormExitPopup={openFormExitPopup}
            setOpenFormExitPopup={setOpenFormExitPopup}
            isOpenFormExitPopup={isOpenFormExitPopup}
            setIsOpenExitPopup={setIsOpenExitPopup}
            formExitCallBackAction={formExitCallBackAction}
            handleCloseForm={handleCloseForm}
            userData={userData}
          />
        </Box>
      </Modal>
    </>
  );
};
export default LeadModal;
