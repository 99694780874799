import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React from 'react';
import { makeStyles } from '@mui/styles';
import CompOffRequestForm from './compOffRequestForm';

const ApplyCompOffRequestModal = ({ isModalOpen, closeModal, empId }) => {
  const classes = useStyles();

  return (
    <>
      <Modal open={isModalOpen} onClose={closeModal}>
        <div className={`${classes.modalRoot} modal-container`}>
          <Box className={classes.screenHeadingContainer}>
            <Box display={'flex'} alignSelf={'center'} margin={'0 auto'}>
              <Typography
                variant="h6"
                sx={{
                  '@media (min-width: 2000px)': {
                    fontSize: 'calc(12px + 1.8rem)',
                  },
                  '@media (min-width: 1500px) and (max-width: 2000px)': {
                    fontSize: 'calc(12px + 1.2rem)',
                  },
                  '@media (min-width: 1000px) and (max-width: 1500px)': {
                    fontSize: 'calc(12px + 0.08rem)',
                  },
                  '@media (min-width: 600px) and (max-width: 1000px)': {
                    fontSize: 'calc(12px + 0.008rem)',
                  },
                  '@media (max-width: 600px)': {
                    fontSize: 'calc(12px + 0.001rem)',
                  },
                }}
              >
                Request to add a Compensatory Off
              </Typography>
            </Box>
            <Box>
              <Button sx={{ color: '#FFFF' }} onClick={closeModal}>
                <CloseOutlinedIcon />
              </Button>
            </Box>
          </Box>
          <div>
            <CompOffRequestForm empId={empId} closeModal={closeModal} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplyCompOffRequestModal;

const useStyles = makeStyles(() => ({
  screenHeadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#162952',
    height: '50px',
    color: '#FFFFFF',
  },
  modalRoot: {
    maxHeight: '90%',
    width: '75%',
  },
  formContainer: {},
}));
