import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

interface CustomDatePickerProps {
  value: any;
  handleDateChange: (dateString) => void;
  isError: any;
  errorText: any;
  padding: any;
  datePickerdisabled: any;
  textFieldDisabled: any;
  rest: any;
}

const CustomDatePicker = ({
  value,
  handleDateChange,
  isError,
  errorText,
  padding,
  datePickerdisabled = false,
  textFieldDisabled = false,
  rest,
}: CustomDatePickerProps) => {
  const getDate = () => {
    if (dayjs(value)['$d'].toString().toLowerCase().includes('invalid'))
      return null;
    return dayjs(value);
  };
  const [dateValue, setDateValue] = useState(getDate());
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  useEffect(() => {
    setDateValue(getDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onOpen={() => setDatePickerOpen(true)}
        onClose={() => setDatePickerOpen(false)}
        open={datePickerOpen}
        {...rest}
        value={dateValue}
        format={DatePickerConstants.dateFormat}
        disabled={datePickerdisabled}
        slotProps={{
          field: {
            clearable: false,
            onClear: () => {
              handleDateChange(null);
            },
          },

          textField: {
            helperText: errorText,
            disabled: textFieldDisabled,
            // contentEditable: false,
            sx: datePickerStyles(
              isError,
              padding,
              textFieldDisabled,
              datePickerdisabled
            ),
          },
        }}
        onChange={(date: Date) => {
          try {
            handleDateChange(
              dayjs(date.toISOString()).format(
                DatePickerConstants.conversionFormat
              )
            );
          } catch (error) {
            console.error('Error: ', error);
          }
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;

const DatePickerColors = {
  baseColor: '#ca4545',
  iconButtonColor: '#f15830',
  grey: 'grey',
  black: 'black',
};
const DatePickerConstants = {
  dateFormat: 'DD/MM/YYYY',
  conversionFormat: 'YYYY-MM-DD',
};
const datePickerStyles = (
  isError,
  padding,
  textFieldDisabled,
  datePickerdisabled
) => {
  return {
    '& .MuiFormHelperText-root': {
      color: `${DatePickerColors.baseColor} !important`,
      display: isError ? 'static' : 'none',
    },
    '& .MuiInputBase-input': {
      boxSizing: 'border-box !important',
      padding: padding + ' !important',
    },
    '& .MuiIconButton-root': {
      color: datePickerdisabled
        ? DatePickerColors.grey
        : DatePickerColors.iconButtonColor,
    },
    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
      opacity: 1,
      WebkitTextFillColor:
        textFieldDisabled && datePickerdisabled
          ? DatePickerColors.grey
          : DatePickerColors.black,
    },
  };
};
