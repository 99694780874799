import React, { CSSProperties, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
// import { pushNotification } from "../../redux/actions";
import { uploadFileApi } from '../services/UploadFile';
import {
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from '../services/formService';
import { DOCUMENTS_UPLOAD_FORM_ID } from '../constants/formConstants';

import { store } from '../redux/store';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../redux/actions';
import ClipLoader from 'react-spinners/ClipLoader';
import { deleteDocument, downloadFile } from '../services/DocumentService';
import './document.css';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CONSTANTS from '../constants/constants';
import { getSessionStorageItem } from '../encrypt';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Documents = (props: any) => {
  const dispatch = useDispatch();
  const reduxState = store.getState();
  const fileTypes = ['JPG', 'PNG', 'PDF', 'XLSX', 'DOCX'];
  const [allFiles, setAllFiles] = useState<any>([]);
  const [formId, setFormId] = useState('');

  const emailId: any = getSessionStorageItem('user-email');

  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    borderColor: '#4B6D8F',
  };

  const color = '#ffffff';
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    prefillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  const handleFileChange = async (file) => {
    setLoading(true);
    const Files = [...file];

    const filesDataArray = await Promise.all(
      Files.map(async (item: any) => {
        const response: any = await uploadFileApi(
          item.name,
          item,
          'empMaster',
          {}
        );

        const data = {
          fileName: item.name,
          fileType: item.type,
          fileSize: item.size,
          documentId: response.data.id,
          status: response.success,
        };
        return data;
      })
    );

    setAllFiles([...allFiles, ...filesDataArray]);
    setLoading(false);
    submitFormData([...allFiles, ...filesDataArray], formId);
  };

  const deleteFile = async (d, i) => {
    await deleteDocument(d.documentId);
    const names = [...allFiles];
    names.splice(i, 1);
    setAllFiles(names);
    submitFormData(names, formId);
  };

  const downloadFiles = async (id, name) => {
    await downloadFile(id, name);
  };

  // const handleStatusChange = async () => {
  //   let payload = {
  //     formId: PERSONAL_FORM_ID,
  //     id: reduxState.parentDataId,
  //     formData: {
  //       status: "active",
  //     },
  //   };
  //   const response = await patchDataToForm(payload);
  // };

  const submitFormData = async (allFiles, formId) => {
    if (formId) {
      const payload = {
        formId: DOCUMENTS_UPLOAD_FORM_ID,
        id: formId,
        formData: {
          reportingTo: reduxState.reportingToEmailId,
          officialEmail: emailId,
          files: allFiles,
        },
      };
      const response: any = await postForm(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS.SUCCESS,
          })
        );
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    } else {
      const payload = {
        formId: DOCUMENTS_UPLOAD_FORM_ID,
        formData: {
          reportingTo: reduxState.reportingToEmailId,
          officialEmail: emailId,
          files: allFiles,
        },
      };
      const response = await postForm(payload);

      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    }
  };

  const prefillData = async () => {
    const response: any = await FilterFormDetailsWithOfficalEmail(
      DOCUMENTS_UPLOAD_FORM_ID,
      emailId
    );

    if (response?.success && response?.data?.content?.length > 0) {
      setFormId(response?.data?.content[0]?.id);
      setAllFiles(response?.data?.content[0]?.formData?.files || []);
    } else {
      setFormId('');
      setAllFiles([]);
    }
  };

  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={3}
        mb={2}
      >
        <Typography
          color={'#f15830'}
          width="100%"
          sx={{
            '@media (min-width: 2000px)': {
              fontSize: 'calc(12px + 1.4rem)',
            },
            '@media (min-width: 1500px) and (max-width: 1999px)': {
              fontSize: 'calc(12px + 0.8rem)',
            },
            '@media (min-width: 1000px) and (max-width: 1499px)': {
              fontSize: 'calc(12px + 0.8rem)',
            },
            '@media (min-width: 600px) and (max-width: 999px)': {
              fontSize: 'calc(12px + 0.4rem)',
            },
            '@media (max-width: 600px)': {
              fontSize: 'calc(12px + 0.2rem)',
            },
          }}
        >
          Documents <Divider />
        </Typography>
        <ClipLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />

        <FileUploader
          multiple={true}
          handleChange={handleFileChange}
          name="file"
          types={fileTypes}
        />
      </Box>

      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={3}
      >
        {allFiles?.map((d: any, i: number) => (
          <Box
            key={d.id || i}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
            border={'1px solid  #C3C3C3'}
            borderRadius={'15px'}
            padding={1}
          >
            {d.fileType === 'image/png' && (
              <img
                style={{ height: '50px', width: '50px' }}
                src="https://cdn-icons-png.flaticon.com/512/337/337948.png"
                alt="icon"
              />
            )}
            {d.fileType === 'application/pdf' && (
              <img
                style={{ height: '50px', width: '50px' }}
                src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
                alt="icon"
              />
            )}
            {d.fileType === 'image/jpg' && (
              <img
                style={{ height: '50px', width: '50px' }}
                src="https://www.svgrepo.com/show/255826/jpg.svg"
                alt="icon"
              />
            )}
            {d.fileType === 'doc' && (
              <img
                style={{ height: '50px', width: '50px' }}
                src="https://www.svgrepo.com/show/255810/doc.svg"
                alt="icon"
              />
            )}

            {d.fileType === 'text/csv' && (
              <img
                style={{ height: '50px', width: '50px' }}
                src="https://cdn-icons-png.flaticon.com/512/172/172534.png"
                alt="icon"
              />
            )}

            {d.fileType === 'image/jpeg' && (
              <img
                style={{ height: '50px', width: '50px' }}
                src="https://cdn-icons-png.flaticon.com/512/337/337940.png"
                alt="icon"
              />
            )}

            {d.fileType === 'text/plain' && (
              <img
                style={{ height: '50px', width: '50px' }}
                src="https://cdn-icons-png.flaticon.com/512/2656/2656402.png"
                alt="icon"
              />
            )}

            {d.fileType ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && (
              <img
                style={{ height: '50px', width: '50px' }}
                src="https://img.icons8.com/external-fauzidea-flat-fauzidea/256/external-docx-file-file-extension-fauzidea-flat-fauzidea.png"
                alt="icon"
              />
            )}

            {d.fileType ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
              <img
                style={{ height: '50px', width: '50px' }}
                src="https://cdn-icons-png.flaticon.com/512/9496/9496502.png"
                alt="icon"
              />
            )}

            <Box
              sx={{
                width: '100px',
                wordWrap: 'break-word',
              }}
            >
              {d.fileName}
            </Box>
            <Box>
              <IconButton
                onClick={() => downloadFiles(d.documentId, d.fileName)}
              >
                <FileDownloadOutlinedIcon sx={{ color: '#10A6F9' }} />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                onClick={() => deleteFile(d, i)}
                sx={{ color: '#DC4955' }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>

            <Box>
              {d.status ? (
                <Tooltip title={'Success'}>
                  <IconButton>
                    <CheckCircleIcon sx={{ color: '#32BEA6' }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={'Failed'}>
                  <IconButton>
                    <ErrorIcon sx={{ color: '#E75454' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Documents;
