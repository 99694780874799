import React, { useEffect, useState } from 'react';
import { SUMMARY_FORM_ID } from '../constants/formConstants';
import {
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from '../services/formService';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../redux/actions';
import CONSTANTS from '../constants/constants';
import { Button } from '@mui/material';
import CustomTextField from './CustomTextField';
import { makeStyles } from '@material-ui/core/styles';
import { getSessionStorageItem } from '../encrypt';

interface SummaryCardProps {
  isQuickView?: boolean;
  userEmail: string;
}

const SummaryCard = ({ isQuickView = false, userEmail }: SummaryCardProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [summary, setSummary] = useState<any>('');
  const [summaryId, setSummaryId] = useState('');
  const [linkedin, setLinkedin] = useState<any>('');
  const [enable, setEnable] = useState(true);
  const [buttonText, setButtonText] = useState('Edit');

  useEffect(() => {
    fetchSummaryDetail(SUMMARY_FORM_ID, userEmail);
  }, [userEmail]);

  const handleSummaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSummary(e.target.value);
  };

  const postSummary = async () => {
    if (summaryId) {
      const payload = {
        formId: SUMMARY_FORM_ID,
        id: summaryId,
        formData: {
          summary: summary,
          officialEmail: getSessionStorageItem('user-email'),
          linkedinUrl: linkedin,
        },
      };
      const response: any = await postForm(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setEnable(true);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
        setEnable(true);
      }
    } else {
      const payload = {
        formId: SUMMARY_FORM_ID,
        formData: {
          summary: summary,
          officialEmail: getSessionStorageItem('user-email'),
          linkedinUrl: linkedin,
        },
      };
      const response = await postForm(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setEnable(true);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
        setEnable(true);
      }
    }
  };

  const fetchSummaryDetail = async (
    formId: string,
    emailId: any
  ): Promise<{ success: boolean; data?: any; message?: string }> => {
    const response = await FilterFormDetailsWithOfficalEmail(formId, emailId);
    if (response?.success && response?.data?.content?.length > 0) {
      setSummary(response?.data?.content[0]?.formData?.summary);
      setSummaryId(response?.data?.content[0]?.id);
      setLinkedin(response?.data?.content[0]?.formData?.linkedinUrl);
    } else {
      setSummary('');
      setSummaryId('');
      setLinkedin('');
    }
    return { success: false };
  };

  useEffect(() => {
    if (enable) {
      setButtonText('Edit');
    } else {
      setButtonText('Save');
    }
  }, [enable]);

  const buttonStyle = {
    textTransform: 'capitalize',
    fontSize: '14px !important',
    '&:hover': {
      boxShadow: '0 0 5px #2c2c2c5e !important',
    },
    ...(enable ? { color: '#F15830' } : { color: '#24af4e' }),
  };

  return (
    <div className="summary">
      <div className={classes.summaryContainer}>
        <div className="summaryButton">
          {!isQuickView && (
            <Button
              onClick={enable ? () => setEnable((prev) => !prev) : postSummary}
              sx={buttonStyle}
            >
              {buttonText}
            </Button>
          )}
        </div>
        <p className="summaryTitleAsset">Summary</p>
      </div>
      {!enable ? (
        <CustomTextField
          handleContentTextChange={handleSummaryChange}
          contentText={summary}
          color={'#162952'}
          fontsize={'16px'}
          height={'170px'}
          width={'100%'}
        />
      ) : (
        <div className={classes.customParagraphStyle}>
          <p>{summary}</p>
        </div>
      )}
    </div>
  );
};

export default SummaryCard;

const useStyles = makeStyles({
  customParagraphStyle: {
    width: '100%',
    height: '170px',
    display: 'flex',
    justifyContent: 'start',
    overflow: 'auto',
    scrollbarWidth: 'none',
    textAlign: 'justify',
    color: '#162952',
    cursor: 'default',
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '50px',
    marginBottom: '5px',
  },
});
