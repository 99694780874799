import { useEffect } from 'react';
import { getSupportedDocsForThisPromotion } from './utilFunctions/promotionUtils';
import {
  EmployeeGrievance,
  EventAccordionWrapperProps,
  EventTypes,
  PromotionWithHike,
  DesignationChange,
  SalaryHike,
  Violation,
  DiscussionNote,
} from './types/EventTypes';
import EventPromotionWithHike from './eventAccordions/EventPromotionWithHike';
import EventPromotionWithoutHike from './eventAccordions/EventDesignationChange';
import EventSalaryHike from './eventAccordions/EventSalaryHike';
import EventViolation from './eventAccordions/EventViolation';
import EventEmployeeGrievance from './eventAccordions/EventEmployeeGrievance';
import EventDiscussionNote from './eventAccordions/EventDicussionNote';

const EventAccordionWrapper = ({
  event,
  downloadFiles,
  allFiles,
  deletePromotion,
  onSave,
  updatePromotion,
  isAdmin,
}: EventAccordionWrapperProps) => {
  useEffect(() => {
    const fetchedSupportingDocs = getSupportedDocsForThisPromotion(
      allFiles,
      event?.supportingDocuments
    );

    // Compare arrays function
    const arraysEqual = (arr1, arr2) => {
      if (arr1?.length !== arr2?.length) return false;
      return arr1?.every(
        (doc, index) => doc?.documentId === arr2?.[index]?.documentId
      );
    };

    // Check if the previously set supportingDocs are different from fetched ones
    if (!arraysEqual(event?.referenceDocs, fetchedSupportingDocs)) {
      event.referenceDocs = fetchedSupportingDocs;
    } else event.referenceDocs = [];
  }, [allFiles, event]);

  return (
    <>
      {event?.eventType === EventTypes?.PROMOTION_WITH_HIKE && (
        <EventPromotionWithHike
          deletePromotion={deletePromotion}
          key={event.id}
          event={event as PromotionWithHike}
          onSave={onSave}
          updatePromotion={updatePromotion}
          downloadFiles={downloadFiles}
          isAdmin={isAdmin}
        />
      )}
      {event?.eventType === EventTypes?.DESIGNATION_CHANGE && (
        <EventPromotionWithoutHike
          deletePromotion={deletePromotion}
          key={event.id}
          event={event as DesignationChange}
          onSave={onSave}
          updatePromotion={updatePromotion}
          downloadFiles={downloadFiles}
          isAdmin={isAdmin}
        />
      )}
      {event?.eventType === EventTypes?.SALARY_HIKE && (
        <EventSalaryHike
          deletePromotion={deletePromotion}
          key={event.id}
          event={event as SalaryHike}
          onSave={onSave}
          updatePromotion={updatePromotion}
          downloadFiles={downloadFiles}
          isAdmin={isAdmin}
        />
      )}
      {event?.eventType === EventTypes?.VIOLATION && (
        <EventViolation
          deletePromotion={deletePromotion}
          key={event.id}
          event={event as Violation}
          onSave={onSave}
          updatePromotion={updatePromotion}
          downloadFiles={downloadFiles}
          isAdmin={isAdmin}
        />
      )}{' '}
      {event?.eventType === EventTypes?.DISCUSSION_NOTE && (
        <EventDiscussionNote
          deletePromotion={deletePromotion}
          key={event.id}
          event={event as DiscussionNote}
          onSave={onSave}
          updatePromotion={updatePromotion}
          downloadFiles={downloadFiles}
          isAdmin={isAdmin}
        />
      )}
      {event?.eventType === EventTypes?.GRIEVANCE && (
        <EventEmployeeGrievance
          deletePromotion={deletePromotion}
          key={event.id}
          event={event as EmployeeGrievance}
          onSave={onSave}
          updatePromotion={updatePromotion}
          downloadFiles={downloadFiles}
          isAdmin={isAdmin}
        />
      )}
    </>
  );
};

export default EventAccordionWrapper;
