import React, { useEffect, useState } from 'react';
import './LeaveDetails.css';
import { isCompOffRequest } from '../../../../services/commonHelperService';
import RectangularLoader from '../../../../Loaders/ContentLoader/Loader';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { LeaveTooltips } from '../../../../constants/constants';
import { leaveTypeNames } from '../../../uiElementsAccess';

const LeaveDetails = (props) => {
  const { color, leaveData, type, isLoading } = props;
  const [backgroundColor, setBackgroundColor] = useState(color);

  useEffect(() => {
    setBackgroundColor(color);
  }, [color]);

  const getContent = (data) => {
    return isLoading ? (
      <div className={'loader-container'}>
        <RectangularLoader />
      </div>
    ) : (
      <span>{data}</span>
    );
  };

  const getTitle = (type) => {
    switch (type) {
      case 'general':
        return LeaveTooltips.GENERAL;
      case 'privilege':
        return LeaveTooltips.PRIVILEGE;
      case 'compOff':
        return LeaveTooltips.COMP_OFF;
      case 'WFH':
        return LeaveTooltips.WFH;
      default:
        return undefined;
    }
  };

  return (
    <div className="leave-details-container">
      <div className={'leave-details-heading-container'}>
        <div className={'leave-details-heading'}>
          <div className={'leave-type-indicator'} style={{ backgroundColor }}>
            <span className={'label-indicator'}>Label color</span>
          </div>
          <div className={'leave-type-heading'}>{leaveTypeNames[type]} </div>
          <div>
            <Tooltip title={getTitle(type)}>
              <InfoOutlinedIcon sx={{ fontSize: 'small', color: 'gray' }} />
            </Tooltip>
          </div>
        </div>
        {isCompOffRequest(type) ? (
          <span className={'comp-off-data'}>
            {getContent(leaveData.expired)} Expired
          </span>
        ) : null}
      </div>
      <div className="leave-details">
        <div className={'details-container'}>
          <span className={'leave-value'}>
            {getContent(leaveData.leaveDetails)}
          </span>
          <span className={'leave-type'}>Available</span>
        </div>
        <div className={'details-container'}>
          <span className={'leave-value'}>
            {getContent(leaveData.consumedLeaveDetails)}
          </span>
          <span className={'leave-type'}>Consumed</span>
        </div>
        <div className={'details-container'}>
          <span className={'leave-value'}>
            {getContent(leaveData.accruedLeaveDetails)}
          </span>
          <span className={'leave-type'}>Accrued So Far</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LeaveDetails);
