import { leaveTypeEnums } from '../screen/uiElementsAccess';
import { PhoneNumberUtil } from 'google-libphonenumber';

const options: any = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  year4Digits: true,
};

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const phoneUtil = PhoneNumberUtil.getInstance();

export function formatPhoneNumber(phoneNumber, defaultCountry = 'IN') {
  try {
    // Parse the phone number with the default country code
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber, defaultCountry);

    // Check if the phone number is valid
    if (!phoneUtil.isValidNumber(parsedPhoneNumber)) {
      throw new Error('Invalid phone number');
    }

    // Format the phone number
    const formattedPhoneNumber = phoneUtil.format(parsedPhoneNumber, 1); // Using enum value for PhoneNumberFormat.INTERNATIONAL

    return formattedPhoneNumber;
  } catch (error) {
    // Handle parsing errors
    console.error('Error parsing phone number:', error);
    // Return the original phone number if parsing fails
    return phoneNumber;
  }
}

// export function getPlainNumber(value: String) {
//   return value?.replace(/[^\d\+\-]/g, "");
// }
export function splitPhoneByCountryCode(country: string, phoneNumber: string) {
  if (!phoneNumber) {
    return '';
  }
  switch (country) {
    case 'in':
      if (phoneNumber[0] === '+') {
        return '+91-' + phoneNumber?.slice(3);
      } else if (phoneNumber.length === 12) {
        return '+91-' + phoneNumber?.slice(2);
      } else if (phoneNumber.length === 10) {
        return '+91-' + phoneNumber;
      }
      break;
    default:
      return 'No matching country found';
  }
}

function EmployeeTenureCount(startDate, endDate) {
  const start = new Date(startDate).getTime(); // Convert to timestamp
  const end = endDate ? new Date(endDate).getTime() : new Date().getTime(); // Convert to timestamp

  if (end < start) {
    return {
      years: 0,
      months: 0,
      days: 0,
    };
  }
  // Calculate the difference in milliseconds
  let diff = end - start;

  // Calculate years
  const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
  diff -= years * (1000 * 60 * 60 * 24 * 365.25);

  // Calculate months
  const months = Math.floor(diff / (1000 * 60 * 60 * 24 * (365.25 / 12)));
  diff -= months * (1000 * 60 * 60 * 24 * (365.25 / 12));

  // Calculate days
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  return { years, months, days };
}

const getFullName = (nameList) => {
  const filteredNames = nameList.filter((nameListItem) => nameListItem);
  return filteredNames.join(' ').trim();
};

// Helper function to check if a date is in the list of holidays
const isHoliday = (date, holidays) => {
  if (!holidays || !Array.isArray(holidays)) {
    return false;
  }

  const dateStr = date.toISOString().split('T')[0]; // Get the date part as a string

  return holidays.includes(dateStr);
};

const getBusinessDaysCount = (startDate, endDate, holidays = []) => {
  // Convert the start and end dates to milliseconds
  const startMs = startDate.getTime();
  const endMs = endDate.getTime();

  // Calculate the number of milliseconds in a day
  const msInDay = 1000 * 60 * 60 * 24;

  // Initialize a counter for the number of business days
  let businessDays = 0;

  // Loop through each day between the start and end dates
  for (let i = startMs; i <= endMs; i += msInDay) {
    const currentDate = new Date(i);

    // If the current day is not a Saturday or Sunday and is not a holiday, increment the businessDays counter
    if (
      currentDate.getDay() !== 0 &&
      currentDate.getDay() !== 6 &&
      !isHoliday(currentDate, holidays)
    ) {
      businessDays++;
    }
  }

  // Return the number of business days
  return businessDays;
};

const getDaysCount = (startDate, endDate) => {
  // Convert the start and end dates to milliseconds
  const startMs = startDate.getTime();
  const endMs = endDate.getTime();

  // Calculate the number of milliseconds in a day
  const msInDay = 1000 * 60 * 60 * 24;

  return (endMs - startMs) / msInDay + 1;
};

const formatDate = (date) => {
  if (date && date !== '...') {
    const dateArray = new Date(date)
      .toLocaleString('en-IN', options)
      .split(' ');
    return dateArray.join('-');
  }
  return '...';
};

const isCompOffRequest = (leaveChoice: string) => {
  return leaveChoice === leaveTypeEnums.COMP_OFF;
};

const isWFHRequest = (leaveChoice: string) => {
  return leaveChoice === leaveTypeEnums.WFH;
};

const isWFHOrCompOffRequest = (leaveChoice: string) => {
  return (
    leaveChoice === leaveTypeEnums.WFH ||
    leaveChoice === leaveTypeEnums.COMP_OFF
  );
};

export {
  EmployeeTenureCount,
  getFullName,
  isHoliday,
  getBusinessDaysCount,
  formatDate,
  getDaysCount,
  isCompOffRequest,
  isWFHOrCompOffRequest,
  isWFHRequest,
};
