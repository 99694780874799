import React from 'react';
import Box from '@mui/material/Box';
import FormsDetails from './formsDetail';
import { useDispatch } from 'react-redux';
import { setOfficialEmailId } from '../../../redux/actions';
import Modal from '@mui/material/Modal';
import { FormExitPopupActions } from '../../FormExitPopup';

const MODAL_CODES = {
  EMP_CREATION_SUCCESS: 'empCreationSuccess',
  MODAL_CLOSE: 'onModalClose',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: '#fff',
  boxShadow: 24,
  overflowY: 'scroll',
  height: '90vh',
  display: 'block',
};

const AddEmployeeModal = (props) => {
  const { show, setShow } = props;

  const dispatch = useDispatch();
  // const [show, setShow] = useState(false);

  const [openFormExitPopup, setOpenFormExitPopup] = React.useState(false); // check point
  const [isOpenFormExitPopup, setIsOpenExitPopup] = React.useState(false); // for popup

  const handleCloseForm = (code: string) => {
    if (code === MODAL_CODES.EMP_CREATION_SUCCESS) {
      closeModal(false);
      return;
    }
    setIsOpenExitPopup(openFormExitPopup);
    if (!openFormExitPopup) {
      closeModal(false);
    }
  };
  const closeModal = (value: any) => {
    setShow(value);
    dispatch(setOfficialEmailId(''));
  };

  const formExitCallBackAction = (action: any) => {
    if (action === FormExitPopupActions.CANCEL) setIsOpenExitPopup(false);
    else if (action === FormExitPopupActions.LEAVE) closeModal(false);
  };

  return (
    <>
      <Modal
        open={show}
        onClose={() => {
          handleCloseForm(MODAL_CODES.MODAL_CLOSE);
        }}
      >
        <Box sx={style}>
          <FormsDetails
            setOpen={setShow}
            closeModal={setShow}
            openFormExitPopup={openFormExitPopup}
            setOpenFormExitPopup={setOpenFormExitPopup}
            isOpenFormExitPopup={isOpenFormExitPopup}
            setIsOpenExitPopup={setIsOpenExitPopup}
            formExitCallBackAction={formExitCallBackAction}
            handleCloseForm={handleCloseForm}
          />
        </Box>
      </Modal>
    </>
  );
};
export default AddEmployeeModal;
