const getCompOffDetails = (compOffData: any = null) => {
  const compOffDetails = {
    heading: 'Comp Offs',
    infoText: '',
    availableLeaves: 0,
    consumedLeaves: 0,
    accruedSoFar: 0,
    expired: 0,
  };

  compOffData?.forEach((compOffObject) => {
    if (compOffObject.formData.status !== 'pending') {
      compOffDetails.accruedSoFar += compOffObject.formData.totalCompOffCount;
      compOffDetails.availableLeaves += compOffObject.formData.compOffCount;
      compOffDetails.consumedLeaves += compOffObject.formData.consumedCount;
      compOffDetails.expired += compOffObject.formData.expiredCount;
    }
  });

  return compOffDetails;
};

const getCompOffDetailsById = (compOffId, compOffData: any = null) => {
  const compOffDetails = {
    heading: 'Comp Offs',
    infoText: '',
    availableLeaves: 0,
    consumedLeaves: 0,
    accruedSoFar: 0,
    expired: 0,
  };

  const filteredData = compOffData?.filter((data) => data.id === compOffId);
  if (filteredData?.length) {
    const compOffObject = filteredData[0];
    compOffDetails.accruedSoFar = compOffObject.formData.totalCompOffCount;
    compOffDetails.availableLeaves = compOffObject.formData.compOffCount;
    compOffDetails.consumedLeaves = compOffObject.formData.consumedCount;
    compOffDetails.expired = compOffObject.formData.expiredCount;
  }
  return compOffDetails;
};

export { getCompOffDetails, getCompOffDetailsById };
