import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { QUOTES } from './quotes';

const styles = {
  typography: {
    quote: {
      maxWidth: '550px',
      lineHeight: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      margin: '10px 0',
      '&:hover': {
        WebkitLineClamp: 3,
      },
      color: 'black',
      //   width:"100px"
    },
  },
};

function getRandomQuote() {
  const randomIndex = Math.floor(Math.random() * QUOTES.length);
  return QUOTES[randomIndex];
}

const Quote = () => {
  const [quote, setQuote] = useState('');

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        // const response = await axios.get("https://zenquotes.io/api/random");
        setQuote(getRandomQuote());

        // setQuote(response.data[0].q);
      } catch (error) {
        console.error('Error fetching the quote', error);
      }
    };

    fetchQuote();

    const intervalId = setInterval(fetchQuote, 3600000); // Fetch a new quote every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <Typography sx={styles.typography.quote}>{quote}</Typography>
    </div>
  );
};

export default Quote;
