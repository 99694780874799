import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  ChangeCompOffStatus,
  FilterFormDetailsWithReportingTo,
} from '../../../services/lmsService';
import { MY_COMP_OFFS_FORM_ID } from '../../../constants/formConstants';
import { pushNotification } from '../../../redux/actions';
import CONSTANTS from '../../../constants/constants';
import { Box, Button, Typography } from '@mui/material';
import {
  capitalize,
  formatDate,
  getFullName,
} from '../../../services/commonHelperService';
import ConfirmationModal from '../../../components/common/ConfirmationModal/ConfirmationModal';
import './teamCompOffRequest.css';
import { GridColDef } from '@mui/x-data-grid';
import TsDatagrid from '../../../components/TsDatagrid';
import dayjs from 'dayjs';
import { getSessionStorageItem } from '../../../encrypt';

const TeamCompOffRequest = () => {
  const dispatch = useDispatch();
  const emailId: any = getSessionStorageItem('user-email');
  const [teamCompOffRequests, setTeamCompOffRequests] = useState<any>([]);

  const [selectedRowId, setSelectedRowId] = useState('');
  const [selectedDecision, setSelectedDecision] = useState('');

  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = (id, decision) => {
    setSelectedRowId(id);
    setSelectedDecision(decision);
    setIsConfirmationModalOpen(true);
  };
  const handleClose = () => {
    setIsConfirmationModalOpen(false);
    setSelectedRowId('');
    setSelectedDecision('');
  };

  useEffect(() => {
    getCompOffRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCompOffRequests = async () => {
    setIsLoading(true);
    const response = await FilterFormDetailsWithReportingTo(
      MY_COMP_OFFS_FORM_ID,
      emailId
    );
    if (response?.success && response?.data?.length > 0) {
      const teamData = response?.data?.map((item) => {
        const mapData = item?.formData;
        return {
          id: item?.id,
          firstName: mapData?.firstName,
          lastName: mapData?.lastName,
          empId: mapData?.employeeId,
          officialEmail: mapData?.officialEmail,
          reportingTo: mapData?.reportingTo,
          fromDate: mapData?.fromDate,
          toDate: mapData?.toDate,
          totalDays: mapData?.compOffCount,
          status: mapData?.status,
          comments: mapData?.reason,
          leaveType: mapData?.leaveType,
          fullName: `${getFullName([
            mapData?.firstName,
            mapData?.lastName,
          ])} - (${mapData?.employeeId})`,
          appliedOn: item?.createdOn,
          approvedOn:
            Boolean(item?.formData?.approvedOn) &&
            item?.formData?.approvedOn !== ''
              ? item?.formData?.approvedOn
              : '-',
        };
      });

      setTeamCompOffRequests(teamData);
      setIsLoading(false);
    } else {
      setTeamCompOffRequests([]);
      setIsLoading(false);
    }
  };

  const handleAcceptReject = async (id, decision) => {
    setIsActionButtonDisabled(true);
    const response = await ChangeCompOffStatus(id, decision);
    if (response.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      setIsConfirmationModalOpen(false);
      setIsActionButtonDisabled(false);
      getCompOffRequests();
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
      setIsConfirmationModalOpen(false);
      setIsActionButtonDisabled(false);
    }
  };

  const getConfirmationTitle = () => {
    // return `${selectedDecision.toUpperCase()} Comp Off Request`;
    return 'Confirm Decision';
  };

  const showActionItems = (status) => {
    return status === 'pending';
  };

  const getTableHeader = (title) => {
    return (
      <Typography sx={dataGridStyles.tableHeaderStyle}>{title}</Typography>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'User',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'fromDate',
      headerName: 'From',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => formatDate(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'toDate',
      headerName: 'To',
      flex: 1,
      editable: false,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => formatDate(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'totalDays',
      headerName: 'No.of.days',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'appliedOn',
      headerName: 'Applied On',
      editable: false,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueFormatter: (row: any) => {
        const value = dayjs(new Date(row?.value))
          .format('DD/MM/YYYY - hh:mm a')
          .replace('am', 'AM')
          .replace('pm', 'PM');
        if (value === 'Invalid Date') return '-';
        else return value;
      },
      flex: 1,
    },
    {
      field: 'approvedOn',
      headerName: 'Approved on',
      minWidth: 200,

      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      // valueFormatter: (row: any) => {
      //   const value = dayjs(new Date(row?.value)).format("DD/MM/YYYY");
      //   if (value === "Invalid Date") return "-";
      //   else return value;
      // },
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      editable: false,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => capitalize(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'comments',
      headerName: 'Comments',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 250,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (props) => {
        const item = props.row;
        return (
          <Box display={'flex'} gap={1.5}>
            <Button
              id="Leave-Accept"
              variant="contained"
              size="small"
              sx={actionColumnStyles.acceptButtonStyle}
              onClick={() => {
                handleOpen(item?.id, 'approve');
              }}
              disabled={!showActionItems(item?.status)}
            >
              Accept
            </Button>
            <Button
              id="Leave-Reject"
              size="small"
              sx={actionColumnStyles.rejectButtonstyle}
              onClick={() => {
                handleOpen(item?.id, 'reject');
              }}
              disabled={!showActionItems(item?.status)}
            >
              Reject
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <div>
        {isConfirmationModalOpen && (
          <ConfirmationModal
            id="confirmationModal"
            title={getConfirmationTitle()}
            message={`Do you want to ${selectedDecision} this Comp Off Request?`}
            open={isConfirmationModalOpen}
            onClose={handleClose}
            onSubmit={() => handleAcceptReject(selectedRowId, selectedDecision)}
            isSubmitButtonDisabled={isActionButtonDisabled}
          />
        )}

        <Box className="teamsContainer">
          <TsDatagrid
            rows={teamCompOffRequests}
            columns={columns}
            pageSize={10}
            pageSizeArray={[10, 20, 30]}
            getSelectedRowsData={() => {
              // TODO: Implement function
            }}
            handlePageChange={() => {
              // TODO: Implement function
            }}
            handlePageSizeChange={() => {
              // TODO: Implement function
            }}
            isCheckboxSelection={false}
            totalElements={teamCompOffRequests.length}
            sx={dataGridStyles.tableStyle}
            components={{
              NoRowsOverlay: () => (
                <Box sx={dataGridStyles.overlayStyle}>
                  {isLoading
                    ? 'Loading Requests'
                    : 'No Comp Off Requests Available'}
                </Box>
              ),
            }}
          />
        </Box>
      </div>
    </>
  );
};

export default TeamCompOffRequest;

const actionColumnStyles = {
  acceptButtonStyle: {
    color: '#326B16',
    backgroundColor: '#EDEDED',
    textTransform: 'capitalize',
  },
  rejectButtonstyle: {
    color: '#C04A2B',
    width: '30px',
    textTransform: 'capitalize',
  },
};

const dataGridStyles = {
  tableStyle: {
    height: '500px',
  },
  overlayStyle: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableHeaderStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
};
