import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import './i18nextInit';
import { getSessionStorageItem } from './encrypt';

if (getSessionStorageItem('react-token')) {
  const envs: any = getSessionStorageItem('config');
  const config: any = JSON.parse(envs);
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <React.StrictMode>
      <App config={config} />
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  fetch(`${window.location.origin}${window.location.pathname}.json`)
    .then(async (r) => r.json())
    .then((config) => {
      // eslint-disable-next-line react/no-deprecated
      ReactDOM.render(<App config={config} />, document.getElementById('root'));
    })
    .catch((error) => {
      // eslint-disable-next-line react/no-deprecated
      ReactDOM.render(<div>{error}</div>, document.getElementById('root'));
    });
}
