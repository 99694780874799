import React from 'react';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import ApplyLeave from './applyLeave';

const useStyles = makeStyles(() => ({
  modalRoot: {
    maxHeight: '90%',
    width: '75%',
  },
}));

const ApplyLeaveModal = (props) => {
  const { show, setShow, leaveChoice, compOffId } = props;

  const classes = useStyles();

  return (
    <>
      <Modal
        open={show}
        onClose={() => {
          setShow(false);
          // dispatch(setParentDataId(""));
        }}
      >
        <div className={`${classes.modalRoot} modal-container`}>
          <ApplyLeave
            show={show}
            setShow={setShow}
            leaveChoice={leaveChoice}
            compOffId={compOffId}
          />
        </div>
      </Modal>
    </>
  );
};
export default ApplyLeaveModal;
