import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { makeStyles } from '@mui/styles';

interface ChartTypes {
  chartData: any;
  colors: any;
}
const CustomChart = ({ chartData, colors }: ChartTypes) => {
  const classes = divStyle();
  const totalSum =
    (chartData[1]?.[1] || 0) +
    (chartData[2]?.[1] || 0) +
    (chartData[3]?.[1] || 0) +
    (chartData[4]?.[1] || 0);

  const getAveragePercentages = () => {
    const averages = [0.0, 0.0, 0.0, 0.0];
    for (let i = 1; i <= 4; i++) {
      averages[i - 1] = Number(((chartData[i][1] * 100) / totalSum).toFixed(2));
    }
    return averages;
  };
  const avgPercentages = getAveragePercentages();

  const data: any = [];
  const getData = () => {
    for (let i = 1; i <= 4; i++) {
      const item = {
        id: chartData[i][0],
        value: avgPercentages[i - 1],
        color: colors[i - 1],
      };
      data.push(item);
    }
  };
  getData();

  return (
    <div className={classes.main}>
      <ResponsivePie
        colors={colors}
        data={data}
        margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
        innerRadius={0.02}
        padAngle={1}
        activeOuterRadiusOffset={10}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={30}
        arcLabel={(e) => '' + e.value + '%'}
        arcLabelsRadiusOffset={0.5}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['brighter', 3]],
        }}
        legends={[]}
      />
    </div>
  );
};

export default CustomChart;

const divStyle = makeStyles({
  main: {
    height: '300px',
    padding: '20px',
    margin: '0',
    '@media (min-width: 1200px)': {
      padding: '0',
    },
    '@media (min-width: 1440px)': {
      padding: '20px',
    },
  },
});
