import { makeStyles } from '@mui/styles';

export const useEventStyles = (isEditing) => {
  return makeStyles({
    heading: {
      fontSize: '0.9rem !important',
      color: 'black !important',
    },
    content: {
      fontSize: '1rem !important',
      color: '#000000af !important',
      whiteSpace: 'nowrap !important',
      overflow: 'hidden !important',
      padding: '0.3rem 0rem !important',
      textOverflow: 'ellipsis !important',
      maxWidth: '100% !important',
    },
    multilineText: {
      '& .MuiOutlinedInput-input': {
        fontSize: '0.9rem !important',
      },
    },
    helperText: { color: '#db4b4b !important' },
    flexCenter: {
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
    },
    containerSub: {
      marginTop: '2rem !important',
    },

    main: {
      marginBottom: '1rem !important',
    },
    paper: {
      padding: '16px !important',
      marginBottom: '16px !important',
    },
    icon: {
      height: '50px !important',
      width: '50px !important',
    },
    dateBoxContainer: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      position: 'relative !important',
      padding: '0px !important',
      '& .MuiSvgIcon-root': {
        color: isEditing ? '#f15830 !important' : '',
      },
    },
    dateBoxFormLabel: {
      top: -8,
      left: 16,
      backgroundColor: 'white !important',
      padding: '0 4px !important',
      zIndex: 1,
      width: '6.5rem !important',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      position: 'absolute !important',
      '& .MuiInputBase-input, .MuiOutlinedInput-input': {
        fontSize: '14px !important',
      },
    },
    dateBoxHeading: {
      fontSize: '12px !important',
    },
    documentsBox: {
      display: 'flex !important',
      flexDirection: 'row',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      gap: 2,
      border: '1px solid #C3C3C3 !important',
      borderRadius: '15px !important',
      padding: '8px !important',
    },
    fileIconStyle: {
      height: '2rem !important',
      width: '2rem !important',
      backgroundPosition: 'center !important',
      objectFit: 'cover',
      objectPosition: 'center !important',
    },
    fileNameBox: {
      width: '100px !important',
      wordWrap: 'break-word',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      gap: '4px !important',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      display: '-webkit-box !important',
      textAlign: 'start',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      minWidth: '150px !important',
      maxWidth: '200px !important',
    },
    downloadIcon: { color: '#10A6F9 !important' },
    filesBufferContainer: {
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      gap: '8px !important',
      border: '1px solid black !important',
      borderRadius: '4px !important',
      background: '#ff7f3567 !important',
      padding: '8px !important',
    },
    filesBufferFileName: {
      fontSize: '1rem !important',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      display: '-webkit-box !important',
      textAlign: 'start',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      maxWidth: '200px !important',
    },
    filesBufferClearIcon: {
      '&:hover': {
        cursor: 'pointer !important',
      },
    },
    ctaContainer: {
      display: 'flex !important',
      justifyContent: 'flex-end !important',
      alignItems: 'center !important',
      gap: '1rem !important',
    },
    ctaCancelButton: {
      color: '#000000 !important',
      background: '#ffffff !important',
    },
    ctaDeleteButton: {
      color: '#ffffff !important',
      background: '#da3131 !important',
    },
    currentDocsContainer: {
      display: 'flex !important',
      justifyContent: 'start !important',
      alignItems: 'start !important',
      width: '100% !important',
      flexGrow: '1',
      flexWrap: 'wrap',
      gap: '12px !important',
    },
    inputLabel: {
      background: 'white',
    },

    eventPromotionWithHikeStyle: {
      borderLeft: '3px solid rgb(76, 175, 79) !important',
    },
    promotionWithoutHikeStyle: {
      borderLeft: '3px solid rgb(255, 193, 7,0.55) !important',
    },
    salaryHikeStyle: {
      borderLeft: '3px solid rgba(33, 149, 243, 0.55) !important',
    },
    quarterlyVariablePayStyle: {
      borderLeft: '3px solid rgb(255, 152, 0,0.55) !important',
    },
    violationStyle: {
      borderLeft: '3px solid rgb(244, 67, 54,0.55) !important',
    },
    discussionStyle: {
      borderLeft: '3px solid rgba(153, 84, 243, 0.55) !important',
    },
    employeeGrievanceStyle: {
      borderLeft: '3px solid rgb(156, 39, 176,0.55) !important',
    },
    eventPromotionWithHikeColor: { color: 'rgb(76, 175, 79) !important' },
    promotionWithoutHikeColor: { color: 'rgb(255, 193, 7,0.55) !important' },
    salaryHikeColor: { color: 'rgba(33, 149, 243, 0.55)' },
    quarterlyVariablePayColor: { color: 'rgb(255, 152, 0,0.05) !important' },
    violationColor: { color: 'rgb(244, 67, 54,0.55) !important' },
    dicussionColor: { color: 'rgba(153, 84, 243, 0.55) !important' },
    employeeGrievanceColor: { color: 'rgb(156, 39, 176,0.55) !important' },

    // fucntion with parameters
    currentAllDocsContainer: !isEditing
      ? {
          filter: 'grayscale(100%) !important',
          opacity: 0.5,
        }
      : {},
    disabledTextColor: !isEditing
      ? {
          color: '#00000061 !important',
        }
      : {},

    paperEditingModeBackground: {
      background: isEditing ? 'rgb(256,228,228,0.25)' : '',
    },
    addEventRow: {
      display: 'flex !important',
      flexDirection: 'row',
      justifyContent: 'start !important',
      alignItems: 'center !important',
      padding: '1rem 0 !important',
    },
    eventDropDown: {
      marginRight: '1rem !important',
      minWidth: '16rem !important',
    },
    eventAddButton: {
      margin: '1rem 0 !important',
    },
  });
};
