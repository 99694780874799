export const QUOTES = [
   'Believe you can and you\'re halfway there.',
  'Your limitation—it\'s only your imagination.',
  'Push yourself, because no one else is going to do it for you.',
  'Great things never come from comfort zones.',
  'Dream it. Wish it. Do it.',
  'Success doesn\'t just find you. You have to go out and get it.',
  'The harder you work for something, the greater you\'ll feel when you achieve it.',
  'Dream bigger. Do bigger.',
  'Don\'t stop when you\'re tired. Stop when you\'re done.',
  'Wake up with determination. Go to bed with satisfaction.',
  'Do something today that your future self will thank you for.',
  'Little things make big days.',
  'It\'s going to be hard, but hard does not mean impossible.',
  'Don\'t wait for opportunity. Create it.',
  'Sometimes we\'re tested not to show our weaknesses, but to discover our strengths.',
  'The key to success is to focus on goals, not obstacles.',
  'Dream it. Believe it. Build it.',
  'Don’t watch the clock; do what it does. Keep going.',
  'Success is the sum of small efforts, repeated day in and day out.',
  'The way to get started is to quit talking and begin doing.',
  'The only limit to our realization of tomorrow is our doubts of today.',
  'Don’t let yesterday take up too much of today.',
  'You learn more from failure than from success. Don’t let it stop you.',
  'What you get by achieving your goals is not as important as what you become by achieving your goals.',
  'You are never too old to set another goal or to dream a new dream.',
  'To see what is right and not do it is a lack of courage.',
  'If you want to achieve greatness stop asking for permission.',
  'Just when the caterpillar thought the world was over, it became a butterfly.',
  'The best time to plant a tree was 20 years ago. The second best time is now.',
  'It always seems impossible until it’s done.',
  'Don’t be afraid to give up the good to go for the great.',
  'Success is not final, failure is not fatal: It is the courage to continue that counts.',
  'Hardships often prepare ordinary people for an extraordinary destiny.',
  'What lies behind us and what lies before us are tiny matters compared to what lies within us.',
  'Go confidently in the direction of your dreams. Live the life you have imagined.',
  'The only way to do great work is to love what you do.',
  'Act as if what you do makes a difference. It does.',
  'Keep your face always toward the sunshine—and shadows will fall behind you.',
  'Don’t wish it were easier. Wish you were better.',
  'The only person you are destined to become is the person you decide to be.',
  'Start where you are. Use what you have. Do what you can.',
  'You don’t have to be great to start, but you have to start to be great.',
  'You are not a product of your circumstances but a product of your decisions.',
  'Your life only gets better when you do.',
  'It’s not whether you get knocked down, it’s whether you get up.',
  'Life is 10% what happens to us and 90% how we react to it.',
  'Failure will never overtake me if my determination to succeed is strong enough.',
  'The future belongs to those who believe in the beauty of their dreams.',
  'If you can dream it, you can do it.',
  'What we achieve inwardly will change outer reality.',
  'Your time is limited, so don’t waste it living someone else’s life.',
  'Happiness is not something ready-made. It comes from your own actions.',
  'Start each day with a positive thought and a grateful heart.',
  'Do not wait to strike till the iron is hot; but make it hot by striking.',
  'We may encounter many defeats but we must not be defeated.',
  'Be miserable. Or motivate yourself. Whatever has to be done, it\'s always your choice.',
  'Perseverance is not a long race; it is many short races one after another.',
  'What you do today can improve all your tomorrows.',
  'The only way to achieve the impossible is to believe it is possible.',
  'Don’t count the days, make the days count.',
  'You can’t cross the sea merely by standing and staring at the water.',
  'Do not go where the path may lead, go instead where there is no path and leave a trail.',
  'Action is the foundational key to all success.',
  'Setting goals is the first step in turning the invisible into the visible.',
  'A goal without a plan is just a wish.',
  'The only place where success comes before work is in the dictionary.',
  'Motivation is what gets you started. Habit is what keeps you going.',
  'Success is not how high you have climbed, but how you make a positive difference to the world.',
  'It’s not the load that breaks you down, it’s the way you carry it.',
  'In the middle of every difficulty lies opportunity.',
  'You miss 100% of the shots you don’t take.',
  'Success is how high you bounce when you hit bottom.',
  'Your big opportunity may be right where you are now.',
  'If you don’t like the road you’re walking, start paving another one.',
  'Don’t let what you cannot do interfere with what you can do.',
  'The best way to predict your future is to create it.',
  'Everything you’ve ever wanted is on the other side of fear.',
  'A journey of a thousand miles begins with a single step.',
  'There are no shortcuts to any place worth going.',
  'The harder the conflict, the more glorious the triumph.',
  'The best revenge is massive success.',
  'Opportunities don\'t happen, you create them.',
  'If you are not willing to risk the usual, you will have to settle for the ordinary.',
  'Success usually comes to those who are too busy to be looking for it.',
  'The only thing standing between you and your goal is the story you keep telling yourself as to why you can\'t achieve it.',
  'Don’t wait. The time will never be just right.',
  'Everything you need is already inside you.',
  'All our dreams can come true if we have the courage to pursue them.',
  'Challenges are what make life interesting, and overcoming them is what makes life meaningful.',
  'It’s not about how bad you want it. It’s about how hard you’re willing to work for it.',
  'What seems to us as bitter trials are often blessings in disguise.',
  'Failure is the condiment that gives success its flavor.',
  'Our greatest glory is not in never falling, but in rising every time we fall.',
];
