import { Box, Button, Modal } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { makeStyles } from '@mui/styles';

interface WebcamCaptureProps {
  onCapture: (base64: string | null) => void;
  imageSrc: any;
  setImageSrc: any;
  webcamOpen: any;
  setWebcamOpen: any;
}

const useStyles = makeStyles(() => ({
  modalRoot: {
    maxHeight: '100%',
    width: '29vw',
    borderRadius: '18px',
    backgroundColor: 'transparent',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none', // IE and Edge
    'scrollbar-width': 'none', // Firefox
    display: 'flex',
  },
}));

const styles = {
  boxes: {
    main: {
      height: '28vw',
      width: '28vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '2rem',
      background: '#E3E7EF',
      borderRadius: '18px',
    },
    imagePresentation: {
      borderRadius: '100%',
      maxWidth: '80%',
    },
    webCamPresentation: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',

      gap: '0.8rem',
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',

      gap: '0.5rem',
    },
    galleryBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  buttons: {
    actionButton: {
      borderRadius: '24px',
      background: '#2b5f6b',
      '&:hover': {
        background: '#1c3a42',
      },
      color: 'white !important',
      textTransform: 'capitalize',
      padding: '6px 18px',
    },
    okButton: {
      borderRadius: '24px',
      // marginBottom: "-18px",

      color: 'white !important',
      textTransform: 'capitalize',
      // fontsize: "16px",
      padding: '6px 18px',
    },
  },
};

export const WebcamCapture = ({
  onCapture,
  imageSrc,
  setImageSrc,
  webcamOpen,
  setWebcamOpen,
}: WebcamCaptureProps) => {
  const classes = useStyles();
  const webcamRef = useRef<Webcam>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(true);
  const [buttonText, setButtonText] = useState('Capture');
  const [action, setAction] = useState('capture');

  const capture = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setImageSrc(imageSrc);
        onCapture(imageSrc);
      }
    } else {
      onCapture(null);
      setImageSrc(null);
    }
  };

  useEffect(() => {
    setButtonText(imageSrc ? 'Re-capture' : 'Capture');
    setAction(imageSrc ? 'recapture' : 'capture');
  }, [imageSrc]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
        onCapture(reader.result as string);
        setIsCameraOpen(false);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal
      open={webcamOpen}
      onClose={() => {
        setWebcamOpen(false);
      }}
    >
      <div className={`${classes.modalRoot} modal-container`}>
        <Box sx={styles.boxes.main}>
          {isCameraOpen && !imageSrc && (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              style={{
                width: '22vw',
                borderRadius: '100%',
                border: '1px solid grey',
              }}
            />
          )}
          {!isCameraOpen && imageSrc && (
            <Box
              component={'img'}
              src={imageSrc}
              sx={styles.boxes.imagePresentation}
            />
          )}
          {webcamRef && (
            <Box sx={styles.boxes.webCamPresentation}>
              <Box sx={styles.boxes.buttonGroup}>
                <Button
                  onClick={() => {
                    capture();
                    if (action === 'capture') {
                      setIsCameraOpen(false);
                    } else {
                      setIsCameraOpen(true);
                    }
                  }}
                  sx={styles.buttons.actionButton}
                >
                  {buttonText}
                </Button>
                <Box sx={styles.boxes.galleryBox}>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      sx={[
                        styles.buttons.actionButton,
                        {
                          marginBottom: '-6px',
                        },
                      ]}
                    >
                      Choose from Gallery
                    </Button>
                  </label>
                </Box>
              </Box>
              <Button
                onClick={() => {
                  imageSrc && setWebcamOpen(false);
                }}
                sx={[
                  styles.buttons.okButton,
                  {
                    background: imageSrc ? '#2b5f6b' : 'gray',
                    '&:hover': {
                      background: imageSrc ? '#1c3a42' : 'gray',
                    },
                  },
                ]}
              >
                ok
              </Button>
            </Box>
          )}
        
        </Box>
      </div>
    </Modal>
  );
};
