import React, { CSSProperties, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { uploadFileApi } from '../../../../services/UploadFile';
import {
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from '../../../../services/formService';

import { DOCUMENTS_UPLOAD_FORM_ID } from '../../../../constants/formConstants';
import { store } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../../../redux/actions';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  deleteDocument,
  downloadFile,
} from '../../../../services/DocumentService';
import './document.css';
import CONSTANTS from '../../../../constants/constants';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
    mb: 2,
  },
  documentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
  },
  fileItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    border: '1px solid #C3C3C3',
    borderRadius: '15px',
    padding: 1,
  },
  icon: {
    height: '50px',
    width: '50px',
  },
  fileName: {
    width: '100px',
    wordWrap: 'break-word',
  },
  downloadIcon: {
    color: '#10A6F9',
  },
  deleteIcon: {
    color: '#DC4955',
  },
  successIcon: {
    color: '#32BEA6',
  },
  errorIcon: {
    color: '#E75454',
  },
  title: {
    color: 'red',
    width: '100%',
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Documents = (props: any) => {
  const dispatch = useDispatch();
  const reduxState = store.getState();
  const fileTypes = ['JPG', 'PNG', 'PDF', 'XLSX', 'DOCX'];
  const [allFiles, setAllFiles] = useState<any>([]);
  const [formId, setFormId] = useState('');

  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    borderColor: '#4B6D8F',
  };

  const [color] = useState('#ffffff');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    prefillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState.officialEmailId]);

  const handleFileChange = async (file) => {
    setLoading(true);
    const Files = [...file];

    const filesDataArray = await Promise.all(
      Files?.map(async (item: any) => {
        const response: any = await uploadFileApi(
          item?.name,
          item,
          'empMaster',
          {}
        );

        const data = {
          fileName: item?.name,
          fileType: item?.type,
          fileSize: item?.size,
          documentId: response?.data?.id,
          status: response?.success,
        };
        return data;
      })
    );

    setAllFiles([...allFiles, ...filesDataArray]);
    setLoading(false);
    submitFormData([...allFiles, ...filesDataArray], formId);
  };

  const deleteFile = async (d, i) => {
    await deleteDocument(d?.documentId);
    const names = [...allFiles];
    names?.splice(i, 1);
    setAllFiles(names);
    submitFormData(names, formId);
  };

  const downloadFiles = async (id, name) => {
    await downloadFile(id, name);
  };

  // const handleStatusChange = async () => {
  //   let payload = {
  //     formId: PERSONAL_FORM_ID,
  //     id: reduxState.parentDataId,
  //     formData: {
  //       status: "active",
  //     },
  //   };
  //   const response = await patchDataToForm(payload);
  // };

  const submitFormData = async (allFiles, formId) => {
    if (formId) {
      const payload = {
        formId: DOCUMENTS_UPLOAD_FORM_ID,
        id: formId,
        formData: {
          reportingTo: reduxState?.reportingToEmailId,
          officialEmail: reduxState?.officialEmailId,
          files: allFiles,
        },
      };
      const response: any = await postForm(payload);
      if (response?.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS.SUCCESS,
          })
        );
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    } else {
      const payload = {
        formId: DOCUMENTS_UPLOAD_FORM_ID,
        formData: {
          reportingTo: reduxState?.reportingToEmailId,
          officialEmail: reduxState?.officialEmailId,
          files: allFiles,
        },
      };
      const response = await postForm(payload);

      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS.SUCCESS,
          })
        );
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    }
  };

  const prefillData = async () => {
    const response: any = await FilterFormDetailsWithOfficalEmail(
      DOCUMENTS_UPLOAD_FORM_ID,
      reduxState?.officialEmailId
    );

    if (response?.success && response?.data?.content?.length > 0) {
      setFormId(response?.data?.content?.[0]?.id);
      setAllFiles(response?.data?.content?.[0]?.formData?.files || []);
    } else {
      setFormId('');
      setAllFiles([]);
    }
  };

  return (
    <Box>
      <Box sx={styles.container}>
        <Typography sx={styles.title}>
          Documents <Divider />
        </Typography>
        <ClipLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />

        <FileUploader
          multiple={true}
          handleChange={handleFileChange}
          name="file"
          types={fileTypes}
        />
      </Box>

      <Box sx={styles.documentBox}>
        {allFiles?.map((d: any, i: number) => (
          <Box key={d.id || i} sx={styles.fileItem}>
            <img
              style={styles.icon}
              src={(() => {
                switch (d.fileType) {
                  case 'image/png':
                    return 'https://cdn-icons-png.flaticon.com/512/337/337948.png';
                  case 'application/pdf':
                    return 'https://cdn-icons-png.flaticon.com/512/337/337946.png';
                  case 'image/jpg':
                    return 'https://www.svgrepo.com/show/255826/jpg.svg';
                  case 'doc':
                    return 'https://www.svgrepo.com/show/255810/doc.svg';
                  case 'text/csv':
                    return 'https://cdn-icons-png.flaticon.com/512/172/172534.png';
                  case 'image/jpeg':
                    return 'https://cdn-icons-png.flaticon.com/512/337/337940.png';
                  case 'text/plain':
                    return 'https://cdn-icons-png.flaticon.com/512/2656/2656402.png';
                  case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return 'https://img.icons8.com/external-fauzidea-flat-fauzidea/256/external-docx-file-file-extension-fauzidea-flat-fauzidea.png';
                  case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    return 'https://cdn-icons-png.flaticon.com/512/9496/9496502.png';
                  default:
                    return ''; // Or a default icon if needed
                }
              })()}
              alt="icon"
            />

            <Box sx={styles.fileName}>{d.fileName}</Box>
            <Box>
              <IconButton
                onClick={() => downloadFiles(d.documentId, d.fileName)}
              >
                <FileDownloadOutlinedIcon sx={styles.downloadIcon} />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                onClick={() => deleteFile(d, i)}
                sx={styles.deleteIcon}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>

            <Box>
              {d.status ? (
                <Tooltip title={'Success'}>
                  <IconButton>
                    <CheckCircleIcon sx={styles.successIcon} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={'Failed'}>
                  <IconButton>
                    <ErrorIcon sx={styles.errorIcon} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Documents;
