import { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Dialog,
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {
  deleteSpecificEventFromHistory,
  fetchEventHistoryByEmployeeId,
  saveEventHistoryToDatabase,
} from '../../services/eventHistoryService';
import {
  FilterFormDetailsWithOfficalEmail,
  postForm,
  updateGenralForm,
} from '../../services/formService';
import { DOCUMENTS_UPLOAD_FORM_ID } from '../../constants/formConstants';
import { store } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../redux/actions';
import CONSTANTS from '../../constants/constants';

import { uploadFileApi } from '../../services/UploadFile';
import { deleteDocument, downloadFile } from '../../services/DocumentService';
import EventAccordionWrapper from './EventAccordionWrapper';
import {
  DiscussionNote,
  EmployeeGrievance,
  EventAccordionListProps,
  EventType,
  EventTypes,
  PromotionWithHike,
  DesignationChange,
  SalaryHike,
  Violation,
  FormikConstants,
  ProfileConstants,
  StorageBucketConstants,
} from './types/EventTypes';
import { revertString } from './utilFunctions/promotionUtils';
import { getExtraByType } from './eventAccordions/formUtils';
import { useEventStyles } from './styles';

const EventAccordionList = ({ userData, isAdmin }: EventAccordionListProps) => {
  const styles = useEventStyles(false)();
  const [allEvents, setAllEvents] = useState<any[]>([]);
  const [promotionsWithHikes, setPromotionsWithHikes] = useState<
    PromotionWithHike[]
  >([]);
  const [designationChanges, setDesignationChanges] = useState<
    DesignationChange[]
  >([]);
  const [salaryHikes, setSalaryHikes] = useState<SalaryHike[]>([]);
  const [discussionNotes, setDiscussionNotes] = useState<DiscussionNote[]>([]);
  const [employeeGrievances, setEmployeeGrievances] = useState<
    EmployeeGrievance[]
  >([]);
  const [violations, setViolations] = useState<Violation[]>([]);

  const reduxState = store.getState();
  const [recordId, setRecordId] = useState('');
  const [allFiles, setAllFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [eventType, setEventType] = useState<EventType>(
    EventTypes?.PROMOTION_WITH_HIKE
  );
  const filteredEvents = Object.values(EventTypes)?.filter(
    (value) => value !== EventTypes?.QUARTERLY_VARIABLE_PAY
  );
  const dispatch = useDispatch();

  const fetchAllEvents = async () => {
    setLoading(true);
    try {
      const promotionResponse: any = await fetchEventHistoryByEmployeeId(
        userData?.employeeId
      );

      const data = promotionResponse?.data?.data;
      const promotionWithHikes = data?.promotionWithHikes;
      const designationChanges = data?.designationChanges;
      const salaryHikes = data?.salaryHikes;
      const violations = data?.violations;
      const employeeGrievances = data?.employeeGrievances;
      const discussionNotes = data?.discussionNotes;

      setPromotionsWithHikes([...promotionWithHikes]?.reverse());
      setDesignationChanges([...designationChanges]?.reverse());
      setSalaryHikes([...salaryHikes]?.reverse());
      setViolations([...violations]?.reverse());
      setDiscussionNotes([...discussionNotes]?.reverse());
      setEmployeeGrievances([...employeeGrievances]?.reverse());

      const allEventArray = [
        ...promotionWithHikes,
        ...designationChanges,
        ...salaryHikes,
        ...violations,
        ...discussionNotes,
        ...employeeGrievances,
      ];

      allEventArray.sort((a, b) => {
        const dateA = new Date(a?.effectiveDate as string);
        const dateB = new Date(b?.effectiveDate as string);
        return dateB?.getTime() - dateA?.getTime();
      });
      setAllEvents([...allEventArray]);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showErrorOutput(error);
    }
  };
  const showErrorOutput = (error: any) => {
    try {
      const errorObj = error?.message
        ? JSON.parse(error?.message)
        : { success: false, message: CONSTANTS?.ERROR_OCCURRED };
      dispatch(
        pushNotification({
          isOpen: true,
          message: errorObj?.message
            ? errorObj?.message
            : CONSTANTS?.ERROR_OCCURRED,
          type: errorObj?.success ? CONSTANTS?.SUCCESS : CONSTANTS?.ERROR,
        })
      );
    } catch (error: any) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: error?.message ? error?.message : CONSTANTS?.ERROR_OCCURRED,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  const prefillData = async () => {
    const response: any = await FilterFormDetailsWithOfficalEmail(
      DOCUMENTS_UPLOAD_FORM_ID,
      reduxState?.officialEmailId || userData?.officialEmailId
    );

    if (response?.success && response?.data?.content?.length > 0) {
      setRecordId(response?.data?.content?.[0]?.id);
      setAllFiles(response?.data?.content?.[0]?.formData?.files || []);
    } else {
      setRecordId('');
      setAllFiles([]);
    }
  };

  const submitFormData = async (allFiles, recordId) => {
    const payload: any = {
      formId: DOCUMENTS_UPLOAD_FORM_ID,
      formData: {
        reportingTo: reduxState?.reportingToEmailId,
        officialEmail: reduxState?.officialEmailId,
        files: allFiles,
      },
    };

    if (recordId) {
      payload.id = recordId; // Add the id only if recordId is provided
    }

    const response = await postForm(payload);

    const notification = {
      isOpen: true,
      message: response?.message,
      type: response?.success ? CONSTANTS?.SUCCESS : CONSTANTS?.ERROR,
    };

    dispatch(pushNotification(notification));
  };

  const postFiles = async (existingFiles: any, files: any) => {
    const filesDataArray = await Promise.all(
      files?.map(async (item: any) => {
        try {
          const response: any = await uploadFileApi(
            item?.name,
            item,
            StorageBucketConstants?.EMP_MASTER,
            {}
          );
          const data = {
            fileName: item.name,
            fileType: item.type,
            fileSize: item.size,
            documentId: response?.data?.id,
            status: response?.success,
          };
          return data;
        } catch (error) {
          return [];
        }
      })
    );
    if (filesDataArray === null) return [];
    await submitFormData([...existingFiles, ...filesDataArray], recordId);
    await prefillData();
    return filesDataArray;
  };

  const downloadFiles = async (id, name) => {
    setLoading(true);
    await downloadFile(id, name);
    setLoading(false);
  };

  useEffect(() => {
    const apiSlave = async () => {
      await prefillData();
      await fetchAllEvents();
    };
    apiSlave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savePromotionChanges = async (payloadValues: any) => {
    setLoading(true);
    delete payloadValues?.isNew;
    delete payloadValues?.referenceDocs;
    if (payloadValues?.filesBuffer?.length === 0) {
      delete payloadValues?.filesBuffer;
      uploadPromotion(payloadValues);
    } else {
      try {
        const fileUploadResponse = await postFiles(
          allFiles,
          payloadValues?.filesBuffer
        );
        delete payloadValues?.filesBuffer;
        payloadValues.data.supportingDocuments =
          fileUploadResponse !== null
            ? fileUploadResponse?.map((file: any) => file?.documentId)
            : [];
        uploadPromotion(payloadValues);
      } catch (error: any) {
        setLoading(false);
        showErrorOutput(error);
      }
    }
  };

  const uploadPromotion = async (payloadValues: any) => {
    payloadValues.employeeId = userData?.employeeId;

    try {
      await saveEventHistoryToDatabase(payloadValues);

      await updateUserDesignationAndDepartment(
        payloadValues?.data?.currentDesignation,
        payloadValues?.data?.currentDepartment
      );
      if (
        payloadValues?.data?.eventType === EventTypes?.PROMOTION_WITH_HIKE ||
        payloadValues?.data?.eventType === EventTypes?.SALARY_HIKE
      )
        await updateGenralForm(
          ProfileConstants?.LAST_REVISION_DATE,
          payloadValues?.data?.effectiveDate,
          userData?.officialEmail
        );
      await fetchAllEvents();
    } catch (error: any) {
      setLoading(false);
      showErrorOutput(error);
    }
  };

  const updatePromotion = async (payloadValues: any) => {
    setLoading(true);
    delete payloadValues?.isNew;
    delete payloadValues?.referenceDocs;
    const deletableBuffer = payloadValues?.deletableBuffer || [];
    let existingSupportingDocs = payloadValues?.supportingDocuments || [];
    const filesBuffer = payloadValues?.filesBuffer || [];
    let tempoAllFiles = [...allFiles];
    try {
      for (const file of deletableBuffer) {
        tempoAllFiles = tempoAllFiles?.filter(
          (aFile) => aFile?.documentId !== file?.id
        );
        existingSupportingDocs = existingSupportingDocs?.filter(
          (docId) => docId !== file?.id
        );
        await deleteDocument(file?.id);
      }
    } catch (error: any) {
      setLoading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: CONSTANTS?.DOCUMENTS_ERROR_OCCURRED + error?.message,
          type: CONSTANTS?.ERROR,
        })
      );
    }

    const updatedFilesBuffer = filesBuffer?.filter((file) => {
      return !existingSupportingDocs?.includes(file?.id);
    });

    const filteredUpdatedFilesBuffer = updatedFilesBuffer?.filter(
      (updatedFile) =>
        !tempoAllFiles?.some(
          (tempFile) => tempFile?.documentId === updatedFile?.id
        )
    );

    const newDocs = await postFiles(tempoAllFiles, filteredUpdatedFilesBuffer);

    const remainingSupportingDocIds = [
      ...existingSupportingDocs,
      ...newDocs?.map((doc: any) => doc?.documentId),
    ];

    delete payloadValues?.deletableBuffer;
    delete payloadValues?.filesBuffer;
    delete payloadValues?.supportingDocuments;
    payloadValues.data.supportingDocuments = [...remainingSupportingDocIds];
    payloadValues.employeeId = userData?.employeeId;

    try {
      await saveEventHistoryToDatabase(payloadValues);

      await updateGenralForm(
        ProfileConstants?.DESIGNATION,
        payloadValues?.data?.currentDesignation,
        userData?.officialEmail
      );
      await updateGenralForm(
        ProfileConstants?.DEPARTMENT,
        payloadValues?.data?.currentDepartment,
        userData?.officialEmail
      );
      if (
        payloadValues?.data?.eventType === EventTypes?.PROMOTION_WITH_HIKE ||
        payloadValues?.data?.eventType === EventTypes?.SALARY_HIKE
      )
        await updateGenralForm(
          ProfileConstants?.LAST_REVISION_DATE,
          payloadValues?.data?.effectiveDate,
          userData?.officialEmail
        );
      await fetchAllEvents();
    } catch (error: any) {
      showErrorOutput(error);
    }
  };

  const addExtra = () => {
    let recentEventObject;
    const getSingle = (array: any) => {
      return array ? (array?.length !== 0 ? array?.[0] : null) : null;
    };
    switch (eventType) {
      case EventTypes?.PROMOTION_WITH_HIKE:
        recentEventObject = getSingle(promotionsWithHikes);
        break;

      case EventTypes?.DESIGNATION_CHANGE:
        recentEventObject = getSingle(designationChanges);
        break;

      case EventTypes?.SALARY_HIKE:
        recentEventObject = getSingle(salaryHikes);
        break;

      case EventTypes?.VIOLATION:
        recentEventObject = getSingle(violations);
        break;

      case EventTypes?.DISCUSSION_NOTE:
        recentEventObject = getSingle(discussionNotes);
        break;

      case EventTypes?.GRIEVANCE:
        recentEventObject = getSingle(employeeGrievances);
        break;

      default:
        recentEventObject = null;
    }

    const extra = getExtraByType(eventType, recentEventObject, userData);
    setAllEvents((prevEvents: any) => {
      return [extra, ...(prevEvents ? prevEvents : [])];
    });
  };
  const removeElementFromPromotionsList = (id: number, eventType: string) => {
    const filteredList = allEvents?.filter(
      (event) => event?.eventyType !== eventType && event?.id !== id
    );
    setAllEvents([...filteredList]);
  };

  const deletePromotion = async (
    data: any,
    supportingDocs: any,
    isNew: boolean
  ) => {
    if (isNew) {
      removeElementFromPromotionsList(data?.eventId, data?.eventType);
      return;
    }

    setLoading(true);
    let tempoAllFiles = [...allFiles];
    const existingDocs = supportingDocs ? supportingDocs : [];
    try {
      for (const docId of existingDocs) {
        tempoAllFiles = tempoAllFiles?.filter(
          (aFile) => aFile?.documentId !== docId
        );
        await deleteDocument(docId);
      }
      if (data?.previousDepartment) {
        await updateUserDesignationAndDepartment(
          data?.previousDesignation,
          data?.previousDepartment
        );
        delete data?.previousDepartment;
        delete data?.previousDesignation;
      }
      const payload = {
        ...data,
        employeeId: userData?.employeeId,
      };
      await deleteSpecificEventFromHistory(payload);
      setAllFiles([...tempoAllFiles]);
      await submitFormData(tempoAllFiles, recordId);
      await fetchAllEvents();
    } catch (error: any) {
      setLoading(false);
      showErrorOutput(error);
    }
  };

  const updateUserDesignationAndDepartment = async (
    designation: string,
    department: string
  ) => {
    await updateGenralForm(
      ProfileConstants?.DESIGNATION,
      designation,
      userData?.officialEmail
    );
    await updateGenralForm(
      ProfileConstants?.DEPARTMENT,
      department,
      userData?.officialEmail
    );
  };

  return (
    <Box className={styles.containerSub}>
      <Dialog
        open={loading}
        a-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Loading</DialogTitle>
        <DialogContent className={styles.flexCenter}>
          <CircularProgress size={40} />
        </DialogContent>
      </Dialog>
      {isAdmin && (
        <Box className={styles.addEventRow}>
          <FormControl className={styles.eventDropDown}>
            <InputLabel shrink>Event Type</InputLabel>
            <Select
              label={FormikConstants?.eventType?.LABEL2}
              value={eventType}
              onChange={(e: any) => {
                setEventType(e.target.value);
              }}
              fullWidth
            >
              {filteredEvents?.map((type) => (
                <MenuItem key={type} value={type}>
                  {revertString(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={addExtra}
            className={styles.eventAddButton}
          >
            Add
          </Button>
        </Box>
      )}
      <Box>
        {allEvents?.map((mEvent, i) => {
          return (
            <EventAccordionWrapper
              key={i}
              event={mEvent}
              downloadFiles={downloadFiles}
              allFiles={allFiles}
              deletePromotion={deletePromotion}
              onSave={savePromotionChanges}
              updatePromotion={updatePromotion}
              isAdmin={isAdmin}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default EventAccordionList;
