export const getOrDefaultFormik = (value: any): string => {
  return (value !== null || value !== undefined) && value !== '--' ? value : '';
};
export const getOrDefaultRender = (value: any): string => {
  return (value !== null || value !== undefined) && value !== '' ? value : '--';
};

export const getSupportedDocsForThisPromotion = (allDocs, supportingDocs) => {
  // Check if allFiles and supportingDocs are arrays
  if (!Array.isArray(allDocs) || !Array.isArray(supportingDocs)) {
    return []; // Return an empty array if either is not an array
  }
  const filteredDocs = allDocs?.filter((file) =>
    supportingDocs?.includes(file?.documentId)
  );
  return filteredDocs;
};

export const revertString = (input: string): string => {
  // Check if the input is in the required uppercase format with underscores
  const regex = /^[A-Z]+(_[A-Z]+)*$/;
  if (!regex.test(input)) {
    return input;
  }

  // Replace underscores with spaces and convert to title case
  return input
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
