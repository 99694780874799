import { useFormik } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { leaveTypeEnums, leaveTypes } from '../../uiElementsAccess';
import RectangularLoader from '../../../Loaders/ContentLoader/Loader';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import DatePicker from 'react-datepicker';
import { getDate } from 'date-fns';
import CONSTANTS from '../../../constants/constants';
import moment from 'moment/moment';
import { getDaysCount } from '../../../services/commonHelperService';
import AddLeaveDialog from './addLeaveDialog';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../../redux/actions';
import { AddCompOffs, AddLeaves } from '../../../services/lmsService';
import { ERROR_MESSAGES } from '../../../constants/common';

const styles = {
  submitButton: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
      width: '250px',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
      width: '220px',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
      width: '200px',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem)',
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1%',
    gap: 2.5,
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
      fontWeight: 'bold',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
      fontWeight: 'bold',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
      fontWeight: 'bold',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
      fontWeight: 'bold',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
      fontWeight: 'bold !important',
    },
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  dateContentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  outerBox: {
    padding: '0 5px',
  },
  borderBox: {
    border: '1px solid grey',
  },
  rowContainer: {
    padding: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    flexWrap: 'wrap',
    alignItems: 'center',
    '@media (max-width: 800px)': {
      flexDirection: 'column',
    },
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  separator: {
    marginTop: '0.5rem',
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    color: 'red',
  },
  formControl: {
    size: 'small',
  },
  formLabel: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
      fontWeight: 'bold !important',
      color: 'black',
    },
  },
  radioGroup: {
    justifyContent: 'center',
  },
  formControlLabel: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
    },
    '@media (min-width: 1000px) and (max-width: 1499px)': {
      fontSize: 'calc(12px + 0.04rem)',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem)',
    },
  },
  fullWidthColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
};
const AddLeaveForm = (props) => {
  const { leaveType, title, closeModal, selectedEmpIdList = [] } = props;
  const classes = useStyles();
  const initialFormValues = {
    startDate: null,
    endDate: null,
    leaveType: leaveTypeEnums.GENERAL,
    empIdList: [...selectedEmpIdList],
    numberOfDays: 0,
    reason: '',
  };

  const [isLoading] = useState(false);
  const [finalFormData, setFinalFormData] = useState<any>(initialFormValues);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);
  const dispatch = useDispatch();

  const disableFutureDates = (date: any) => {
    return date <= new Date();
  };

  const addLeaveForm = useFormik({
    initialValues: initialFormValues,
    validationSchema: Yup.object({
      startDate: Yup.date()
        .typeError('')
        .required(ERROR_MESSAGES.REQUIRED_START_DATE)
        .nullable(),
      endDate: Yup.date()
        .typeError('')
        .required(ERROR_MESSAGES.REQUIRED_END_DATE)
        .nullable()
        .test(
          'endDate',
          ERROR_MESSAGES.END_DATE_BEFORE_START,
          function (endDate) {
            const startDate = this.resolve(Yup.ref('startDate'));
            if (!endDate || !startDate) return true; // Allow if endDate or startDate is not set
            return endDate >= startDate;
          }
        ),
      leaveType: Yup.string().required(ERROR_MESSAGES.REQUIRED_LEAVE_TYPE),
      numberOfDays: Yup.number(),
      reason: Yup.string().required(ERROR_MESSAGES.REQUIRED_REASON),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(true);
    constructRequestBody(values);
  };

  const constructRequestBody = (values: any) => {
    const requestBody = {
      fromDate: moment(values?.startDate.toISOString()).format('YYYY-MM-DD'),
      toDate: moment(values?.endDate.toISOString()).format('YYYY-MM-DD'),
      leaveType:
        leaveType === leaveTypeEnums.COMP_OFF ? leaveType : values?.leaveType,
      employeeId: selectedEmpIdList,
      leaveCount: 0,
      reason: values?.reason,
    };
    requestBody.leaveCount = getDaysCount(
      new Date(requestBody.fromDate),
      new Date(requestBody.toDate)
    );
    setFinalFormData(requestBody);
    setIsConfirmationDialogOpen(true);
  };

  const renderDayContents = (day, date) => {
    return <span>{getDate(date)}</span>;
  };

  const handleDateChange = async (fieldName, date) => {
    await addLeaveForm.setFieldValue(fieldName, date);
  };

  const hideLeaveType = () => {
    return leaveType && leaveType === leaveTypeEnums.COMP_OFF;
  };

  const handleApply = async () => {
    setIsActionButtonDisabled(true);
    let response;

    if (leaveType === leaveTypeEnums.COMP_OFF) {
      response = await AddCompOffs(finalFormData);
    } else {
      response = await AddLeaves(finalFormData);
    }

    if (response.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      setIsConfirmationDialogOpen(false);
      closeModal();
      setIsActionButtonDisabled(false);
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
      setIsActionButtonDisabled(false);
    }
  };

  const handleClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  return (
    <>
      {isConfirmationDialogOpen ? (
        <AddLeaveDialog
          onApply={handleApply}
          onClose={handleClose}
          leaveData={finalFormData}
          isDialogOpen={isConfirmationDialogOpen}
          isActionButtonDisabled={isActionButtonDisabled}
          title={title}
        />
      ) : null}

      <form onSubmit={addLeaveForm.handleSubmit}>
        <Box sx={styles.mainContainer}>
          <Box sx={styles.dateContainer}>
            {isLoading ? (
              <RectangularLoader loaderAlignX={80} />
            ) : (
              <Box sx={styles.dateContentContainer}>
                <label className={classes.lmsLabel}>Select Date</label>

                <Box sx={styles.outerBox}>
                  <Box sx={styles.borderBox}>
                    <Box sx={styles.rowContainer}>
                      <Box sx={styles.columnContainer}>
                        <DatePicker
                          id="datePicker-startDate"
                          className={classes.datePickerStyles}
                          selected={addLeaveForm.values.startDate}
                          onChange={(date) =>
                            handleDateChange('startDate', date)
                          }
                          placeholderText="Select Start Date"
                          onKeyDown={(e) => e.preventDefault()}
                          renderDayContents={renderDayContents}
                          filterDate={disableFutureDates}
                          autoComplete="off"
                        />
                      </Box>

                      <Box sx={styles.separator}>-</Box>
                      <Box sx={styles.columnContainer}>
                        <DatePicker
                          id="datePicker-endDate"
                          className={classes.datePickerStyles}
                          required
                          selected={addLeaveForm.values.endDate}
                          onChange={(date) => handleDateChange('endDate', date)}
                          placeholderText="Select End Date"
                          onKeyDown={(e) => e.preventDefault()}
                          renderDayContents={renderDayContents}
                          filterDate={disableFutureDates}
                          autoComplete="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={styles.centerBox}>
                    <FormHelperText sx={styles.errorText}>
                      {addLeaveForm.errors.startDate ||
                        addLeaveForm.errors.endDate}
                    </FormHelperText>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          {!hideLeaveType() ? (
            <Box sx={styles.columnContainer}>
              <FormControl sx={styles.formControl}>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={styles.formLabel}
                  className={classes.lmsLabel}
                >
                  Leave Type
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="leaveType"
                  id="leave-type"
                  onBlur={addLeaveForm.handleBlur}
                  onChange={(event) => {
                    addLeaveForm.handleChange(event);
                  }}
                  value={addLeaveForm.values.leaveType}
                  sx={styles.radioGroup}
                >
                  {leaveTypes.map((leaveTypeItem) => {
                    return (
                      <FormControlLabel
                        key={`leaveType_${leaveTypeItem.value}`}
                        value={leaveTypeItem.value}
                        control={<Radio />}
                        label={leaveTypeItem.title}
                        sx={styles.formControlLabel}
                      />
                    );
                  })}
                </RadioGroup>
                <FormHelperText sx={styles.errorText}>
                  {addLeaveForm.touched.leaveType &&
                    addLeaveForm.errors.leaveType}
                </FormHelperText>
              </FormControl>
            </Box>
          ) : null}

          <Box sx={styles.fullWidthColumn}>
            <label className={classes.lmsLabel}>May I know the Reason?</label>
            <TextField
              id="reason-leave"
              name="reason"
              helperText={
                addLeaveForm.touched.reason && addLeaveForm.errors.reason
              }
              error={
                addLeaveForm.touched.reason &&
                Boolean(addLeaveForm.errors.reason)
              }
              onBlur={addLeaveForm.handleBlur}
              onChange={addLeaveForm.handleChange}
              value={addLeaveForm.values.reason}
              multiline
              rows={3}
            />
          </Box>

          <Box sx={styles.flexRowCenter}>
            <Box sx={styles.columnContainer}>
              <Button
                className={classes.lmsSubmitBtn}
                variant="contained"
                type="submit"
                sx={styles.submitButton}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default AddLeaveForm;

const useStyles = makeStyles(() => ({
  lmsLabel: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  lmsSubmitBtn: {
    backgroundColor: '#162952 !important',
    borderRadius: '35px !important',
    width: '137px',
  },
  inputStyles: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem) !important',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem) !important',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem) !important',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem) !important',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
    },
  },
  datePickerStyles: {
    height: '36px',
    border: '1px solid grey !important',
    borderRadius: '5px',
    '&::placeholder': {
      textAlign: 'center',
    },
    textAlign: 'center',
  },
}));
