import { useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import { iSwipeData } from '../../types/types';
import { cardSwipeSystem } from '../../constants/common';

const styles = {
  tableCell: {
    fontWeight: 'bold',
  },
  errorText: {
    color: 'red',
    fontWeight: 'bold',
  },
};

function Row(props: { row: iSwipeData }) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.date}</TableCell>
        <TableCell>{row.day}</TableCell>
        <TableCell>{row.project}</TableCell>
        <TableCell>{row.clockIn}</TableCell>
        <TableCell>{row.clockOut}</TableCell>
        <TableCell>{row.total}</TableCell>
      </TableRow>
      {open ? (
        <TableRow>
          <TableCell colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {cardSwipeSystem.cardSwipeDetails}
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={styles.tableCell}>
                        {cardSwipeSystem.clockInEntireDay}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {cardSwipeSystem.clockOutEntireDay}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.swipeHistory.map((history) => (
                      <TableRow key={Math.random() * 100}>
                        <TableCell>
                          {history.clockIn.length > 0 ? (
                            history.clockIn
                          ) : (
                            <Box sx={styles.errorText}>
                              {cardSwipeSystem.swipeInDataNotAvailable}
                            </Box>
                          )}
                        </TableCell>
                        <TableCell>
                          {history.clockOut.length > 0 ? (
                            history.clockOut
                          ) : (
                            <Box sx={styles.errorText}>
                              {cardSwipeSystem.swipeOutDataNotAvailable}
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
}

export default Row;
