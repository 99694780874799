import { USER_INFO_PATH } from '../constants/endpoints';
import { getSessionStorageItem } from '../encrypt';
import { request } from './request';

export const getRoles = async (): Promise<{
  success: boolean;
  formData?: any;
  message?: string;
}> => {
  const res: any = await request.get(
    `${getSessionStorageItem('gatewayURL')}/auth/realms/${getSessionStorageItem(
      'realm'
    )}${USER_INFO_PATH}`
  );
  if (res) {
    return { success: true, formData: res, message: '' };
  }
  return { success: false };
};
