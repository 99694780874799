import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Finance from './forms/finance';

import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const useStyles = makeStyles(() => ({
  empTabs: {
    backgroundColor: '#f8e7e3',
    height: '100vh',
    '&.MuiTabs-root .MuiTabs-indicator': {
      backgroundColor: 'red',
    },
  },
  empTab: {
    '&.Mui-selected': {
      color: 'black !important',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width={900} sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function FormsDetails(props: any) {
  const classes = useStyles();
  const { closeModal, setOpenFormExitPopup } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleValue = (newValue, buttonName) => {
  //   if (buttonName == "next") {
  //     setValue(newValue + 1);
  //   }
  //   if (buttonName == "back") {
  //     setValue(newValue - 1);
  //   }
  // };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: 'auto',
      }}
    >
      <Button
        sx={{ color: 'red', position: 'absolute', marginLeft: '93%' }}
        onClick={() => closeModal(false)}
      >
        <CloseOutlinedIcon sx={{ margin: '2px' }} />
      </Button>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        scrollButtons={false}
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.empTabs}
      >
        <Tab
          className={classes.empTab}
          label="FINANCE"
          {...a11yProps(0)}
          icon={
            value === 0 ? (
              <svg
                width="30"
                height="30"
                viewBox="0 0 61 61"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.47027 32.3452H55.415C56.4001 32.3452 57.1988 31.5466 57.1988 30.5615V1.78374C57.1988 0.798641 56.4001 0 55.415 0H5.47027C4.48516 0 3.68652 0.798641 3.68652 1.78374V30.5615C3.68652 31.5466 4.48516 32.3452 5.47027 32.3452ZM7.25401 3.56749H53.6313V28.7777H7.25401V3.56749Z"
                  fill="#F15830"
                />
                <path
                  d="M60.4941 43.7735C57.4218 39.9338 51.7981 39.3094 47.9582 42.3811C46.2513 43.7465 45.0464 44.6666 44.1317 45.2887C42.861 41.9109 39.5791 39.52 35.7938 39.52C32.7436 39.52 28.6336 39.3792 23.8849 38.2809C19.2518 37.2177 16.6779 37.4688 12.218 39.48H1.78374C0.798641 39.48 0 40.2787 0 41.2638V59.1012C0 60.0863 0.798641 60.8849 1.78374 60.8849H12.6051C13.5902 60.8849 14.3889 60.0863 14.3889 59.1012V57.3638C17.2154 56.5787 19.6455 56.4984 22.1307 57.0907C26.0133 58.016 29.6503 58.4771 33.1135 58.4771C43.9698 58.4771 53.0069 53.8747 60.3931 46.1179C61.0084 45.4719 61.0516 44.4702 60.4941 43.7735ZM3.56748 57.3176V43.0476H10.8214V57.3176H3.56748ZM22.9577 53.6205C20.143 52.9497 17.4251 52.9619 14.3889 53.681V42.4214C17.6835 41.0116 19.4434 40.9219 23.084 41.7574C28.1813 42.9363 32.5539 43.0876 35.7938 43.0876C38.0888 43.0876 40.0739 44.5505 40.815 46.6066C40.7484 46.6098 26.8751 46.6151 26.8751 46.6151C25.8899 46.6151 25.0913 47.4138 25.0913 48.3989C25.0913 49.384 25.8899 50.1826 26.8751 50.1826C26.8751 50.1826 40.6819 50.1822 40.702 50.1817C43.7298 50.0795 45.2997 49.0763 50.1867 45.1671C52.0672 43.6628 54.6606 43.6358 56.5526 44.9292C44.2318 56.9206 31.5403 55.6658 22.9577 53.6205Z"
                  fill="#F15830"
                />
                <path
                  d="M16.1725 10.7025C17.1576 10.7025 17.9563 9.90383 17.9563 8.91872C17.9563 7.93362 17.1576 7.13498 16.1725 7.13498H12.605C11.6199 7.13498 10.8213 7.93362 10.8213 8.91872V23.4265C10.8213 24.4116 11.6199 25.2103 12.605 25.2103H16.1725C17.1576 25.2103 17.9563 24.4116 17.9563 23.4265C17.9563 22.4414 17.1576 21.6428 16.1725 21.6428H14.3888V10.7025H16.1725Z"
                  fill="#F15830"
                />
                <path
                  d="M48.2799 7.13498H44.7125C43.7274 7.13498 42.9287 7.93362 42.9287 8.91872C42.9287 9.90383 43.7274 10.7025 44.7125 10.7025H46.4962V21.6428H44.7125C43.7274 21.6428 42.9287 22.4414 42.9287 23.4265C42.9287 24.4116 43.7274 25.2103 44.7125 25.2103H48.2799C49.265 25.2103 50.0637 24.4116 50.0637 23.4265V8.91872C50.0637 7.93362 49.265 7.13498 48.2799 7.13498Z"
                  fill="#F15830"
                />
                <path
                  d="M30.4426 7.13498C25.5129 7.13498 21.5239 11.2415 21.5239 16.1726C21.5239 21.1027 25.5117 25.2103 30.4426 25.2103C35.3724 25.2103 39.3614 21.1037 39.3614 16.1726C39.3614 11.2426 35.3736 7.13498 30.4426 7.13498ZM30.4426 21.6428C27.5419 21.6428 25.0914 19.1378 25.0914 16.1726C25.0914 13.2074 27.5419 10.7025 30.4426 10.7025C33.3434 10.7025 35.7939 13.2074 35.7939 16.1726C35.7939 19.1378 33.3434 21.6428 30.4426 21.6428Z"
                  fill="#F15830"
                />
              </svg>
            ) : (
              <svg
                width="30"
                height="30"
                viewBox="0 0 61 61"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.47027 32.3452H55.415C56.4001 32.3452 57.1988 31.5466 57.1988 30.5615V1.78374C57.1988 0.798641 56.4001 0 55.415 0H5.47027C4.48516 0 3.68652 0.798641 3.68652 1.78374V30.5615C3.68652 31.5466 4.48516 32.3452 5.47027 32.3452ZM7.25401 3.56749H53.6313V28.7777H7.25401V3.56749Z"
                  fill="#CDA59C"
                />
                <path
                  d="M60.4941 43.7735C57.4218 39.9338 51.7981 39.3094 47.9582 42.3811C46.2513 43.7465 45.0464 44.6666 44.1317 45.2887C42.861 41.9109 39.5791 39.52 35.7938 39.52C32.7436 39.52 28.6336 39.3792 23.8849 38.2809C19.2518 37.2177 16.6779 37.4688 12.218 39.48H1.78374C0.798641 39.48 0 40.2787 0 41.2638V59.1012C0 60.0863 0.798641 60.8849 1.78374 60.8849H12.6051C13.5902 60.8849 14.3889 60.0863 14.3889 59.1012V57.3638C17.2154 56.5787 19.6455 56.4984 22.1307 57.0907C26.0133 58.016 29.6503 58.4771 33.1135 58.4771C43.9698 58.4771 53.0069 53.8747 60.3931 46.1179C61.0084 45.4719 61.0516 44.4702 60.4941 43.7735ZM3.56748 57.3176V43.0476H10.8214V57.3176H3.56748ZM22.9577 53.6205C20.143 52.9497 17.4251 52.9619 14.3889 53.681V42.4214C17.6835 41.0116 19.4434 40.9219 23.084 41.7574C28.1813 42.9363 32.5539 43.0876 35.7938 43.0876C38.0888 43.0876 40.0739 44.5505 40.815 46.6066C40.7484 46.6098 26.8751 46.6151 26.8751 46.6151C25.8899 46.6151 25.0913 47.4138 25.0913 48.3989C25.0913 49.384 25.8899 50.1826 26.8751 50.1826C26.8751 50.1826 40.6819 50.1822 40.702 50.1817C43.7298 50.0795 45.2997 49.0763 50.1867 45.1671C52.0672 43.6628 54.6606 43.6358 56.5526 44.9292C44.2318 56.9206 31.5403 55.6658 22.9577 53.6205Z"
                  fill="#CDA59C"
                />
                <path
                  d="M16.1725 10.7025C17.1576 10.7025 17.9563 9.90383 17.9563 8.91872C17.9563 7.93362 17.1576 7.13498 16.1725 7.13498H12.605C11.6199 7.13498 10.8213 7.93362 10.8213 8.91872V23.4265C10.8213 24.4116 11.6199 25.2103 12.605 25.2103H16.1725C17.1576 25.2103 17.9563 24.4116 17.9563 23.4265C17.9563 22.4414 17.1576 21.6428 16.1725 21.6428H14.3888V10.7025H16.1725Z"
                  fill="#CDA59C"
                />
                <path
                  d="M48.2799 7.13498H44.7125C43.7274 7.13498 42.9287 7.93362 42.9287 8.91872C42.9287 9.90383 43.7274 10.7025 44.7125 10.7025H46.4962V21.6428H44.7125C43.7274 21.6428 42.9287 22.4414 42.9287 23.4265C42.9287 24.4116 43.7274 25.2103 44.7125 25.2103H48.2799C49.265 25.2103 50.0637 24.4116 50.0637 23.4265V8.91872C50.0637 7.93362 49.265 7.13498 48.2799 7.13498Z"
                  fill="#CDA59C"
                />
                <path
                  d="M30.4426 7.13498C25.5129 7.13498 21.5239 11.2415 21.5239 16.1726C21.5239 21.1027 25.5117 25.2103 30.4426 25.2103C35.3724 25.2103 39.3614 21.1037 39.3614 16.1726C39.3614 11.2426 35.3736 7.13498 30.4426 7.13498ZM30.4426 21.6428C27.5419 21.6428 25.0914 19.1378 25.0914 16.1726C25.0914 13.2074 27.5419 10.7025 30.4426 10.7025C33.3434 10.7025 35.7939 13.2074 35.7939 16.1726C35.7939 19.1378 33.3434 21.6428 30.4426 21.6428Z"
                  fill="#CDA59C"
                />
              </svg>
            )
          }
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Finance
          setValue={setValue}
          setOpenFormExitPopup={setOpenFormExitPopup}
        />
      </TabPanel>
    </Box>
  );
}
