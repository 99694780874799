import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

interface FormExitPopupProps {
  callbackAction: any;
  isShowFormExitPopup: any;
  setIsShowFormExitPopup: any;
}
export const FormExitPopupActions = {
  LEAVE: 'leave',
  CANCEL: 'cancel',
};

const useStyles = makeStyles(() => ({
  modalRoot: {
    width: '25%',
    minWidth: '180px',
    borderRadius: '12px',
  },
  popupHeading: {
    fontSize: '18px !important',
    padding: '18px 18px 8px 18px !important',
    whiteSpace: 'nowrap !important',
    display: 'flex !important',
    justifyContent: 'flex-start',
    alignItems: 'center !important',
    fontWeight: 'bold !important',
  },
  popupMessage: {
    fontSize: '16px !important',
    padding: '0 18px 12px !important',
    // whiteSpace: "nowrap",
    display: 'flex !important',
    justifyContent: 'flex-start !important',
    alignItems: 'center !important',
  },
  ctaButtonContainer: {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    alignItems: 'center !important',
    margin: '18px !important',
    gap: '18px !important',
  },
}));
const styles = {
  ctaCancelButton: {
    textTransform: 'capitalize !important',
    background: 'transparent !important',
    border: '1px solid rgba(18, 18, 18, 0.2) !important',
    color: 'black !important',
    '&:hover': {
      background: 'transparent !important',
    },
  },
  ctaLeaveButton: {
    textTransform: 'capitalize',
  },
};

const FormExitPopup = ({
  callbackAction,
  isShowFormExitPopup,
  setIsShowFormExitPopup,
}: FormExitPopupProps) => {
  const classes = useStyles();

  return (
    <Modal open={isShowFormExitPopup}>
      <div className={`${classes.modalRoot} modal-container`}>
        <Box>
          <Typography className={classes.popupHeading}>
            Are you sure you want to Leave?
          </Typography>
          <Typography className={classes.popupMessage}>
            Changes that you have made will not be saved.
          </Typography>
          <Box className={classes.ctaButtonContainer}>
            <Button
              variant="contained"
              sx={styles.ctaLeaveButton}
              onClick={() => {
                setIsShowFormExitPopup(false);
                callbackAction(FormExitPopupActions.LEAVE);
              }}
            >
              Leave
            </Button>
            <Button
              variant="contained"
              sx={styles.ctaCancelButton}
              onClick={() => callbackAction(FormExitPopupActions.CANCEL)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default FormExitPopup;
