import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { deactiveEmployeePost } from '../services/formService';
import * as Yup from 'yup';

import {
  modalAdminGroup,
  modalFinanroup,
  modalHrAdminGroup,
  modalLeadGroup,
  modalNcgGroup,
} from './uiElementsAccess';
import { store } from '../redux/store';
import {
  pushNotification,
  setLoader,
  setOfficialEmailId,
  setReportingToEmailId,
  setUserData,
} from '../redux/actions';
import CONSTANTS from '../constants/constants';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CustomTextField from './CustomTextField';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import { EmployeeStatusConstants } from './EmployeeList';

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    marginTop: 8,
  },
  heading: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 600,
  },
  createEmployeeBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  departureDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    gap: '16px',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%',
  },
}));

const styles = {
  lwdDatePicker: {
    containerMain: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      gap: '18px',
    },
    datePicker: {
      '& .MuiOutlinedInput-input': {
        padding: '8px !important',
        // fontSize: "14px",
        width: '100%',
        // maxWidth: "90px",
      },
      '& .MuiSvgIcon-root': {
        // fontSize: "16px !important",
        display: 'block !important',
      },
    },
  },
  boxes: {
    formBoxMain: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      maxHeight: '70%',
      borderRadius: 2,
      bgcolor: 'background.paper',
      p: 2,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
      },
    },
  },
  buttons: {
    closeButton: { color: 'red', position: 'absolute', right: 10 },
  },
  icons: {
    closeIcon: { margin: '2px' },
  },
};

const QuickViewOptions = {
  PROFILE_VIEW: 'profileView',
  DEACTIVATE: 'deactivate',
  EDIT: 'edit',
};
interface MoreIconProps {
  row: any;
  // setShow:any
  // setAction: any;
  // isAdmin:any
  setIsAdmin: any;
  isLead: any;
  setIsLead: any;
  // isFinance:any
  setIsFinance: any;
  // isNcg:any
  setIsNcg: any;
  // isHrAdmin:any
  setIsHrAdmin: any;
  emailId: any;
  // setOpenDeactivation:any
  // setPopUpMessage:any
  // showView:any
  setShowView: any;
  setShowProfileView: any;
  setRefresh: any;
  setUserData: any;
}

const MoreIcon = ({
  row,
  // setAction,
  setIsAdmin,
  setIsLead,
  setIsFinance,
  setIsNcg,
  setIsHrAdmin,
  emailId,
  setShowView,
  setShowProfileView,
  setRefresh,
  setUserData: setUserDataLocally,
}: MoreIconProps) => {
  const dispatch = useDispatch();
  const reduxState: any = store.getState();
  const classes = useStyles();
  const { userData } = useSelector((state: any) => ({
    userData: state.userData,
  }));

  const [openDeactivate, setOpenDeactivate] = React.useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const validationSchema = Yup.object({
    officialEmail: Yup.string(),
    lwd: Yup.string().required('Date & time is required'),
    departureReason: Yup.string().required('Reason required'),
    performanceSummary: Yup.string(),
    strengths: Yup.string(),
    areasImprovement: Yup.string(),
    additionalComments: Yup.string(),
    confidentialityChecked: Yup.boolean(),
    generalFormId: Yup.string(),
    leaveFormId: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      officialEmail: '',
      lwd: '',
      departureReason: '',
      performanceSummary: '',
      departureStrengths: '',
      areasImprovement: '',
      additionalComments: '',
      confidentialityChecked: false,
      generalFormId: '',
      leaveFormId: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleDeactivation(values);
    },
  });

  const handleClickDeactivate = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDeactivate(true);
  };

  const handleCloseDeactivate = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDeactivate(false);
  };

  const actions = [
    {
      actionId: QuickViewOptions.PROFILE_VIEW,
      actionName:
        row?.status === EmployeeStatusConstants.INACTIVE
          ? 'Quick View'
          : 'View Profile',
    },
    {
      actionId: QuickViewOptions.EDIT,
      actionName: 'Edit',
    },
    {
      actionId: QuickViewOptions.DEACTIVATE,
      actionName: 'Deactivate',
    },
  ];

  const menuColor = '#FF0000';
  // code for more menu
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeactivation = async (values: any) => {
    setButtonDisabled(true);
    dispatch(setLoader(true));

    const response = await deactiveEmployeePost(values);
    if (response?.success) {
      setButtonDisabled(false);
      dispatch(setLoader(false));
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      handleClose();
      setOpenDeactivate(false);
      setRefresh((prevState) => !prevState);
    } else {
      setButtonDisabled(false);
      dispatch(setLoader(false));
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
      handleClose();
      setOpenDeactivate(false);
      setRefresh((prevState) => !prevState);
    }
  };

  const handleActionClick = async (event, row, id) => {
    event.preventDefault();
    event.stopPropagation();
    setUserDataLocally(row);

    if (id === QuickViewOptions.EDIT) {
      handleClose();
      await dispatch(setOfficialEmailId(emailId));
      await dispatch(setReportingToEmailId(row?.reportingTo));
      if (modalAdminGroup.includes(reduxState.role)) {
        setIsAdmin(true);
      } else if (modalFinanroup.includes(reduxState.role)) {
        setIsFinance(true);
      } else if (modalNcgGroup.includes(reduxState.role)) {
        setIsNcg(true);
      } else if (modalLeadGroup.includes(reduxState.role)) {
        setIsLead(true);
      } else if (modalHrAdminGroup.includes(reduxState.role)) {
        setIsHrAdmin(true);
      }
    } else if (id === QuickViewOptions.PROFILE_VIEW) {
      await dispatch(setUserData(row));
      row?.status === EmployeeStatusConstants.INACTIVE
        ? setShowView((prevState) => !prevState)
        : setShowProfileView((prevState) => !prevState);
    } else if (id === QuickViewOptions.DEACTIVATE) {
      dispatch(setUserData(row));
      handleClickDeactivate(event);
    }
  };

  const handleFieldChange = (field: any, value: any) => {
    formik.handleChange({
      target: {
        name: field,
        value: value,
      },
    });
  };
  useEffect(() => {
    handleFieldChange('officialEmail', userData.officialEmail);
    handleFieldChange('generalFormId', userData.generalFormId);
    handleFieldChange('leaveFormId', userData.leaveFormId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <Modal
        open={openDeactivate}
        onClose={handleCloseDeactivate}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={styles.boxes.formBoxMain}>
            <Button
              sx={styles.buttons.closeButton}
              onClick={handleCloseDeactivate}
            >
              <CloseOutlinedIcon sx={styles.icons.closeIcon} />
            </Button>
            <Box
              display={'flex'}
              flexDirection="column"
              justifyContent={'center'}
              alignItems={'center'}
              padding={1}
              gap={2}
            >
              <Box className={classes.departureDetails}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  sx={{ width: '100%' }}
                >
                  <label className={classes.heading}>Departure Details:</label>

                  <label className={classes.label}>
                    <span>Please Select the Last Working Day for </span>
                    <span style={{ fontWeight: 800, color: '#f15830' }}>
                      {row.firstName.charAt(0).toUpperCase() +
                        row.firstName.slice(1).toLowerCase()}{' '}
                      {row.lastName.charAt(0).toUpperCase() +
                        row.lastName.slice(1).toLowerCase()}
                    </span>
                  </label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={styles.lwdDatePicker.datePicker}
                      onChange={(dateDayJs: any) => {
                        try {
                          const formattedDate = dateDayJs.format('YYYY-MM-DD');
                          handleFieldChange('lwd', formattedDate);
                        } catch (error: any) {
                          handleFieldChange('lwd', '');
                        }
                      }}
                      slotProps={{
                        actionBar: { actions: ['clear', 'today'] },
                      }}
                    />
                  </LocalizationProvider>
                  <label className={classes.label}>Reason for Departure*</label>
                  <FormControl sx={{ width: '100%' }} size="small">
                    <Select
                      labelId="probation"
                      id="probation"
                      value={formik.values.departureReason}
                      onChange={(e) => {
                        handleFieldChange('departureReason', e.target.value);
                      }}
                      error={
                        formik.touched.departureReason &&
                        Boolean(formik.errors.departureReason)
                      }
                    >
                      <MenuItem value={'Resignation'}>Resignation</MenuItem>
                      <MenuItem value={'Termination'}>Termination</MenuItem>
                      <MenuItem value={'Retirement'}>Retirement</MenuItem>
                    </Select>
                    {/* <FormHelperText sx={{ color: "red" }}>
                        {formOne.touched.departure && formOne.errors.departure}
                      </FormHelperText> */}
                  </FormControl>
                </Box>
                <Box className={classes.fieldContainer}>
                  <label className={classes.heading}>
                    Feedback and Remarks:
                  </label>
                  <label className={classes.label}>Performance Summary</label>

                  <CustomTextField
                    contentText={formik.values.performanceSummary}
                    handleContentTextChange={(e: any) => {
                      handleFieldChange('performanceSummary', e.target.value);
                    }}
                    color={'#121212'}
                    height={'60px'}
                    width={'100%'}
                    fontsize={'16px'}
                    // placeholder={"Performance summary"}
                  />

                  <label className={classes.label}>Strengths:</label>

                  <CustomTextField
                    contentText={formik.values.departureStrengths}
                    handleContentTextChange={(e: any) => {
                      handleFieldChange('departureStrengths', e.target.value);
                    }}
                    color={'#121212'}
                    height={'60px'}
                    width={'100%'}
                    fontsize={'16px'}
                  />
                  <label className={classes.label}>
                    Areas for Improvement:
                  </label>

                  <CustomTextField
                    contentText={formik.values.areasImprovement}
                    handleContentTextChange={(e: any) => {
                      handleFieldChange('areasImprovement', e.target.value);
                    }}
                    color={'#121212'}
                    height={'60px'}
                    width={'100%'}
                    fontsize={'16px'}
                  />
                  <label className={classes.label}>Additional Comments:</label>

                  <CustomTextField
                    contentText={formik.values.additionalComments}
                    handleContentTextChange={(e: any) => {
                      handleFieldChange('additionalComments', e.target.value);
                    }}
                    color={'#121212'}
                    height={'60px'}
                    width={'100%'}
                    fontsize={'16px'}
                  />
                </Box>
                <Box className={classes.fieldContainer}>
                  <label className={classes.heading}>
                    Confidentiality and Compliance:
                  </label>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.confidentialityChecked}
                        onClick={() => {
                          handleFieldChange(
                            'confidentialityChecked',
                            !formik.values.confidentialityChecked
                          );
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Check if compliance with company policies on
                        confidentiality is ensured.*
                      </Typography>
                    }
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  disabled={
                    isButtonDisabled ||
                    formik.values.lwd === '' ||
                    formik.values.departureReason === '' ||
                    !formik.values.confidentialityChecked
                  }
                  sx={{ backgroundColor: 'rgb(22, 41, 82)' }}
                  type="submit"
                  onClick={() => {
                    formik.submitForm();
                  }}
                >
                  Deactivate
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Modal>

      <Box>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(e) => handleClick(e)}
          sx={{ padding: 0 }}
        >
          <MoreVertIcon sx={{ color: menuColor, marginBottom: '5px' }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '15ch',
            },
          }}
        >
          {actions?.map((item, index) => {
            const isDisable = Boolean(
              (item.actionId === QuickViewOptions.EDIT &&
                row?.status === EmployeeStatusConstants.INACTIVE) ||
                (item.actionId === QuickViewOptions.DEACTIVATE &&
                  row?.status === EmployeeStatusConstants.INACTIVE) ||
                (item.actionId === QuickViewOptions.DEACTIVATE &&
                  !modalHrAdminGroup.includes(reduxState?.role))
            );
            return (
              <MenuItem
                key={item.actionId || index}
                id={item.actionId}
                onClick={(e) => handleActionClick(e, row, item.actionId)}
                sx={{ color: menuColor }}
                disabled={isDisable}
                style={{
                  display:
                    item.actionId === QuickViewOptions.DEACTIVATE &&
                    !modalHrAdminGroup.includes(reduxState?.role)
                      ? 'none'
                      : '',
                }}
              >
                {item.actionName}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </>
  );
};

export default MoreIcon;
