import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  chipClasses,
  Divider,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import { SKILLSET_MSG } from '../../../../constants/formConstants';
import { store } from '../../../../redux/store';
import {
  fetchRuntimeFormData,
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from '../../../../services/formService';
import {
  SKILLS_FORM_ID,
  SKILLSET_VALUES_FORM_ID,
} from '../../../../constants/formsConstants';
import { skillSetMsg, skillSetMsg1 } from './formConstants';
import { pushNotification } from '../../../../redux/actions';
import CONSTANTS from '../../../../constants/constants';
import { useDispatch } from 'react-redux';
import Label from '../../../../components/common/controls/Label';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: 'red',
  },
  limitWidth: {
    width: 402,
  },
  inputField: {
    '& fieldset': {
      borderRadius: 12,
    },
  },
  Heading: {
    height: '22px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#30374C',
    textAlign: 'left',
    marginBottom: 30,
  },
  submitBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    gap: '500px',
  },
}));

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  marginBottom: 16,
  paddingTop: 0,
  paddingBotom: 0,
}));

const SkillSet = (props: any) => {
  const { setOpenFormExitPopup } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const reduxState = store.getState();

  const [id, setId] = React.useState<any>();

  const [enteredSkill, setEnteredSkill] = React.useState('');

  const [skills, setSkills] = React.useState<string[]>([
    'Python',
    'React',
    'Java',
    'SpringBoot',
    'Other',
  ]);
  const formOne = useFormik({
    initialValues: { skills: [] },
    validationSchema: Yup.object({
      skills: Yup.array().min(1, skillSetMsg).max(30, skillSetMsg1),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitFormOne(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmitFormOne = async (values: any, setSubmitting: any) => {
    setSubmitting(false);
    postFormValues(values);
  };

  const handleRemoveChip = (item: string, itemArray: string[]) => {
    const index = itemArray.findIndex((elem) => elem === item);
    if (index !== -1) {
      itemArray.splice(index, 1);
      return itemArray;
    }
  };

  const formPayload = (values: any) => ({
    formId: SKILLS_FORM_ID,
    formData: {
      reportingTo: reduxState.reportingToEmailId,
      officialEmail: reduxState.officialEmailId,
      skills: values.skills.toString(),
      skillsStatus: 'Pending',
    },
  });
  const postFormValues = async (values: any) => {
    const postData = formPayload(values);

    if (id) {
      const payload = {
        formId: postData.formId,
        id: id,
        formData: postData.formData,
      };
      const response = await postForm(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setOpenFormExitPopup(false);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    } else {
      const response = await postForm(postData);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setOpenFormExitPopup(false);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    }
  };

  useEffect(() => {
    prefillData();
    handleGetSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState.officialEmailId]);

  useEffect(() => {
    const caller = async () => {
      await prefillData();
      // setFormInitialData(formOne.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpenFormExitPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOne.values]);

  const handleGetSkills = async () => {
    const response = await fetchRuntimeFormData(SKILLSET_VALUES_FORM_ID);

    if (response?.success) {
      setSkills(response?.data?.content[0]?.formData?.skills);
    } else {
      setSkills(skills);
    }
  };

  const prefillData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      SKILLS_FORM_ID,
      reduxState.officialEmailId
    );
    if (response.success && response?.data?.content?.length > 0) {
      const mapData: any = response?.data?.content[0]?.formData;
      setId(response?.data?.content[0]?.id);
      const finalData = {
        skills:
          Object.prototype.hasOwnProperty.call(mapData, 'skills') &&
          mapData?.skills?.length > 0
            ? mapData.skills.split(',')
            : [],
      };

      formOne.setValues(finalData);
    } else {
      formOne.setValues({
        skills: [],
      });
    }
  };

  // const handleOptionChange = (event: React.ChangeEvent<{}>, values: any) => {
  //   formOne.setFieldValue("skills", values);
  //   // onChange(values);
  // };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredSkill(event.target.value);
    // Handle manual input changes if needed
    // e.g., fetch options from an API based on the user's input
  };

  return (
    <>
      <Box>
        <form onSubmit={formOne.handleSubmit}>
          <Typography color={'#f15830'} width="100%">
            Skill set <Divider />
          </Typography>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            mt={5}
            className={classes.limitWidth}
          >
            <Item>
              <Label title={SKILLSET_MSG} />
              <div
                style={{
                  height: 'auto',
                  width: '190%',
                  border: '1px solid black',
                  borderRadius: '10px',
                  padding: '15px',
                }}
              >
                {formOne?.values?.skills?.length > 0 ? (
                  <>
                    {formOne.values.skills?.map((item) => (
                      <Chip
                        // disabled={!props.hasButtons}
                        sx={{
                          [`& .${chipClasses.deleteIcon}`]: {
                            color: '#4D6CD9',
                          },
                        }}
                        label={item}
                        key={item}
                        onDelete={() => {
                          const newArray = handleRemoveChip(
                            item,
                            formOne.values.skills
                          );
                          if (newArray)
                            formOne.setFieldValue('skills', [...newArray]);

                          if (item === 'All')
                            formOne.setFieldValue('topFiveSkills', []);
                        }}
                        className="list-chip-style"
                        deleteIcon={<CloseIcon />}
                      />
                    ))}
                  </>
                ) : null}
                <FormControl
                  sx={{ width: '100%', padding: 0, marginTop: '20px' }}
                >
                  {/* <Autocomplete
                    multiple
                    options={skills}
                    value={formOne.values.skills}
                    onChange={handleOptionChange}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select options"
                        onChange={handleInputChange}
                      />
                    )}
                  /> */}

                  <Autocomplete
                    // disabled={!props.hasButtons}
                    options={skills}
                    multiple
                    value={formOne.values.skills}
                    renderTags={() => null}
                    id="skills"
                    onChange={(e, value) =>
                      formOne.setFieldValue('skills', value)
                    }
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        name="skills"
                        value={
                          !formOne.values.skills
                            ? 'Please Select *'
                            : formOne.values.skills.join(', ')
                        }
                        onChange={handleInputChange}
                        onKeyDown={(ev) => {
                          if (ev.key === 'Enter') {
                            ev.preventDefault();
                            formOne.setFieldValue('skills', [
                              ...formOne.values.skills,
                              enteredSkill,
                            ]);
                          }
                        }}
                        className={classes.inputField}
                        error={
                          formOne.touched.skills &&
                          Boolean(formOne.errors.skills)
                        }
                        helperText={
                          formOne.touched.skills && formOne.errors.skills
                        }
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          className="list-checked-icon"
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                  />
                </FormControl>
              </div>
            </Item>
          </Grid>
          <Box mt={1} mb={2} ml={2}>
            Note: Don&apos;t hesitate to introduce any additional, ad hoc skills
            that may have been overlooked.
          </Box>
          <Box
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignContent: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#f15830',
                color: 'white',
              }}
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default SkillSet;
