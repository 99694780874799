import './profileDetails.css';
import EmployeeModal from '../../EmployeeModal';
import {
  Box,
  Grid,
  IconButton,
  Menu,
  TextField,
  Tooltip,
  Zoom,
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { pushNotification, setLoader } from '../../../redux/actions';
import CONSTANTS from '../../../constants/constants';
import CircularLoader from '../../../Loaders/CircularLoader';
import RectangularLoader from '../../../Loaders/ContentLoader/Loader';
import InputAdornment from '@mui/material/InputAdornment';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  formatDate,
  splitPhoneByCountryCode,
} from '../../../services/commonHelperService';
import { ContentCopy } from '@mui/icons-material';
import { getSessionStorageItem } from '../../../encrypt';
import { openFile } from '../../../services/DocumentService';

interface iProfileDetailsProps {
  profileDetails: any;
  isLoading: boolean;
  isEmployeeModalOpen: boolean;
  setIsEmployeeModalOpen: any;
  menuAnchorElement: any;
  closeMenu?: any;
  handleFileChange?: any;
  handleOpenMenu?: any;
  handleLinkedinPost?: any;
  handleLinkedinData: any;
  linkedinUrl: any;
  isQuickView?: any;
}

const styles = {
  modals: {
    employeeModal: {
      '&.MuiButtonBase-root .MuiButton-textSizeMedium': {
        'padding-left': '1.5em',
      },
    },
  },
  icons: {
    doneIcon: {
      cursor: 'pointer',
      borderRadius: '50%',
      padding: '2px',
      transition: '0.3s',
      '&:hover': {
        boxShadow: '0 0 12px gray',
        transition: '0.3s',
      },
    },
    editIcon: {
      cursor: 'pointer',
      borderRadius: '50%',
      padding: '2px',
      transition: '0.3s',
      '&:hover': {
        boxShadow: '0 0 12px gray',
        transition: '0.3s',
      },
    },
  },
  boxes: {
    helpDoc: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '18px',
    },
    copyTool: {
      cursor: 'pointer',
      padding: '2px',
      transition: '0.3s',
      '&:hover': {
        transform: 'scale(1.1)',
        transition: '0.3s',
      },
    },
  },
  image: {
    container: {
      border: '1px solid lightgrey',
      borderRadius: '50%',
      padding: '13px',
    },
    innerContainer: {
      border: '1px solid grey',
      borderRadius: '50%',
    },
  },
};

const ProfileDetails = ({
  profileDetails,
  isLoading,
  isEmployeeModalOpen,
  setIsEmployeeModalOpen,
  menuAnchorElement,
  closeMenu,
  handleFileChange,
  handleOpenMenu,
  handleLinkedinPost,
  handleLinkedinData,
  linkedinUrl,
  isQuickView = false,
}: iProfileDetailsProps) => {
  const dispatch = useDispatch();
  const open = Boolean(menuAnchorElement);
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  const emp360HelpDocPdfID = getSessionStorageItem('emp360HelpDocPdfID');

  const [linkedInData, setLinkedInData] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [toolTipText, setToolTipText] = useState(
    !isQuickView ? 'Edit' : 'Copy'
  );
  const [isToolTipVisible, setIsToolTipVisible] = useState(false);
  const [userData, setUserData] = useState<any>({});

  const setAndhandleLinkedinData = (event) => {
    handleLinkedinData(event);
  };
  const postLinkedinUrl = () => {
    handleLinkedinPost();
    setIsEditing(false);
    setToolTipText(!isQuickView ? 'Edit' : 'Copy');
    setIsToolTipVisible(false);
  };

  useEffect(() => {
    setLinkedInData(linkedinUrl);
  }, [linkedinUrl]);

  useEffect(() => {
    setUserData({
      employeeId: profileDetails?.empId,

      officialEmailId: profileDetails?.emailId,
    });
  }, [profileDetails]);

  const handleEditClick = () => {
    setIsEditing(true);
    setToolTipText(!isQuickView ? 'Save' : 'Copy');
    setIsToolTipVisible(false);
  };

  const getDataContent = (label, value) => {
    return (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="profile-details-item">
          <span className="profile-details-label">{label}</span>

          {isLoading ? (
            <RectangularLoader />
          ) : (
            <Tooltip
              title={value}
              TransitionComponent={Zoom}
              placement="bottom"
              arrow
            >
              <span className="profile-details-value">{value}</span>
            </Tooltip>
          )}
        </div>
      </Grid>
    );
  };

  const emp360HelpDocumentation = async () => {
    dispatch(setLoader(true));

    if (emp360HelpDocPdfID) {
      const response = await openFile(emp360HelpDocPdfID);
      dispatch(setLoader(false));
      window.open(response, '_blank');
    } else {
      dispatch(setLoader(false));
      console.error('EMP360 Help Document URL not found.');
    }
  };

  const handleCopyClick = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        dispatch(
          pushNotification({
            isOpen: true,

            message: CONSTANTS.COPY_SUCCESS,

            type: CONSTANTS.SUCCESS,
          })
        );
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  };

  return (
    <>
      {!isQuickView && (
        <Menu
          id="basic-menu"
          anchorEl={menuAnchorElement}
          open={open}
          onClose={closeMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <>
            <Box display={'flex'} flexDirection={'column'} padding={2}>
              <Box mb={3} fontSize={18} fontWeight={'bold'}>
                Upload Profile Picture
              </Box>

              <FileUploader
                multiple={false}
                handleChange={handleFileChange}
                name="file"
                maxSize={2}
                types={fileTypes}
                onTypeError={(error) => {
                  dispatch(
                    pushNotification({
                      isOpen: true,

                      message: `${error}${CONSTANTS.SUPPORTED_FILES}`,

                      type: CONSTANTS.ERROR,
                    })
                  );
                }}
              />
            </Box>
          </>
        </Menu>
      )}

      <div className="profile-details-card">
        {/* <div className={'emp-details-container emp-location-container'}>
          <span className="emp-location"><LocationDisplay /></span>
        </div> */}
        {!isQuickView && (
          <div className="edit-button-container">
            <EmployeeModal
              show={isEmployeeModalOpen}
              setShow={setIsEmployeeModalOpen}
              sx={styles.modals.employeeModal}
              userData={userData}
            />
          </div>
        )}

        {isLoading ? (
          <div className={'profile-avatar-loading-container'}>
            <CircularLoader />
          </div>
        ) : (
          <>
            <div className="profile-avatar">
              <div style={styles.image.container}>
                <div style={styles.image.innerContainer}>
                  <IconButton onClick={handleOpenMenu}>
                    <img
                      src={profileDetails?.profileImage}
                      className="card-img-top"
                      alt="Profile-pcture-home-emp"
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </>
        )}

        <div className={'emp-details-container'}>
          {isLoading ? (
            <RectangularLoader loaderAlignX={80} />
          ) : (
            <span className="emp-name">{profileDetails?.fullName}</span>
          )}
        </div>

        <div className={'emp-details-container'}>
          {isLoading ? (
            <RectangularLoader loaderAlignX={80} />
          ) : (
            <span className="emp-designation">{profileDetails?.role}</span>
          )}
        </div>

        <Grid container spacing={2} className={'profile-detail-grid-container'}>
          {getDataContent('Emp ID', profileDetails?.empId)}
          {getDataContent('Email', profileDetails?.emailId)}
          {getDataContent(
            'Phone',

            profileDetails?.phone !== '...'
              ? splitPhoneByCountryCode('in', profileDetails?.phone)
              : profileDetails?.phone
          )}
          {getDataContent('Location', profileDetails?.location)}
          {getDataContent('Date of Birth', formatDate(profileDetails?.dob))}
          {getDataContent('Marital Status', profileDetails?.maritalStatus)}
          {getDataContent('Blood Group', profileDetails?.bloodGroup)}
          {getDataContent(
            'Emergency No',
            profileDetails?.emergencyNo !== '...'
              ? splitPhoneByCountryCode('in', profileDetails?.emergencyNo)
              : profileDetails?.emergencyNo
          )}
          {getDataContent('Reporting To', profileDetails?.reportingTo)}
          {getDataContent('Department', profileDetails?.department)}
          {getDataContent(
            'Date of Joining',
            formatDate(profileDetails?.dateOfJoining)
          )}
        </Grid>

        <div className={'linkedin-container'}>
          <TextField
            sx={{
              width: `${isEditing ? '80%' : '50%'}`,
            }}
            disabled={!isEditing}
            id="outlined-basic"
            variant="outlined"
            value={linkedInData}
            onChange={setAndhandleLinkedinData}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                    style={{ width: '20px', height: '20px' }}
                    alt="icon"
                  />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  {!isQuickView ? (
                    !isEditing ? (
                      <Tooltip title={toolTipText} open={isToolTipVisible}>
                        <EditIcon
                          fontSize="medium"
                          sx={styles.icons.editIcon}
                          onClick={handleEditClick}
                          color={'primary'}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={toolTipText} open={isToolTipVisible}>
                        <DoneIcon
                          fontSize="medium"
                          sx={styles.icons.doneIcon}
                          onClick={postLinkedinUrl}
                          color={'primary'}
                        />
                      </Tooltip>
                    )
                  ) : linkedInData !== '' ? (
                    <Tooltip title={toolTipText}>
                      <ContentCopy
                        fontSize="medium"
                        sx={styles.boxes.copyTool}
                        onClick={(e: any) => {
                          e.preventDefault();
                          handleCopyClick(linkedInData);
                        }}
                        color={'primary'}
                      />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </div>

        {!isQuickView && (
          <Box sx={styles.boxes.helpDoc}>
            <div className="help-documentation">
              <Tooltip
                title={'Download EMP 360 Help Document'}
                TransitionComponent={Zoom}
                placement="bottom"
                arrow
              >
                <span
                  className="help-documentation-text"
                  onClick={emp360HelpDocumentation}
                >
                  EMP360 Help Doc
                </span>
              </Tooltip>
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

export default ProfileDetails;
