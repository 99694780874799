import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

interface CommonPopupProps {
  isShowCommonPopup: any;
  setIsShowCommonPopup: any;
  heading: any;
  content: any;
}

const useStyles = makeStyles(() => ({
  modalRoot: {
    width: '25%',
    minWidth: '180px',
    borderRadius: '12px',
  },
  popupHeading: {
    fontSize: '18px !important',
    padding: '18px 18px 8px 18px !important',
    whiteSpace: 'nowrap !important',
    display: 'flex !important',
    justifyContent: 'flex-start',
    alignItems: 'center !important',
    fontWeight: 'bold !important',
  },
  popupMessage: {
    fontSize: '16px !important',
    padding: '0 18px 12px !important',
    display: 'flex !important',
    justifyContent: 'flex-start !important',
    alignItems: 'center !important',
  },
  ctaButtonContainer: {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    alignItems: 'center !important',
    margin: '18px !important',
    gap: '18px !important',
  },
}));
const styles = {
  ctaCancelButton: {
    textTransform: 'capitalize !important',
    background: 'transparent !important',
    border: '1px solid rgba(18, 18, 18, 0.2) !important',
    color: 'black !important',
    '&:hover': {
      background: 'transparent !important',
    },
  },
  ctaLeaveButton: {
    background: '#243f78 !important',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#162952 !important',
    },
  },
};

const CommonPopup = ({
  isShowCommonPopup,
  setIsShowCommonPopup,
  heading,
  content,
}: CommonPopupProps) => {
  const classes = useStyles();

  return (
    <Modal open={isShowCommonPopup}>
      <div className={`${classes.modalRoot} modal-container`}>
        <Box>
          <Typography className={classes.popupHeading}>{heading}</Typography>
          <Typography className={classes.popupMessage}>{content}</Typography>
          <Box className={classes.ctaButtonContainer}>
            <Button
              variant="contained"
              sx={styles.ctaLeaveButton}
              onClick={() => {
                setIsShowCommonPopup(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default CommonPopup;
