import React, { useEffect, useReducer, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getDate, getDay } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ApplyCompOff, ApplyLeavePost } from '../../services/lmsService';
import {
  LEAVE_DETAILS_FORM_ID,
  MY_COMP_OFFS_FORM_ID,
} from '../../constants/formConstants';
import { pushNotification } from '../../redux/actions';
import CONSTANTS, { HOLIDAYS_FORMID } from '../../constants/constants';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  fetchRuntimeFormData,
  FilterFormDetailsWithEmployeeId,
  FilterFormDetailsWithOfficalEmail,
} from '../../services/formService';
import { getAllUsers } from '../../services/userService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RectangularLoader from '../../Loaders/ContentLoader/Loader';
import { leaveTypeEnums, leaveTypes } from '../uiElementsAccess';
import { getCompOffDetailsById } from '../../services/compOffService';
import { formatDate } from '../../services/commonHelperService';
import LeaveNotificationPopup, {
  LeaveNotificationCallbackActions,
} from './UserOverview/LeaveNotificationPopup';
import { getSessionStorageItem } from '../../encrypt';

const useStyles = makeStyles(() => ({
  screenHeadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#162952',
    height: '50px',
    color: '#FFFFFF',
  },
  lmsSubmitBtn: {
    backgroundColor: '#162952 !important',
    borderRadius: '35px !important',
    width: '137px',
  },
  lmsDatePicker: {
    height: '39px',
    // width: "300px",
  },
  lmsLabel: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  dialogBox1: {
    wordBreak: 'break-word',
    width: '140px',
    marginRight: '30px',
    fontWeight: 'bold',
    color: 'black',
  },
  dialogBox2: {
    wordBreak: 'break-word',
    width: '200px',
    marginLeft: '30px',
  },
  datePickerStyles: {
    height: '36px',
    border: '1px solid grey !important',
    borderRadius: '5px',
    '&::placeholder': {
      textAlign: 'center',
    },
    textAlign: 'center',
    // "@media (min-width: 1500px) and (max-width: 2000px)": {
    //   paddingTop: "8px !important",
    //   paddingBottom: "8px !important",
    // },
  },
  inputStyles: {
    textAlign: 'center',
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem) !important',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem) !important',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem) !important',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem) !important',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
    },
  },
}));

const styles = {
  dialogContext: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 500,
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem)',
    },
  },
  typography: {
    screenHeadingContainer: {
      '@media (min-width: 2000px)': {
        fontSize: 'calc(12px + 1.8rem)',
      },
      '@media (min-width: 1500px) and (max-width: 2000px)': {
        fontSize: 'calc(12px + 1.2rem)',
      },
      '@media (min-width: 1000px) and (max-width: 1500px)': {
        fontSize: 'calc(12px + 0.08rem)',
      },
      '@media (min-width: 600px) and (max-width: 1000px)': {
        fontSize: 'calc(12px + 0.008rem)',
      },
      '@media (max-width: 600px)': {
        fontSize: 'calc(12px + 0.001rem)',
      },
    },
  },
  leaveFormContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '98%',
    overflowY: 'scroll',
  },
  empCardMain: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
      fontWeight: 'bold',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
      fontWeight: 'bold',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
      fontWeight: 'bold',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
      fontWeight: 'bold',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
      fontWeight: 'bold !important',
    },
  },
  empId: {
    width: '80px',
    '@media (min-width: 2000px)': {
      width: '120px !important',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      width: '100px !important',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      width: '80px !important',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      width: '60px !important',
    },
    '@media (max-width: 600px)': {
      width: '60px !important',
    },
  },
  leaveType: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
      fontWeight: 'bold !important',
      color: 'black',
    },
  },
  radioButton: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
    },
    '@media (min-width: 1000px) and (max-width: 1499px)': {
      fontSize: 'calc(12px + 0.04rem)',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem)',
    },
  },
  requestTo: {
    fontSize: '50px',
    width: '190px',
    '@media (min-width: 2000px)': {
      width: '500px !important',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      width: '450px !important',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      width: '380px !important',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      width: '240px !important',
    },
    '@media (max-width: 600px)': {
      width: '220px !important',
    },
  },
  submitButton: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
      width: '250px',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
      width: '220px',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
      width: '200px',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem)',
    },
  },
  icon: {
    color: '#FFFF',
  },
};

const popupMessages = {
  NO_LEAVES: {
    infoText: 'Leave request results in Loss of Pay (LOP).',
    rightButtonText: 'Cancel',
    leftButtonText: 'Proceed with LOP',
  },
  NO_GENERAL_LEAVES_USE_PRIVILEGE: {
    infoText:
      'General leaves exhausted, resulting in Loss of Pay (LOP). Use Privilege Leaves',
    rightButtonText: 'Use Privilege Leaves',
    leftButtonText: 'Proceed with LOP',
  },
  NO_GENERAL_LEAVES_LOP: {
    infoText: 'Leave request results in Loss of Pay (LOP).',
    rightButtonText: 'Save for later',
    leftButtonText: 'Proceed with LOP',
  },
  EXCEEDS_GENERAL_USE_PRIVILEGE: {
    infoText:
      'Requested leave days exceed available general leaves. Use Privilege Leaves for Excess',
    rightButtonText: 'Use Privilege Leaves',
    leftButtonText: 'Proceed with LOP',
  },
  GENERAL_LEAVES_AVAILABLE: {
    infoText:
      'General leaves are available. Reserve Privilege Leaves for Future Use',
    rightButtonText: 'Reserve Privilege Leaves',
    leftButtonText: 'Proceed',
  },
  NO_PRIVILEGE_LEAVES: {
    infoText:
      'General leaves are available. Save Privilege Leaves for Future Needs',
    rightButtonText: 'Save Privilege Leaves',
    leftButtonText: 'Proceed',
  },
};

interface propsSchema {
  show: boolean;
  setShow: (show: boolean) => void;
  leaveChoice: string;
  compOffId: string;
}

const ApplyLeave = (props: propsSchema) => {
  const { setShow, leaveChoice, compOffId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [empId, setEmpId] = useState('');
  const [availableLeaves, setAvailableLeaves] = useState(0);
  const [reportingTo, setReportingTo] = useState('');

  const [usersData, setUsersData] = useState<any>([]);
  // const [informToState, setInformToState] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const minimumDate = new Date();
  minimumDate.setDate(minimumDate.getDate() - 14);

  const [empLmsData, setEmpLmsData] = useState<any>({});
  const [finalData, setFinalData] = useState<any>({});
  const emailId: any = getSessionStorageItem('user-email');

  const [totalLeaveDays, setTotalLeaveDays] = useState(0);

  const [applyDisable, setApplyDisable] = useState(false);
  const [holidays, setHolidays] = useState<any>({});
  const [allHolidays, setAllHolidays] = useState<any>([]);

  const [isDisableToDate, setIsDisableToDate] = useState(false);

  const [compOffData, setCompOffData] = useState<any>();

  const [openWarning, setOpenWarning] = useState(false);
  const [availableGeneralLeaves, setAvailableGeneralLeaves] = useState(0);
  const [availablePrivilegeLeaves, setAvailablePrivilegeLeaves] = useState(0);
  const [allFormValues, setAllFormValues] = useState<any>();
  let totalNoOfLeaveDays = 0;

  const popupDataReducer = (mPopupData, action) => {
    switch (action.type) {
      case 'update':
        return { ...mPopupData, ...action.payload };
      default:
        return mPopupData;
    }
  };
  const [popupData, setPopupData] = useReducer(popupDataReducer, {
    infoText: '',
    rightButtonText: '',
    leftButtonText: '',
  });

  // Code for Select field
  const leaveCategories = [
    {
      title: 'Partial Day',
      value: 'partialDay',
    },
    {
      title: 'Full Day',
      value: 'fullDay',
    },
  ];

  const partialLeaveCategories = [
    {
      title: 'First Half (10AM - 2PM)',
      value: 'firstHalf',
    },
    {
      title: 'Second Half (2PM - 6PM)',
      value: 'secondHalf',
    },
  ];

  const handleClose = () => {
    setOpenDialog(false);
  };

  const theme = useTheme();

  const formOne = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
      leaveType: leaveChoice,
      informTo: [],
      reason: '',
      leaveCategory: 'fullDay',
      partialLeaveType: '',
    },
    validationSchema: Yup.object({
      startDate: Yup.date()
        .typeError('')
        .required('Please select Start Date')
        .nullable()
        .test(
          'startDate',
          'Start Date must be within the past 30 days',
          function (startDate) {
            if (!startDate) return true; // Allow if startDate is not set
            const currentDate = new Date();
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(currentDate.getDate() - 31);

            const isWithinPast31Days =
              startDate >= thirtyDaysAgo && startDate <= currentDate;
            const isFutureDate = startDate > currentDate;

            return isWithinPast31Days || isFutureDate;
          }
        ),
      endDate: Yup.date()
        .typeError('')
        .required('Please select End Date')
        .nullable()
        .test(
          'endDate',
          'End Date cannot be before Start Date',
          function (endDate) {
            const startDate = this.resolve(Yup.ref('startDate'));
            if (!endDate || !startDate) return true; // Allow if endDate or startDate is not set
            return endDate >= startDate;
          }
        ),
      leaveType: Yup.string().required('Please select atleast one Option'),
      informTo: Yup.array(),
      reason: Yup.string().required('Please enter the reason'),
      leaveCategory: Yup.string().required('Please select the Leave Category'),
      partialLeaveType: Yup.string().when('leaveCategory', {
        is: 'partialDay',
        then: Yup.string().required('Please select the time range'),
      }),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(true);
    setAllFormValues(values);
    setTotalNoOfLeaveDays(values);
    if (!isWFHRequest() && isShowLeavePopup(values)) {
      setOpenWarning(true);
    } else {
      postFormValues(values);
    }
  };
  const setTotalNoOfLeaveDays = (values: any) => {
    totalNoOfLeaveDays =
      values?.leaveCategory === 'partialDay'
        ? 0.5
        : getBusinessDaysCount(
            new Date(
              moment(values.startDate.toISOString()).format('YYYY-MM-DD')
            ),
            new Date(moment(values.endDate.toISOString()).format('YYYY-MM-DD')),
            holidays
          );
  };

  const isShowLeavePopup = (values: any) => {
    if (availableGeneralLeaves === 0 && availablePrivilegeLeaves === 0) {
      // popupData.infoText = "You will fall into LOP"
      // popupData.rightButtonText = "Cancel"
      setPopupData({
        type: 'update',
        payload: popupMessages.NO_LEAVES,
      });
      return true; // whatever he chooses, it will fell into lop
    }

    if (availableGeneralLeaves === 0 && availablePrivilegeLeaves !== 0) {
      // he needs to utilze privilege leaves
      if (values?.leaveType === leaveTypeEnums.GENERAL) {
        setPopupData({
          type: 'update',
          payload: popupMessages.NO_GENERAL_LEAVES_USE_PRIVILEGE,
        });
        return true;
      }

      if (values?.leaveType === leaveTypeEnums.PRIVILEGE) {
        // if no of selected leaves are greater than available leaves, he fell into lop
        if (totalNoOfLeaveDays > availablePrivilegeLeaves) {
          // popupData.infoText = "You will fall into LOP"
          // popupData.rightButtonText = "Cancel"
          setPopupData({
            type: 'update',
            payload: popupMessages.NO_GENERAL_LEAVES_LOP,
          });
          return true;
        }
      }
    }

    if (availableGeneralLeaves !== 0 && availablePrivilegeLeaves !== 0) {
      if (values?.leaveType === leaveTypeEnums.GENERAL) {
        // if selected leaved days are greater than the available general leaves, then he can utilize privilege leaves
        if (totalNoOfLeaveDays > availableGeneralLeaves) {
          // popupData.infoText = "Leave Days exeeds available General Leaves. It is better to utilize the remaining with Privilege Leaves"
          // popupData.rightButtonText = "OK"
          setPopupData({
            type: 'update',
            payload: popupMessages.EXCEEDS_GENERAL_USE_PRIVILEGE,
          });
          return true;
        }
      }

      // if he has general leaves, then no need to apply privilege instead of wasting them
      if (values?.leaveType === leaveTypeEnums.PRIVILEGE) {
        // popupData.infoText = "You have general leaves to use. It will be waste of Privilege leaves with this application."
        // popupData.rightButtonText = "OK"
        setPopupData({
          type: 'update',
          payload: popupMessages.GENERAL_LEAVES_AVAILABLE,
        });
        return true;
      }
    }

    if (availableGeneralLeaves !== 0 && availablePrivilegeLeaves === 0) {
      if (values?.leaveType === leaveTypeEnums.GENERAL) {
        // if selected leaves greater than availabe, then it will be an lop
        if (totalNoOfLeaveDays > availableGeneralLeaves) {
          // popupData.infoText = "You will fall into LOP"
          // popupData.rightButtonText = "Cancel"
          setPopupData({
            type: 'update',
            payload: popupMessages.NO_LEAVES,
          });
          return true;
        }
      }
      // since privilege are zero, he can utilize general leaves
      if (values?.leaveType === leaveTypeEnums.PRIVILEGE) {
        // popupData.infoText = "You have general leaves to use. It will be waste of Privilege leaves with this application."
        // popupData.rightButtonText = "OK"
        setPopupData({
          type: 'update',
          payload: popupMessages.NO_PRIVILEGE_LEAVES,
        });
        return true;
      }
    }

    // no need to show popup then.
    return false;
  };

  const warningCallbackAction = (action: string) => {
    if (action === LeaveNotificationCallbackActions.CONTINUE) {
      postFormValues(allFormValues);
    }
  };

  const formPayload = (values: any) => ({
    fromDate: moment(values.startDate.toISOString()).format('YYYY-MM-DD'),
    toDate: moment(values.endDate.toISOString()).format('YYYY-MM-DD'),
    leaveType: values.leaveType,
    comments: values.reason,
    empId: empId,
    informTo: values.informTo,
    requestTo: reportingTo,
    leaveCategory: values.leaveCategory,
    partialLeaveType: values.partialLeaveType,
    lop: getIsLop(values.leaveType),
    lopDays: getLopDays(values.leaveType),
  });

  const postFormValues = (values: any) => {
    let postData: any = formPayload(values);
    const totalDays =
      postData.leaveCategory === 'partialDay'
        ? 0.5
        : getBusinessDaysCount(
            new Date(postData.fromDate),
            new Date(postData.toDate),
            holidays
          );
    setTotalLeaveDays(totalDays);
    postData = {
      ...postData,
      lop: getIsLop(postData.leaveType, totalDays),
      lopDays: getLopDays(postData.leaveType, totalDays),
    };
    setFinalData(postData);
    setOpenDialog(true);
  };

  const getIsLop = (leaveType: any, leaveDays = totalLeaveDays): boolean => {
    return leaveType === leaveTypeEnums.WFH
      ? false
      : availableLeaves < leaveDays;
  };

  const getLopDays = (leaveType: any, leaveDays = totalLeaveDays): number => {
    const lopDays =
      availableLeaves <= leaveDays ? leaveDays - availableLeaves : 0;
    return leaveType === leaveTypeEnums.WFH ? 0 : lopDays;
  };

  const getCompOffRequestBody = () => {
    const requestBody = {
      ...finalData,
      employeeId: empId,
      reason: finalData.comments,
      requestTo: [finalData.requestTo],
      leaveDays: totalLeaveDays,
      timeRange: finalData.partialLeaveType,
    };

    if (leaveChoice === leaveTypeEnums.COMP_OFF) {
      requestBody['compOffRecordId'] = props.compOffId;
    }

    delete requestBody['empId'];
    delete requestBody['partialLeaveType'];
    delete requestBody['comments'];

    return requestBody;
  };

  const handlePostApiRequest = async () => {
    setApplyDisable(true);
    const requestBody = getCompOffRequestBody();
    let response;

    if (leaveChoice === leaveTypeEnums.COMP_OFF) {
      response = await ApplyCompOff(requestBody);
    } else {
      response = await ApplyLeavePost(requestBody);
    }

    if (response.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      setOpenDialog(false);
      setShow(false);
      setApplyDisable(false);
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
      setApplyDisable(false);
    }
  };

  const setLeaves = (
    leaveType: any,
    leaveDetails: any = empLmsData?.leaveDetails,
    compOffDetails: any = compOffData
  ) => {
    // console.log({
    //   compOffDetails,
    // });
    switch (leaveType) {
      case leaveTypeEnums.GENERAL:
        setAvailableLeaves(leaveDetails?.general);
        break;
      case leaveTypeEnums.PRIVILEGE:
        setAvailableLeaves(leaveDetails?.privilege);
        break;
      case leaveTypeEnums.COMP_OFF:
        setAvailableLeaves(compOffDetails?.availableLeaves);
        break;
      default:
        setAvailableLeaves(0);
        break;
    }
  };

  const handleGetLmsData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      LEAVE_DETAILS_FORM_ID,
      emailId
    );

    if (response.success && response?.data?.content?.length > 0) {
      setEmpLmsData(response?.data?.content[0]?.formData);
      setEmpId(response?.data?.content[0]?.formData?.employeeId);
      setReportingTo(response?.data?.content[0]?.formData?.reportingTo);
      const leaveDetails = response?.data?.content[0]?.formData?.leaveDetails;
      setLeaves(leaveChoice, leaveDetails);
      setAvailableGeneralLeaves(leaveDetails?.general);
      setAvailablePrivilegeLeaves(leaveDetails?.privilege);
    } else {
      setEmpLmsData({});
      setEmpId('');
      setReportingTo('');
      setAvailableLeaves(0);
    }
  };

  const handleGetUserData = async () => {
    const response = await getAllUsers();
    if (response?.success && response?.data?.length > 0) {
      setUsersData(response?.data);
    } else {
      setUsersData([]);
    }
  };

  const fetchCompOffDetails = async () => {
    const response: any = await FilterFormDetailsWithEmployeeId(
      MY_COMP_OFFS_FORM_ID,
      empId
    );

    let compOffDetails;
    if (response.success && response?.data?.content?.length > 0) {
      compOffDetails = getCompOffDetailsById(
        compOffId,
        response?.data?.content
      );
      setCompOffData(compOffDetails);
    } else {
      compOffDetails = getCompOffDetailsById(
        compOffId,
        response?.data?.content
      );
      setCompOffData(compOffDetails);
    }
    setLeaves(leaveChoice, empLmsData.leaveDetails, compOffDetails);
  };

  const handleLeaveTypeChange = async (leaveType: string) => {
    await formOne.setFieldValue('leaveType', leaveType);
    setLeaves(leaveType);
  };

  const handleOptionSelect = (event, value) => {
    const selectedValuesArray = value?.map((option) => {
      const mapData = option?.userData;
      return mapData?.emailId;
    });

    formOne.setFieldValue('informTo', selectedValuesArray);
  };

  const handleLeaveCategory = async (event, value) => {
    await formOne.setFieldValue('leaveCategory', value.value);
    await formOne.setFieldValue('startDate', null);
    await formOne.setFieldValue('endDate', null);
    if (value.value === 'partialDay') {
      setIsDisableToDate(true);
    } else {
      setIsDisableToDate(false);
    }
  };

  const handlePartialLeaveCategory = (event, selectedValue) => {
    formOne.setFieldValue('partialLeaveType', selectedValue?.value);
  };

  const getBusinessDaysCount = (startDate, endDate, holidays) => {
    // Convert the start and end dates to milliseconds
    const startMs = startDate.getTime();
    const endMs = endDate.getTime();

    // Calculate the number of milliseconds in a day
    const msInDay = 1000 * 60 * 60 * 24;

    // Initialize a counter for the number of business days
    let businessDays = 0;

    // Loop through each day between the start and end dates
    for (let i = startMs; i <= endMs; i += msInDay) {
      const currentDate = new Date(i);

      // If the current day is not a Saturday or Sunday and is not a holiday, increment the businessDays counter
      if (
        currentDate.getDay() !== 0 &&
        currentDate.getDay() !== 6 &&
        !isHoliday(currentDate, holidays)
      ) {
        businessDays++;
      }
    }

    // Return the number of business days
    return businessDays;
  };

  // Helper function to check if a date is in the list of holidays
  const isHoliday = (date, holidays) => {
    if (!holidays || !Array.isArray(holidays)) {
      return false;
    }

    const dateStr = date.toISOString().split('T')[0]; // Get the date part as a string

    return holidays.includes(dateStr);
  };

  // const disableWeekends = (date: any) => {
  //   return date.getDay() === 0 || date.getDay() === 6; // Sunday (0) and Saturday (6)
  // };

  // const disableWeekendsAndDates = (datesToDisable, currentDate) => {
  //   const disabledDates = datesToDisable?.map((date) => new Date(date));

  //   // Check if the current date is a weekend
  //   const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;

  //   // Check if the current date is in the array of disabled dates
  //   const isDisabledDate = disabledDates?.some((disabledDate) =>
  //     isSameDate(currentDate, disabledDate)
  //   );

  //   return isWeekend || isDisabledDate;
  // };

  // Helper function to compare two dates for equality based on their year, month, and day
  // const isSameDate = (date1, date2) =>
  //   date1.getFullYear() === date2.getFullYear() &&
  //   date1.getMonth() === date2.getMonth() &&
  //   date1.getDate() === date2.getDate();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // const disablePastDays = () => {
  //   const date = new Date();
  //   const today = startOfToday();
  //   const twoWeeksAgo = sub(today, { weeks: 2 });
  //   return isBefore(date, twoWeeksAgo) || isBefore(date, today);
  // };

  const handleGetHolidaysList = async () => {
    setIsLoading(true);
    const currentYear = new Date().getFullYear();

    try {
      const response = await fetchRuntimeFormData(HOLIDAYS_FORMID);

      if (response?.success) {
        const mapData =
          response?.data?.content?.[0]?.formData?.holidayList?.[currentYear];

        if (mapData) {
          const promises = mapData.map(async (item) => {
            const date = item?.Date;
            const holidayName = item?.Purpose;

            return { date: date, holidayName: holidayName };
          });

          const holidayListArray = await Promise.all(promises);
          const holidaysArray = holidayListArray.map((item) => item.date);

          setHolidays(holidaysArray);
          setAllHolidays(holidayListArray);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error('Error fetching holiday data:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartDateChange = async (date) => {
    await formOne.setFieldValue('startDate', date);

    if (formOne.values.leaveCategory === 'partialDay') {
      await formOne.setFieldValue('endDate', date);
    }
  };

  const isWeekday = (date: any) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const renderDayContents = (day, date) => {
    return <span title={''}>{getDate(date)}</span>;
  };

  useEffect(() => {
    handleGetLmsData();
    handleGetUserData();
    handleGetHolidaysList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  useEffect(() => {
    if (empId) {
      fetchCompOffDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empId]);

  const getTitle = () => {
    switch (leaveChoice) {
      case leaveTypeEnums.WFH:
        return 'WFH Request';
      case leaveTypeEnums.COMP_OFF:
        return 'Comp Off';
      default:
        return 'Leave';
    }
  };

  const isWFHOrCompOffRequest = () => {
    return (
      leaveChoice === leaveTypeEnums.WFH ||
      leaveChoice === leaveTypeEnums.COMP_OFF
    );
  };

  const isCompOffRequest = () => {
    return leaveChoice === leaveTypeEnums.COMP_OFF;
  };

  const isWFHRequest = () => {
    return leaveChoice === leaveTypeEnums.WFH;
  };

  return (
    <>
      <div>
        <form onSubmit={formOne.handleSubmit}>
          <Box>
            <Dialog
              open={openDialog}
              onClose={handleClose}
              fullScreen={fullScreen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle align="center" id="alert-dialog-title">
                {getTitle()}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  sx={styles.dialogContext}
                >
                  <Box>
                    <Box display="flex" flexDirection={'row'} gap={2}>
                      <Box
                        className={classes.dialogBox1}
                        justifyContent={'flex-start'}
                      >
                        <p className="applyLeaveHeader">EmpId</p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box
                        className={classes.dialogBox2}
                        justifyContent={'flex-end'}
                      >
                        {empId}
                      </Box>
                    </Box>
                    {!isWFHRequest() ? (
                      <Box display="flex" flexDirection={'row'} gap={2}>
                        <Box
                          className={classes.dialogBox1}
                          justifyContent={'flex-start'}
                        >
                          <p className="applyLeaveHeader">Leave Type</p>
                        </Box>
                        &nbsp;:&nbsp;
                        <Box
                          className={classes.dialogBox2}
                          justifyContent={'flex-end'}
                        >
                          {formOne.values.leaveType}
                        </Box>
                      </Box>
                    ) : null}

                    {!isWFHRequest() ? (
                      <Box display="flex" flexDirection={'row'} gap={2}>
                        <Box
                          className={classes.dialogBox1}
                          justifyContent={'flex-start'}
                        >
                          <p className="applyLeaveHeader">
                            Available{' '}
                            {formOne.values.leaveType ===
                            leaveTypeEnums.COMP_OFF
                              ? 'Comp Offs'
                              : 'Leaves'}
                          </p>
                        </Box>
                        &nbsp;:&nbsp;
                        <Box
                          className={classes.dialogBox2}
                          justifyContent={'flex-end'}
                        >
                          {availableLeaves}
                        </Box>
                      </Box>
                    ) : null}

                    {!isWFHOrCompOffRequest() ? (
                      <Box display="flex" flexDirection={'row'} gap={2}>
                        <Box
                          className={classes.dialogBox1}
                          justifyContent={'flex-start'}
                        >
                          <p className="applyLeaveHeader">Loss Of Pay</p>
                        </Box>
                        &nbsp;:&nbsp;
                        <Box
                          className={classes.dialogBox2}
                          justifyContent={'flex-end'}
                        >
                          {finalData.lopDays}
                        </Box>
                      </Box>
                    ) : null}

                    <Box display="flex" flexDirection={'row'} gap={2}>
                      <Box
                        className={classes.dialogBox1}
                        justifyContent={'flex-start'}
                      >
                        <p className="applyLeaveHeader">From Date</p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box
                        className={classes.dialogBox2}
                        justifyContent={'flex-end'}
                      >
                        {formatDate(finalData.fromDate)}
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection={'row'} gap={2}>
                      <Box
                        className={classes.dialogBox1}
                        justifyContent={'flex-start'}
                      >
                        <p className="applyLeaveHeader">To Date</p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box
                        className={classes.dialogBox2}
                        justifyContent={'flex-end'}
                      >
                        {formatDate(finalData.toDate)}
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection={'row'} gap={2}>
                      <Box
                        className={classes.dialogBox1}
                        justifyContent={'flex-start'}
                      >
                        <p className="applyLeaveHeader">No of Days</p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box
                        className={classes.dialogBox2}
                        justifyContent={'flex-end'}
                      >
                        {totalLeaveDays}
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection={'row'} gap={2}>
                      <Box
                        className={classes.dialogBox1}
                        justifyContent={'flex-start'}
                      >
                        <p className="applyLeaveHeader">Reporting Manager</p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box
                        className={classes.dialogBox2}
                        justifyContent={'flex-end'}
                      >
                        {reportingTo}
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection={'row'} gap={2}>
                      <Box
                        className={classes.dialogBox1}
                        justifyContent={'flex-start'}
                      >
                        <p className="applyLeaveHeader">CC</p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box
                        className={classes.dialogBox2}
                        justifyContent={'flex-start'}
                      >
                        {formOne.values.informTo.join(', ')}
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection={'row'} gap={2}>
                      <Box
                        className={classes.dialogBox1}
                        justifyContent={'flex-start'}
                      >
                        <p className="applyLeaveHeader"> Reason for leave</p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box
                        className={classes.dialogBox2}
                        justifyContent={'flex-end'}
                      >
                        {formOne.values.reason}
                      </Box>
                    </Box>
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handlePostApiRequest}
                  disabled={applyDisable}
                >
                  Apply
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  autoFocus
                  disabled={applyDisable}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>

            {openWarning && (
              <LeaveNotificationPopup
                show={openWarning}
                setShow={setOpenWarning}
                availablePrivilegeLeaves={availablePrivilegeLeaves}
                availableGeneralLeaves={availableGeneralLeaves}
                callbackAction={warningCallbackAction}
                popupData={popupData}
              />
            )}

            <Box className={classes.screenHeadingContainer}>
              <Box display={'flex'} alignSelf={'center'} margin={'0 auto'}>
                <Typography
                  variant="h6"
                  sx={styles.typography.screenHeadingContainer}
                >
                  {`Apply ${getTitle()}`}
                </Typography>
              </Box>
              <Box>
                <Button sx={styles.icon} onClick={() => setShow(false)}>
                  <CloseOutlinedIcon />
                </Button>
              </Box>
            </Box>
            <Box className={'empCardMain'}>
              <Box sx={styles.leaveFormContainer}>
                <Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    mt={5}
                    gap={2.5}
                    width={'70vw'}
                    margin={'5% 0'}
                    sx={styles.empCardMain}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      gap={4}
                      justifyContent={'center'}
                      flexWrap={'wrap'}
                    >
                      <Box display={'flex'} flexDirection={'column'}>
                        <label className="applyLeaveHeader">Emp ID</label>
                        <TextField
                          id="Emp-ID"
                          name="empId"
                          value={empId}
                          InputProps={{
                            inputProps: {
                              className: classes.inputStyles,
                            },
                          }}
                          sx={styles.empId}
                          onKeyDown={(e) => e.preventDefault()}
                          // disabled
                          size="small"
                        />
                      </Box>

                      {!isWFHOrCompOffRequest() ? (
                        <Box display={'flex'} flexDirection={'column'}>
                          <FormControl size="small">
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              sx={styles.leaveType}
                              className={classes.lmsLabel}
                            >
                              Leave Type
                            </FormLabel>

                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="leaveType"
                              id="leave-type"
                              onBlur={formOne.handleBlur}
                              onChange={(event) => {
                                formOne.handleChange(event);
                                handleLeaveTypeChange(event.target.value);
                              }}
                              value={formOne.values.leaveType}
                              sx={{
                                justifyContent: 'center',
                              }}
                            >
                              {leaveTypes.map((leaveTypeItem) => {
                                return (
                                  <FormControlLabel
                                    key={`leaveType_${leaveTypeItem.value}`}
                                    value={leaveTypeItem.value}
                                    control={<Radio />}
                                    label={leaveTypeItem.title}
                                    sx={styles.radioButton}
                                  />
                                );
                              })}
                            </RadioGroup>
                            <FormHelperText sx={{ color: 'red' }}>
                              {formOne.touched.leaveType &&
                                formOne.errors.leaveType}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      ) : null}

                      {!isWFHOrCompOffRequest() ? (
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                        >
                          <label>Available Leaves</label>
                          <TextField
                            id="available-leaves"
                            name="availableLeaves"
                            value={availableLeaves}
                            InputProps={{
                              inputProps: {
                                className: classes.inputStyles,
                              },
                            }}
                            sx={{ width: '80px' }}
                            onKeyDown={(e) => e.preventDefault()}
                            // disabled
                            size="small"
                          />
                        </Box>
                      ) : null}
                    </Box>

                    {!isCompOffRequest() ? (
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        gap={4}
                        justifyContent={'center'}
                        flexWrap={'wrap'}
                      >
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          width={'24%'}
                        >
                          <label className={classes.lmsLabel}>Category</label>

                          <Autocomplete
                            disablePortal
                            id="leaveCategory"
                            options={leaveCategories}
                            getOptionLabel={(option: any) =>
                              option?.title || ''
                            }
                            onChange={handleLeaveCategory}
                            // value={formOne.values.informTo}
                            disableClearable
                            size="small"
                            classes={{ input: classes.inputStyles }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  formOne.touched.leaveCategory &&
                                  Boolean(formOne.errors.leaveCategory)
                                }
                                helperText={
                                  formOne.touched.leaveCategory &&
                                  formOne.errors.leaveCategory
                                }
                              />
                            )}
                          />
                        </Box>

                        {formOne.values.leaveCategory === 'partialDay' ? (
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={'25%'}
                          >
                            <label className={classes.lmsLabel}>
                              Select Time Range
                            </label>

                            <Autocomplete
                              disablePortal
                              id="partialLeaveCategory"
                              options={partialLeaveCategories}
                              getOptionLabel={(option: any) =>
                                option?.title || ''
                              }
                              onChange={handlePartialLeaveCategory}
                              disableClearable
                              // value={formOne.values.informTo}
                              size="small"
                              classes={{ input: classes.inputStyles }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={
                                    formOne.touched.partialLeaveType &&
                                    Boolean(formOne.errors.partialLeaveType)
                                  }
                                  helperText={
                                    formOne.touched.partialLeaveType &&
                                    formOne.errors.partialLeaveType
                                  }
                                />
                              )}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    ) : null}

                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'center'}
                      flexWrap={'wrap'}
                    >
                      {isLoading ? (
                        <RectangularLoader loaderAlignX={80} />
                      ) : (
                        <Box display={'flex'} flexDirection={'column'}>
                          <label className={classes.lmsLabel}>
                            Select Date
                          </label>

                          <Box padding={'0 5px'}>
                            <Box border={'1px solid grey'}>
                              <Box
                                padding={1}
                                display={'flex'}
                                flexDirection={'row'}
                                gap={2}
                                flexWrap={'wrap'}
                                alignItems={'center'}
                                sx={{
                                  '@media (max-width: 800px)': {
                                    // Apply styles for screens up to 960px width
                                    flexDirection: 'column',
                                  },
                                }}
                              >
                                <Box display={'flex'} flexDirection={'column'}>
                                  <DatePicker
                                    id="datePicker-startDate"
                                    className={classes.datePickerStyles}
                                    selected={formOne.values.startDate}
                                    onChange={(date) =>
                                      handleStartDateChange(date)
                                    }
                                    filterDate={isWeekday}
                                    holidays={allHolidays}
                                    placeholderText="Select Start Date"
                                    onKeyDown={(e) => e.preventDefault()}
                                    renderDayContents={renderDayContents}
                                    autoComplete="off"
                                  />
                                </Box>

                                <Box mt={0.5}>-</Box>
                                <Box display={'flex'} flexDirection={'column'}>
                                  <DatePicker
                                    id="datePicker-endDate"
                                    className={classes.datePickerStyles}
                                    required
                                    selected={formOne.values.endDate}
                                    onChange={(date) =>
                                      formOne.setFieldValue('endDate', date)
                                    }
                                    filterDate={isWeekday}
                                    holidays={allHolidays}
                                    disabled={isDisableToDate}
                                    placeholderText="Select End Date"
                                    onKeyDown={(e) => e.preventDefault()}
                                    renderDayContents={renderDayContents}
                                    autoComplete="off"
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              <FormHelperText sx={{ color: 'red' }}>
                                {formOne.errors.startDate ||
                                  formOne.errors.endDate}
                              </FormHelperText>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={3}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexWrap={'wrap'}
                    >
                      <Box display={'flex'} flexDirection={'column'}>
                        <label className={classes.lmsLabel}>
                          Reporting Manager
                        </label>
                        <TextField
                          id="Request-to"
                          name="requestTo"
                          value={reportingTo}
                          InputProps={{
                            inputProps: {
                              className: classes.inputStyles,
                            },
                          }}
                          sx={styles.requestTo}
                          // disabled
                          onKeyDown={(e) => e.preventDefault()}
                          size="small"
                        />
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        width={'60%'}
                      >
                        <label className={classes.lmsLabel}>
                          Inform to (CC)
                        </label>

                        <Autocomplete
                          disablePortal
                          id="informTo"
                          options={usersData}
                          multiple
                          getOptionLabel={(option: any) =>
                            option?.userData?.emailId || ''
                          }
                          onChange={handleOptionSelect}
                          // value={formOne.values.informTo}
                          size="small"
                          // classes={{ input: classes.inputStyles }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                formOne.touched.informTo &&
                                Boolean(formOne.errors.informTo)
                              }
                              helperText={
                                formOne.touched.informTo &&
                                formOne.errors.informTo
                              }
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'center'}
                      flexWrap={'wrap'}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        width={'90%'}
                      >
                        <label
                          style={{
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                        >
                          May I know the Reason?
                        </label>
                        <TextField
                          id="reason-leave"
                          name="reason"
                          helperText={
                            formOne.touched.reason && formOne.errors.reason
                          }
                          error={
                            formOne.touched.reason &&
                            Boolean(formOne.errors.reason)
                          }
                          onBlur={formOne.handleBlur}
                          onChange={formOne.handleChange}
                          value={formOne.values.reason}
                          multiline
                          // sx={{ width: "600px" }}
                        />
                      </Box>
                    </Box>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'center'}
                    >
                      <Box display={'flex'} flexDirection={'column'}>
                        <Button
                          className={classes.lmsSubmitBtn}
                          variant="contained"
                          type="submit"
                          sx={styles.submitButton}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </div>
    </>
  );
};

export default ApplyLeave;
