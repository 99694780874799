import axios from 'axios';
import {
  RUNTIME_FORM_ENDPOINT,
  WORKFLOW_ENDPOINT,
  GENERAL_FORM_ENDPOINT,
  FORM_RUNTIME_BASE_PATH,
  COMMON_BACKEND_ENDPOINT
} from '../constants/endpoints';
import { PAGINATION } from '../constants/formConstants';
import { request, ResponseProps } from './request';
import wretch from 'wretch';
import CONSTANTS from '../constants/constants';
import { getSessionStorageItem } from '../encrypt';


export const postForm = async (
  formDetails
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem('gatewayURL')}${RUNTIME_FORM_ENDPOINT}`,
    formDetails
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const postFormGeneral = async (
  formDetails
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem('gatewayURL')}${GENERAL_FORM_ENDPOINT}`,
    formDetails
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const fetchRuntimeFormDetails = async (
  id: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${FORM_RUNTIME_BASE_PATH}/forms/${id}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const fetchRuntimeFormData = async (
  id: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${RUNTIME_FORM_ENDPOINT}?formId=${id}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const fetchFormDataWithId = async (
  formId: string,
  id: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: any = await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${RUNTIME_FORM_ENDPOINT}?formId=${formId}&filter=formData.officialEmail:${id}`
  );

  if (r.success) {
    const form = r.data;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};
export const fetchFormDataWithIdForPersonal = async (
  formId: string,
  id: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: any = await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${RUNTIME_FORM_ENDPOINT}?formId=${formId}&filter=id:${id}`
  );

  if (r.success) {
    const form = r.data;
    return { success: r.success, data: form, message: r.message };
  }
  return { success: false };
};

export const fetchFormDataWithFilter = async (
  formId: string,
  id: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: any = await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${RUNTIME_FORM_ENDPOINT}?formId=${formId}&filter=formData.officialEmail:${id}`
  );

  if (r.success) {
    const form = r.data;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};
export const postFormDataWithId = async (bodyPayload: {
  formId: string;
  id: string;
  formData: any;
}): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem('gatewayURL')}${RUNTIME_FORM_ENDPOINT}`,
    bodyPayload
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const patchFormData = async (bodyPayload: {
  formId: string;
  id: string;
  formData: any;
}) => {
  return axios.post(
    `${getSessionStorageItem('gatewayURL')}${RUNTIME_FORM_ENDPOINT}`,
    bodyPayload,
    {
      headers: {
        Authorization: `Bearer ${getSessionStorageItem('react-token')}`,
      },
    }
  );
};

export const postFormData = async (bodyPayload: {
  formId: string;
  formData: any;
}) => {
  return axios.post(
    `${getSessionStorageItem('gatewayURL')}${RUNTIME_FORM_ENDPOINT}`,
    bodyPayload
  );
};

export const filterFormDataUsingParentId = async (
  formId: string,
  parentDataId: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: any = await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${RUNTIME_FORM_ENDPOINT}?formId=${formId}&filter=formData.officialEmail:${parentDataId}`
  );

  if (r.success) {
    const form = r.data;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const patchDataToForm = async (bodyPayload: {
  formId: string;
  id: string;
  formData: any;
}) => {
  return axios.patch(
    `${getSessionStorageItem('gatewayURL')}${RUNTIME_FORM_ENDPOINT}`,
    bodyPayload,
    {
      headers: {
        Authorization: `Bearer ${getSessionStorageItem('react-token')}`,
      },
    }
  );
};

export const GenericProcess = async (
  processPayload: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: any = (await request.post(
    `${getSessionStorageItem('gatewayURL')}${WORKFLOW_ENDPOINT}`,
    processPayload
  )) as any;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const FilterFormDetailsWithOfficalEmail = async (
  id: string,
  email: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${RUNTIME_FORM_ENDPOINT}?formId=${id}${PAGINATION}&filter=formData.officialEmail:"${email}"`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const getAllEmployeesInfo = async (
  page: number,
  size: number
): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/relation?page=${page}&size=${size}&relations=1052917923916066816:officialEmail,1062244463782965248:officialEmail&formId=1058380750487334912`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const AddEmployeePost = async (
  payload: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/new-employee`,
    payload
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const deactiveEmployeePost = async (
  payload: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  try {
    const response = await wretch(
      `${getSessionStorageItem(
        'gatewayURL'
      )}${COMMON_BACKEND_ENDPOINT}/makeEmployeeInactive`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
      .post(payload)
      .json();
    if (response.success) {
      const form = response.data as any;
      return {
        success: response.success,
        data: form,
        message: response.message,
      };
    }
    return { success: false, message: response.message };
  } catch (error: any) {
    console.error(error);
  }
  return { success: false, message: 'Some internal error occurred' };
};

export const FilterFormDetailsWithEmployeeId = async (
  id: string,
  empId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  compOffId?: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${RUNTIME_FORM_ENDPOINT}?formId=${id}${PAGINATION}&filter=formData.employeeId:${empId}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const updateGenralForm = async (
  propertyName: string,
  value: any,
  email: string
) => {
  if (value === null || value === undefined) return;
  const url = `${getSessionStorageItem(
    'gatewayURL'
  )}${COMMON_BACKEND_ENDPOINT}/update-gf`;

  try {
    const response = await wretch(url)
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
      .json({
        officialEmail: email,
        propertyName: propertyName,
        value: value,
      })
      .post()
      .res();
    if (response.status !== 200) {
      console.error(
        `Failed to update property, status code: ${response.status}`
      );
    } else {
      console.error('Property updated successfully:', response);
    }
  } catch (error: any) {
    console.error('Error updating property:', error.message);
    // Handle error properly, e.g., show user-friendly message or retry logic
  }
};
