import { Box, Typography } from '@mui/material';

interface UpcomingEventsProps {
  events: any;
}

const styles = {
  containers: {
    containerMain: {
      display: 'flex !important',
      justifyContent: 'start !important',
      height: '100%',
    },
    contentMain: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'stretch !important',
      padding: '6px 24px !important',
      borderRadius: '0 0 14px 14px !important',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      gap: '12px',
      height: '100%',
    },
  },
};

const UpcomingEvents = ({ events }: UpcomingEventsProps) => {
  return (
    <Box sx={styles.containers.containerMain}>
      <Box sx={styles.containers.contentMain}>
        <Typography
          sx={{ fontWeight: 'thin', paddingTop: '20px', fontSize: '1.8rem' }}
        >
          Upcoming Holidays
        </Typography>

        <ul>
          {events.map((event: any, index: any) => (
            <Typography
              key={index}
              sx={{
                fontSize: '1.1rem',
                whiteSpace: 'nowrap',
              }}
            >
              <li>
                {event.date}: {event.name}
              </li>
            </Typography>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default UpcomingEvents;
