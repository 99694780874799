import React, { useEffect, useState } from 'react';
import './holidaysList.css';
import { Box, Typography } from '@mui/material';
import { fetchRuntimeFormData } from '../../services/formService';
import { HOLIDAYS_FORMID } from '../../constants/constants';
import { GridColDef } from '@mui/x-data-grid';
import TsDatagrid from '../../components/TsDatagrid';

interface Props {
  year: string;
}

const HolidaysList = ({ year }: Props) => {
  // const [holidays, setHolidays] = React.useState<any>([]);
  const [tableRows, setTableRows] = React.useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetHolidaysList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const handleGetHolidaysList = async () => {
    setIsLoading(true);
    const response: any = await fetchRuntimeFormData(HOLIDAYS_FORMID);
    if (response?.success) {
      const mapData = response?.data?.content?.[0]?.formData?.holidayList;
      const list = mapData[year] || [];
      // setHolidays(list);

      const gridList = list.map((obj) => {
        return {
          id: obj.Sl_No,
          date: new Date(obj?.Date).toLocaleDateString('en-US', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }),
          day: obj?.Day,
          purpose: obj.Purpose,
        };
      });
      setTableRows(gridList);
      setIsLoading(false);
    }
  };
  const getTableHeader = (title) => {
    return (
      <Typography sx={dataGridStyles.tableHeaderStyle}>{title}</Typography>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Sl.No',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'day',
      headerName: 'Day',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'purpose',
      headerName: 'Occasion',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
  ];

  return (
    <>
      <TsDatagrid
        rows={tableRows}
        columns={columns}
        pageSize={tableRows.length}
        pageSizeArray={[10, 20, 30, 40]}
        sx={dataGridStyles.tableStyle}
        components={{
          NoRowsOverlay: () => (
            <Box sx={dataGridStyles.overlayStyle}>
              {isLoading ? ' Loading Holidays' : 'No holidays in selected year'}
            </Box>
          ),
        }}
      />
    </>
  );
};

export default HolidaysList;
const dataGridStyles = {
  tableStyle: {
    height: '500px',
  },
  overlayStyle: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableHeaderStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
};
