export const empIdMsg1 = 'Please Enter Emp Id';
export const empIdMsg2 = 'Minimum 3 Characters';
export const empTypeMsg = 'Please Enter Employee Type';
export const officialEmailMsg = 'Please Enter Official Email';
export const officialEmailMsg1 = 'Please Enter Valid Email';
export const firstNameMsg = 'Please Enter First Name';
export const firstNameMsg1 = 'Minimum 3 Characters';
export const lastNameMsg = 'Please Enter Last Name';
export const lastNameMsg1 = 'Minimum 3 Characters';
export const genderMsg = 'Please select Gender';
export const phoneNumberMsg = 'Please Enter Phone Number';
export const phoneNumberMsg1 = 'Please Enter Valid Phone Number';
export const dobAsPerCertificateMsg = 'DOB is Required';
export const dobAsPerCertificateMsg1 = 'DOB';
export const dobAsPerCertificateMsg2 = 'Please choose a valid date of birth';
export const reportingToMsg = 'Please Enter Reporting To';
export const reportingToMsg1 = 'Minimum 3 Characters';
export const joiningDateMsg = 'DOJ is Required';
export const joiningDateMsg1 = 'DOJ';
export const joiningDateMsg2 = 'Please choose a valid date of joining';
export const dobAsPerCertificateMsg3 = 'years';
export const bloodGroupMsg = 'Please select blood Group';
export const panNumberMsg = 'Please Enter Pan Number';
export const aadharNumberMsg = 'Please Enter Aadhar Number';
export const dobAsPerCelebrationMsg = 'Please choose a valid date of birth';
export const dobAsPerCelebrationMsg1 = 'DOB';
export const dobAsPerCelebrationMsg2 = 'years';
export const personalEmailMsg = 'Please Enter Personal Email';
export const personalEmailMsg1 = 'Minimum 3 Characters';
export const nationalityMsg = 'Please Enter Nationality';
export const nationalityMsg1 = 'Minimum 3 Characters';
export const physicallyChallengedMsg = 'Please select Option';
export const currentAddressMsg = 'Please Enter Current Address';
export const currentAddressMsg1 = 'Maximum 300 Characters';
export const permanentAddressMsg = 'Please Enter Permanent Address';
export const permanentAddressMsg1 = 'Maximum 300 Characters';
export const contactName1Msg = 'Please Enter Contact Name';
export const contactName1Msg1 = 'Minimum 3 Characters';
export const relationship1Msg = 'Please Enter Relationship';
export const relationship1Msg1 = 'Minimum 3 Characters';
export const emergencyPhone1Msg = 'Phone number is requried';
export const emergencyPhone1Msg1 = 'Please enter a valid phone number';
export const contactName2Msg = 'Please Enter Contact Name';
export const contactName2Msg1 = 'Minimum 3 Characters';
export const relationship2Msg = 'Please Enter Relationship';
export const relationship2Msg1 = 'Minimum 3 Characters';
export const emergencyPhone2Msg = 'Phone number is requried';
export const emergencyPhone2Msg1 = 'Please enter a valid phone number';
export const fatherNameMsg = 'Please Enter  Father Name';
export const fatherNameMsg1 = 'Minimum 3 Characters';
export const motherNameMsg = 'Please Enter Mother Name';
export const motherNameMsg1 = 'Minimum 3 Characters';
export const marriedStatusMsg = 'Please select  Option';
export const childFullNameMsg = 'Minimum 3 Characters';
export const bankNameMsg = 'Please Enter Bank Name';
export const accountNumberMsg = 'Please Enter Account Number';
export const ifscMsg = 'Please Enter IFSC';
export const branchMsg = 'Please Enter Branch';
export const OptedForSodexoMealCouponsMsg =
  'OptedForSodexoMealCoupons is Required';
export const assetNameMsg = 'Please Select Asset Name';
export const brandMsg = 'Please Select Brand';
export const serialNumberMsg = 'Please Enter Serial Number';
export const conditionMsg = 'Please Enter Condition';
export const issueDateMsg = 'Issue Date is required';
export const additionalDetailsMsg = 'Please Enter Additional Details';
export const prevOrganizationMsg = 'Please Enter Prev Organization';
export const roleMsg = 'Please Enter Role';
export const locationMsg = 'Please Enter Location';
export const totalNoOfYearsMonthsMsg = 'Please Enter Total No Of Years Months';
export const skillSetMsg = 'Atleast 1 skill is required';
export const skillSetMsg1 = 'Required 5 skills';
export const totalNoOfMonthsMsg = 'Please Enter Total No Of Months';
export const totalNoOfYearsMsg = 'Please Enter Total No Of Years & Months';
export const designationRequriedMsg = 'Please Enter the Designation';
export const passportValidationMsg = 'Please Enter a Valid Passport number';

export const departmentRequiredMsg = 'Please Enter the Department';
export const probationRequiredMsg = 'Please Enter the Probation';
export const currentDesignationRequiredMsg =
  'Please Enter the Current Designation';
export const newDesignationRequiredMsg = 'Please Enter the New Designation';
export const currentDepartmentRequiredMsg =
  'Please Enter the Current Department';
export const hikePercentageRequiredMsg = 'Please set the Hike Percentage';
export const performanceScoreRequiredMsg = 'Performance Score required';
export const revisionTypeRequiredMsg = 'Please Enter the Revision Type';
export const approvalStatusRequiredMsg = 'Please Enter the Approval Status';
export const approverNameRequiredMsg = 'Please Enter the Approver\'s Name';
export const effectiveDateRequiredMsg = 'Please Enter the Effective Date';
export const effectiveDateValueMsg =
  'Effective date cannot be before today\'s date';
export const revisionDateRequiredMsg = 'Please Enter the Revision Date';
export const revisionDateValueMsg =
  'Effective date cannot be after today\'s date';
export const reasonRequiredMsg =
  'Please Enter the Reason for Promotion/Revision';
export const reasonLengthMsg = 'Reason must be between 10 and 250 characters';
export const commentsLengthMsg = 'Comments must be under 500 characters';

export const lopDaysRequiredMsg = 'Please Enter the LOP Days';
export const statusRequriedMsg = 'Please Select the Status';

export const clientMsg = 'Please select the Client';
export const startDateMsg = 'Please Select the Start Date';
export const BillableMsg = 'Please Select the Billable';
export const previousSalaryRequiredMsg = 'Previous Salary is required';
export const currentSalaryRequiredMsg = 'Current Salary is required';
export const violationDateRequiredMsg = 'Violation Date is required';
export const violationTypeRequiredMsg = 'Violation Type is required';
export const descriptionRequiredMsg = 'Description is required';
export const actionTakenRequiredMsg = 'Action Taken is required';
export const payAmountRequiredMsg = 'Pay Amount is required';
export const quarterRequiredMsg = 'Quarter is required';
export const grievanceRequiredMessage = 'Grievance is Required';
export const preferredResolutionRequiredMessage = 'Resolution is Required';

export const discussionTypeRequired = 'Discussion type required';
export const participantsRequired = 'Participants required';
