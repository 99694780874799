import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Avatar, Button, Tooltip, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import FooterLogo from '../../assets/icon/empTXP.png';
import LogoutIcon from '@mui/icons-material/Logout';
import { useKeycloak } from '@react-keycloak/web';
import { getSessionStorageItem } from '../../encrypt';

const drawerWidth = 100;
interface tabItems {
  title: string;
  index: number;
  icon: React.ReactNode;
  url: string;
}
interface tabStylesItems {
  activeIconColor: string;
  activeIndicatorColor: string;
  scrollButtonColor: string;
  menusBackgroundColor: string;
}

interface logoItems {
  title: string;
  icon: string;
}

interface profileLogoItems {
  title: string;
  icon: string;
}

interface propsSchema {
  tabs: tabItems[];
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
  tabStyles: tabStylesItems;
  logo: logoItems;
  profileLogo: profileLogoItems;
  handleLogoClick: (item: any) => void;
  // handleProfileLogoClick: (item: any) => void;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const styles = {
  mainContainer: {
    display: 'flex',
    overflow: 'hidden',
  },
  poweredByContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FAE9E5',
    width: '100%',
  },
  poweredByText: {
    font: 'Inter',
    fontWeight: 400,
    lineHeight: '12.1px',
    fontSize: '10px',
    padding: 1,
    textTransform: 'uppercase',
  },
  poweredByImage: {
    marginBottom: '8px',
  },
};

export default function Sidebar(props: propsSchema) {
  const {
    tabs,
    activeTab,
    setActiveTab,
    tabStyles,
    logo,
    handleLogoClick,
    // handleProfileLogoClick,
  } = props;

  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const windowLocation = window.location.pathname;

  React.useEffect(() => {
    handleTabIndexChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, windowLocation]);

  const handleTabIndexChange = () => {
    if (tabs.length > 0) {
      const filtered: any = tabs.filter((item) => {
        return item.url === window.location.pathname;
      });
      if (filtered[0]) {
        setActiveTab(filtered[0].index);
        navigate(filtered[0].url);
      } else {
        navigate(`${process.env.PUBLIC_URL}`);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const doLogout = () => {
    navigate(`${process.env.PUBLIC_URL}/${getSessionStorageItem('realm')}`);
    sessionStorage.clear();
    localStorage.clear();
    keycloak.logout();
  };

  return (
    <Box sx={styles.mainContainer}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box alignSelf={'center'}>
          <Button id="logo-button" onClick={(e) => handleLogoClick(e)}>
            <Avatar
              alt="logo"
              src={logo.icon}
              sx={{ width: 60, height: 60, marginTop: 2 }}
            />
          </Button>
        </Box>

        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          orientation="vertical"
          variant="scrollable"
          sx={{
            '&.MuiTabs-root .MuiTabScrollButton-root svg': {
              color: tabStyles.scrollButtonColor || 'blue',
            },

            '@media (min-width: 2000px)': {
              '.css-i4bv87-MuiSvgIcon-root': {
                width: '8rem !important',
                height: '4rem !important',
              },
            },

            '@media (min-width: 1499px) and (max-width: 2000px)': {
              '.css-i4bv87-MuiSvgIcon-root': {
                width: '5rem !important',
                height: '2rem !important',
              },
            },

            '&.MuiTabs-root .Mui-selected': {
              color: tabStyles.activeIconColor || 'blue',
            },
            '.MuiButtonBase-root:hover': {
              color: '#F15830',
            },
            '&.MuiTabs-root .MuiTabs-scroller ': {
              backgroundColor: tabStyles.menusBackgroundColor || 'white',
            },
            '&.MuiTabs-root .MuiTabs-indicator': {
              backgroundColor: tabStyles.activeIndicatorColor || 'blue',
              width: '7%',
              borderRadius: '10px 0px 0px 10px',
            },
            height: '70vh',
            marginTop: '1.2vh',
          }}
        >
          {tabs.map((tab: any, index) => (
            <Tooltip
              key={tab.id || index}
              title={tab.title}
              placement="right"
              arrow
            >
              <Tab
                icon={tab.icon}
                sx={{
                  padding: '25px',
                  color: '#3464C1',
                  fontSize: '1.6rem !important',
                }}
                {...a11yProps(tab.index)}
                component={Link}
                to={tab.url}
              />
            </Tooltip>
          ))}
        </Tabs>

        <Box
          sx={{
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
          }}
        >
          <Box alignSelf={'center'}>
            <Tooltip title="Logout">
              <IconButton
                onClick={doLogout}
                sx={{ width: 48, height: 48, marginTop: 5, marginBottom: 2 }}
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={styles.poweredByContainer}>
            <Box sx={styles.poweredByText}>Powered by</Box>
            <img
              style={styles.poweredByImage}
              width="65.6px"
              src={FooterLogo}
              alt={'footer-brand logo'}
            />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
