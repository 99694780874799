import {
  COMMON_BACKEND_ENDPOINT,
  RUNTIME_FORM_ENDPOINT,
} from '../constants/endpoints';
import { getSessionStorageItem } from '../encrypt';
import { request, ResponseProps } from './request';

export const FilterFormDetailsWithEmail = async (
  id: string,
  email: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${RUNTIME_FORM_ENDPOINT}?formId=${id}&filter=formData.officialEmail:"${email}"`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const ApplyLeavePost = async (
  formData: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/apply-leave`,
    formData
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const FilterFormDetailsWithReportingTo = async (
  id: string,
  email: string,
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  filterUrl: string = ''
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${RUNTIME_FORM_ENDPOINT}?formId=${id}&filter=formData.reportingTo:"${email}",${filterUrl}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const CancelAppliedLeave = async (
  leaveApplicationId: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/cancel-leave?leaveApplicationId=${leaveApplicationId}`,
    null
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const ApproveRejectLeave = async (
  leaveApplicationId: string,
  decision: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/decision?leaveApplicationId=${leaveApplicationId}&decision=${decision}`,
    null
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const searchEmployeesList = async (
  searchQuery: string,
  status: string,
  page: number,
  size: number
) => {
  const r = await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/search?query=${searchQuery}&status=${status}&page=${page}&size=${size}`
  );

  if (r) {
    return { success: true, data: r, message: 'Data Fetched Successfully' };
  }

  return { success: false, message: 'Failed to Fetch Data' };
};

export const ApplyCompOff = async (
  formBody
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/apply-comp-off`,
    formBody
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const AddLeaves = async (
  formBody
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/add-leaves`,
    formBody
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const AddCompOffs = async (
  formBody
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/add-comp-off`,
    formBody
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const ApplyCompOffRequest = async (
  formBody
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/request/add-comp-off`,
    formBody
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const ChangeCompOffStatus = async (
  compOffId: string,
  decision: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/comp-off/decision?compOffCreditApplicationId=${compOffId}&decision=${decision}`,
    null
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const ExpirationCheck = async (
  empId: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/check-comp-off-expiry?employeeId=${empId}`,
    null
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const UpdateCompOffLeaveStatus = async (
  leaveId: string,
  decision: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/apply-comp-off/decision?leaveApplicationId=${leaveId}&decision=${decision}`,
    null
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const getLeaveDetails = async (userEmail: string) => {
  const r = await request.get(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}/cards?userEmail=${userEmail}`
  );

  if (r) {
    return { success: true, data: r, message: 'Data Fetched Successfully' };
  }

  return { success: false, message: 'Failed to Fetch Data' };
};
