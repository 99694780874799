import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes as appRoutes } from '../../routes';

const Navigator = () => {
  return (
    <Routes>
      {appRoutes.map((route) => (
        <Route
          key={route.key}
          path={route.path}
          element={<route.component />}
        />
      ))}
    </Routes>
  );
};

export default Navigator;
