import { request, ResponseProps } from './request';
import { ALL_MENUS, MENU_ROLE } from '../constants/endpoints';
import { getSessionStorageItem } from '../encrypt';

export const getMenusAndRoles = async () => {
  const res: ResponseProps = (await request.get(
    `${getSessionStorageItem('gatewayURL')}${MENU_ROLE}`
  )) as ResponseProps;
  if (res?.success) {
    const data = res?.data;
    return data;
  }
  return {};
};

export const getMenuById = async (
  menuId: string
): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${getSessionStorageItem('gatewayURL')}${ALL_MENUS}/${menuId}`
  )) as ResponseProps;

  if (r?.success) {
    const form = r?.data as any;
    return { success: r?.success, data: form, message: r?.message };
  }

  return { success: false, message: r?.message };
};
