import './UserOverview.css';
import { Button, Grid, Tooltip, Zoom } from '@mui/material';
import LeaveDetails from './LeaveDetails';
import LeaveChart from './LeaveChart';
import { leaveTypeEnums } from '../../uiElementsAccess';
import { useEffect, useState } from 'react';
import { getLeaveDetails } from '../../../services/lmsService';
import { getSessionStorageItem } from '../../../encrypt';
import { openFile } from '../../../services/DocumentService';
import { setLoader } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
interface UserOverviewProps {
  handleApplyLeave?: any;
  handleApplyCompOffRequest?: any;
  updated: any;
  isQuickView?: any;
  userEmail: any;
}

const UserOverview = ({
  handleApplyLeave,
  handleApplyCompOffRequest,
  updated,
  isQuickView = false,
  userEmail,
}: UserOverviewProps) => {
  const dispatch = useDispatch();
  const leavePolicyPdfID = getSessionStorageItem('leavePolicyPdfID');
  const [isLeavesLoading, setIsLeavesLoading] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState({
    general: {
      accruedLeaveDetails: 0,
      consumedLeaveDetails: 0,
      leaveDetails: 0,
    },
    privilege: {
      accruedLeaveDetails: 0,
      consumedLeaveDetails: 0,
      leaveDetails: 0,
    },
    WFH: {
      consumedLeaveDetails: 0,
      accruedLeaveDetails: 0,
      leaveDetails: 0,
    },
    compOff: {
      consumedLeaveDetails: 0,
      accruedLeaveDetails: 0,
      leaveDetails: 0,
      expired: 0,
    },
  });

  const fetchLeaveDetails = async () => {
    setIsLeavesLoading(true);
    const response: any = await getLeaveDetails(userEmail);
    const responseData: any = response.data;
    if (responseData.success && responseData?.data) {
      setLeaveDetails(responseData.data);
      setIsLeavesLoading(false);
    } else {
      setIsLeavesLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaveDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, userEmail]);

  const getChartData = () => {
    return [
      ['Leave Type', 'Consumed Leaves'],
      ['General', leaveDetails.general.consumedLeaveDetails],
      ['Privilege', leaveDetails.privilege.consumedLeaveDetails],
      ['Comp Off', leaveDetails.compOff.consumedLeaveDetails],
      ['Work From Home', leaveDetails.WFH.consumedLeaveDetails],
    ];
  };

  const chartData = getChartData();

  const totalAvailableLeaves =
    leaveDetails.general.consumedLeaveDetails +
    leaveDetails.privilege.consumedLeaveDetails +
    leaveDetails.compOff.consumedLeaveDetails +
    leaveDetails.WFH.consumedLeaveDetails;

  const holidayAndLeaveDocumentation = async () => {
    dispatch(setLoader(true));

    if (leavePolicyPdfID) {
      const response = await openFile(leavePolicyPdfID);
      dispatch(setLoader(false));
      window.open(response, '_blank');
    } else {
      dispatch(setLoader(false));
      console.error('Leave Policy URL not found.');
    }
  };

  return (
    <div className={'user-overview-main-container'}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        {!isQuickView && totalAvailableLeaves ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={3}
            className={'chart-grid-main-container'}
          >
            <div className={'chart-container'}>
              <LeaveChart chartData={chartData} />
            </div>
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          sm={12}
          md={totalAvailableLeaves ? 6 : 12}
          lg={totalAvailableLeaves ? 9 : 12}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={totalAvailableLeaves ? 12 : 6}
              lg={6}
            >
              <LeaveDetails
                isLoading={isLeavesLoading}
                color={'#EDC683'}
                leaveData={leaveDetails.general}
                type={leaveTypeEnums.GENERAL}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={totalAvailableLeaves ? 12 : 6}
              lg={6}
            >
              <LeaveDetails
                isLoading={isLeavesLoading}
                color={'#81DDC6'}
                leaveData={leaveDetails.privilege}
                type={leaveTypeEnums.PRIVILEGE}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={totalAvailableLeaves ? 12 : 6}
              lg={6}
            >
              <LeaveDetails
                isLoading={isLeavesLoading}
                color={'#F35E58'}
                leaveData={leaveDetails.compOff}
                type={leaveTypeEnums.COMP_OFF}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={totalAvailableLeaves ? 12 : 6}
              lg={6}
            >
              <LeaveDetails
                isLoading={isLeavesLoading}
                color={'#50504E'}
                leaveData={leaveDetails.WFH}
                type={leaveTypeEnums.WFH}
              />
            </Grid>
          </Grid>
          {!isQuickView && (
            <div className="actions-container">
              <div className="buttons-container">
                <Button
                  variant="contained"
                  className="action-button"
                  onClick={() => handleApplyLeave(leaveTypeEnums.GENERAL)}
                >
                  Apply Leave
                </Button>
                <Button
                  variant="contained"
                  className="action-button"
                  onClick={() => handleApplyLeave(leaveTypeEnums.WFH)}
                >
                  WFH Request
                </Button>
                <Button
                  variant="contained"
                  className="action-button"
                  onClick={handleApplyCompOffRequest}
                >
                  Raise a Comp Off Request
                </Button>
              </div>
              <div className="leave-outer-box">
                <div className="leave-documentation">
                  <Tooltip
                    title={'EMP360 Leave and Holiday Policy'}
                    TransitionComponent={Zoom}
                    placement="bottom"
                    arrow
                  >
                    <span
                      className="help-documentation-text"
                      onClick={holidayAndLeaveDocumentation}
                    >
                      Leave Policy
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default UserOverview;
