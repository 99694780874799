import { Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

interface iConfirmationModalProps {
  id?: any;
  title: string;
  message: string;
  open: boolean;
  buttonName?: string;
  onClose: any;
  onSubmit: any;
  isSubmitButtonDisabled: boolean;
}

const ConfirmationModal = ({
  // id,
  title,
  message,
  open,
  buttonName,
  onClose,
  onSubmit,
  isSubmitButtonDisabled,
}: iConfirmationModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} id="confirmationDialog">
      <DialogTitle>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmationText">{message}</DialogContentText>
      </DialogContent>
      <DialogActions id="confirmationActions">
        <Button variant="outlined" onClick={onClose} id="noButton">
          No
        </Button>
        <Button
          variant="outlined"
          onClick={onSubmit}
          id="submitButton"
          disabled={isSubmitButtonDisabled}
        >
          {buttonName ? buttonName : 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
