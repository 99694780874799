import wretch from 'wretch';
import CONSTANTS from '../constants/constants';
import {
  APPROVE_REJECT_VISITORS_END_POINT,
  CHECK_IN_VISITORS_END_POINT,
  CHECK_OUT_VISITORS_END_POINT,
  COMMON_BACKEND_ENDPOINT,
  CREATE_VISITOR_END_POINT,
  DOWNLOAD_ALL_VISITORS_END_POINT,
  FILTER_VISITORS_END_POINT,
} from '../constants/endpoints';
import { getSessionStorageItem } from '../encrypt';

// Function to create a new visitor
export const createOrUpdate = async (visitor: any) => {
  try {
    const response = await wretch(
      `${`${getSessionStorageItem(
        'gatewayURL'
      )}`}${COMMON_BACKEND_ENDPOINT}${CREATE_VISITOR_END_POINT}`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
      .post(visitor)
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message
        ? JSON.parse(error?.message).message
        : 'Some error occurred'
    );
  }
};

// Function to get visitors with filters
export const getVisitors = async (
  searchText: string,
  date: string,
  status: string,
  page: number,
  pageSize: number
) => {
  try {
    const params = {
      searchText,
      date,
      status,
      page: page.toString(),
      pageSize: pageSize.toString(),
    };
    const response = await wretch(
      `${`${getSessionStorageItem(
        'gatewayURL'
      )}`}${COMMON_BACKEND_ENDPOINT}${FILTER_VISITORS_END_POINT}`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
      .query(params)
      .get()
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message
        ? JSON.parse(error?.message).message
        : 'Some error occurred'
    );
  }
};

// Function to check in a visitor
export const checkinVisitor = async (id: number, datetime: string) => {
  try {
    const response = await wretch(
      `${`${getSessionStorageItem(
        'gatewayURL'
      )}`}${COMMON_BACKEND_ENDPOINT}${CHECK_IN_VISITORS_END_POINT}`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
      .post({ id, datetime })
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message
        ? JSON.parse(error?.message).message
        : 'Some error occurred'
    );
  }
};

// Function to check out a visitor
export const checkoutVisitor = async (id: number, datetime: string) => {
  try {
    const response = await wretch(
      `${`${getSessionStorageItem(
        'gatewayURL'
      )}`}${COMMON_BACKEND_ENDPOINT}${CHECK_OUT_VISITORS_END_POINT}`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
      .post({ id, datetime })
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message
        ? JSON.parse(error?.message).message
        : 'Some error occurred'
    );
  }
};
export const approveOrRejectVisitor = async (id: number, status: string) => {
  try {
    const response = await wretch(
      `${`${getSessionStorageItem(
        'gatewayURL'
      )}`}${COMMON_BACKEND_ENDPOINT}${APPROVE_REJECT_VISITORS_END_POINT}`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
      .post({ id, status })
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message
        ? JSON.parse(error?.message).message
        : 'Some error occurred'
    );
  }
};

// Function to download visitors as an Excel file
export const downloadVisitorsExcel = async () => {
  try {
    await wretch(
      `${`${getSessionStorageItem(
        'gatewayURL'
      )}`}${COMMON_BACKEND_ENDPOINT}${DOWNLOAD_ALL_VISITORS_END_POINT}`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
      .get()
      .blob((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'visitors.xlsx'); // You can set the file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error: any) {
    throw new Error(
      error?.message
        ? JSON.parse(error?.message).message
        : 'Some error occurred'
    );
  }
};
