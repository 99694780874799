import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Assets from './forms/Assets';
import { makeStyles } from '@mui/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button } from '@mui/material';
import FormExitPopup from '../../FormExitPopup';

const useStyles = makeStyles(() => ({
  empTabs: {
    backgroundColor: '#f8e7e3',
    height: '100vh',
    '&.MuiTabs-root .MuiTabs-indicator': {
      backgroundColor: 'red',
    },
  },
  empTab: {
    '&.Mui-selected': {
      color: 'black !important',
    },
  },
}));

const styles = {
  boxes: {
    main: {
      flexGrow: 1,
      bgcolor: 'background.paper',
      display: 'flex',
      height: 'auto',
    },
  },
  buttons: {
    closeButton: { color: 'red', position: 'absolute', marginLeft: '93%' },
  },
  icons: {
    closeIcon: { margin: '2px' },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width={900} sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function FormsDetails(props: any) {
  const classes = useStyles();
  const {
    closeModal,
    setOpenFormExitPopup,
    isOpenFormExitPopup,
    setIsOpenExitPopup,
    formExitCallBackAction,
  } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleValue = (newValue, buttonName) => {
  //   if (buttonName == "next") {
  //     setValue(newValue + 1);
  //   }
  //   if (buttonName == "back") {
  //     setValue(newValue - 1);
  //   }
  // };

  return (
    <Box sx={styles.boxes.main}>
      {isOpenFormExitPopup && (
        <FormExitPopup
          callbackAction={formExitCallBackAction}
          isShowFormExitPopup={isOpenFormExitPopup}
          setIsShowFormExitPopup={setIsOpenExitPopup}
        />
      )}
      <Button sx={styles.buttons.closeButton} onClick={() => closeModal(false)}>
        <CloseOutlinedIcon sx={styles.icons.closeIcon} />
      </Button>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        scrollButtons={false}
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.empTabs}
      >
        <Tab
          className={classes.empTab}
          label="INFRA"
          {...a11yProps(0)}
          icon={
            value === 0 ? (
              <svg
                width="30"
                height="30"
                viewBox="0 0 56 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M46.9395 1H3.64927C2.18842 1 1 2.18842 1 3.64927V32.5095C1 33.9703 2.18842 35.1587 3.64927 35.1587H18.9253L18.2348 40.6828H17.1774C16.7105 40.6828 16.3319 41.0613 16.3319 41.5283C16.3319 41.9952 16.7104 42.3739 17.1774 42.3739H21.6869C22.1538 42.3739 22.5324 41.9953 22.5324 41.5283C22.5324 41.0614 22.1539 40.6828 21.6869 40.6828H19.939L20.6296 35.1587H21.6869C22.1538 35.1587 22.5324 34.7802 22.5324 34.3132C22.5324 33.8463 22.1539 33.4676 21.6869 33.4676H3.64927C3.12087 33.4676 2.69098 33.0378 2.69098 32.5094V29.7473H21.6869C22.1538 29.7473 22.5324 29.3688 22.5324 28.9018C22.5324 28.4348 22.1539 28.0563 21.6869 28.0563H2.69098V3.64927C2.69098 3.12087 3.12087 2.69098 3.64927 2.69098H46.9395C47.4679 2.69098 47.8978 3.12087 47.8978 3.64927V21.6869C47.8978 22.1538 48.2763 22.5324 48.7433 22.5324C49.2102 22.5324 49.5889 22.1539 49.5889 21.6869V3.64927C49.5888 2.18842 48.4004 1 46.9395 1Z"
                  fill="#F15830"
                  stroke="#F15830"
                  strokeWidth="0.5"
                />
                <path
                  d="M39.7243 17.2339H27.098C25.6372 17.2339 24.4487 18.4223 24.4487 19.8831V39.7245C24.4487 41.1853 25.6372 42.3737 27.098 42.3737H39.7243C41.1852 42.3737 42.3736 41.1853 42.3736 39.7245V19.8831C42.3736 18.4223 41.1852 17.2339 39.7243 17.2339ZM40.6826 39.7245C40.6826 40.2529 40.2527 40.6828 39.7243 40.6828H27.098C26.5696 40.6828 26.1397 40.2529 26.1397 39.7245V38.7662H40.6825V39.7245H40.6826ZM40.6826 37.0752H26.1397V22.5324H40.6825V37.0752H40.6826ZM40.6826 20.8413H26.1397V19.883C26.1397 19.3546 26.5696 18.9247 27.098 18.9247H39.7243C40.2527 18.9247 40.6826 19.3546 40.6826 19.883V20.8413V20.8413Z"
                  fill="#F15830"
                  stroke="#F15830"
                  strokeWidth="0.5"
                />
                <path
                  d="M52.3506 24.4489H46.9393C45.4785 24.4489 44.29 25.6373 44.29 27.0982V39.7245C44.29 41.1853 45.4785 42.3737 46.9393 42.3737H52.3506C53.8115 42.3737 54.9999 41.1853 54.9999 39.7245V27.0982C54.9999 25.6373 53.8115 24.4489 52.3506 24.4489ZM53.3089 39.7245C53.3089 40.2529 52.879 40.6828 52.3506 40.6828H46.9393C46.4109 40.6828 45.981 40.2529 45.981 39.7245V38.7662H53.3088V39.7245H53.3089ZM53.3089 37.0752H45.9811V29.7474H53.3089V37.0752ZM53.3089 28.0564H45.9811V27.0982C45.9811 26.5698 46.411 26.1399 46.9394 26.1399H52.3507C52.8791 26.1399 53.309 26.5698 53.309 27.0982V28.0564H53.3089Z"
                  fill="#F15830"
                  stroke="#F15830"
                  strokeWidth="0.5"
                />
              </svg>
            ) : (
              <svg
                width="30"
                height="30"
                viewBox="0 0 56 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M46.9395 1H3.64927C2.18842 1 1 2.18842 1 3.64927V32.5095C1 33.9703 2.18842 35.1587 3.64927 35.1587H18.9253L18.2348 40.6828H17.1774C16.7105 40.6828 16.3319 41.0613 16.3319 41.5283C16.3319 41.9952 16.7104 42.3739 17.1774 42.3739H21.6869C22.1538 42.3739 22.5324 41.9953 22.5324 41.5283C22.5324 41.0614 22.1539 40.6828 21.6869 40.6828H19.939L20.6296 35.1587H21.6869C22.1538 35.1587 22.5324 34.7802 22.5324 34.3132C22.5324 33.8463 22.1539 33.4676 21.6869 33.4676H3.64927C3.12087 33.4676 2.69098 33.0378 2.69098 32.5094V29.7473H21.6869C22.1538 29.7473 22.5324 29.3688 22.5324 28.9018C22.5324 28.4348 22.1539 28.0563 21.6869 28.0563H2.69098V3.64927C2.69098 3.12087 3.12087 2.69098 3.64927 2.69098H46.9395C47.4679 2.69098 47.8978 3.12087 47.8978 3.64927V21.6869C47.8978 22.1538 48.2763 22.5324 48.7433 22.5324C49.2102 22.5324 49.5889 22.1539 49.5889 21.6869V3.64927C49.5888 2.18842 48.4004 1 46.9395 1Z"
                  fill="#CDA59C"
                  stroke="#CDA59C"
                  strokeWidth="0.5"
                />
                <path
                  d="M39.7243 17.2339H27.098C25.6372 17.2339 24.4487 18.4223 24.4487 19.8831V39.7245C24.4487 41.1853 25.6372 42.3737 27.098 42.3737H39.7243C41.1852 42.3737 42.3736 41.1853 42.3736 39.7245V19.8831C42.3736 18.4223 41.1852 17.2339 39.7243 17.2339ZM40.6826 39.7245C40.6826 40.2529 40.2527 40.6828 39.7243 40.6828H27.098C26.5696 40.6828 26.1397 40.2529 26.1397 39.7245V38.7662H40.6825V39.7245H40.6826ZM40.6826 37.0752H26.1397V22.5324H40.6825V37.0752H40.6826ZM40.6826 20.8413H26.1397V19.883C26.1397 19.3546 26.5696 18.9247 27.098 18.9247H39.7243C40.2527 18.9247 40.6826 19.3546 40.6826 19.883V20.8413V20.8413Z"
                  fill="#CDA59C"
                  stroke="#CDA59C"
                  strokeWidth="0.5"
                />
                <path
                  d="M52.3506 24.4489H46.9393C45.4785 24.4489 44.29 25.6373 44.29 27.0982V39.7245C44.29 41.1853 45.4785 42.3737 46.9393 42.3737H52.3506C53.8115 42.3737 54.9999 41.1853 54.9999 39.7245V27.0982C54.9999 25.6373 53.8115 24.4489 52.3506 24.4489ZM53.3089 39.7245C53.3089 40.2529 52.879 40.6828 52.3506 40.6828H46.9393C46.4109 40.6828 45.981 40.2529 45.981 39.7245V38.7662H53.3088V39.7245H53.3089ZM53.3089 37.0752H45.9811V29.7474H53.3089V37.0752ZM53.3089 28.0564H45.9811V27.0982C45.9811 26.5698 46.411 26.1399 46.9394 26.1399H52.3507C52.8791 26.1399 53.309 26.5698 53.309 27.0982V28.0564H53.3089Z"
                  fill="#CDA59C"
                  stroke="#CDA59C"
                  strokeWidth="0.5"
                />
              </svg>
            )
          }
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Assets
          setValue={setValue}
          setOpenFormExitPopup={setOpenFormExitPopup}
        />
      </TabPanel>
    </Box>
  );
}
