import { FC } from 'react';
import LeaveManagementSystem from './screen/LeaveManagementSystem';
import EmployeesList from './screen/EmployeeList';
import Home from './screen/Home';
import ErrorPage from './components/ErrorPage';
import Policies from './screen/Policies';
import Admin from './screen/admin';
import EmpDashboard from './screen/EmpDashboard/EmpDashboard';
import CardSwipeSystem from './screen/CardSwipeSystem/CardSwipeSystem';
import Reception from './screen/visitorsPass/Reception';

interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<unknown>;
}

export const routes: Array<Route> = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    path: `${process.env.PUBLIC_URL}`,
    enabled: true,
    component: Home,
  },
  {
    key: 'employeeListing',
    title: 'Employee Listing',
    path: `${process.env.PUBLIC_URL}/employes-list`,
    enabled: true,
    component: EmployeesList,
  },
  {
    key: 'lms',
    title: 'LMS',
    path: `${process.env.PUBLIC_URL}/lms`,
    enabled: true,
    component: LeaveManagementSystem,
  },
  {
    key: 'empDashboard',
    title: 'Emp Dashboard',
    path: `${process.env.PUBLIC_URL}/empDashboard`,
    enabled: true,
    component: EmpDashboard,
  },
  {
    key: 'policies',
    title: 'Policies',
    path: `${process.env.PUBLIC_URL}/policies`,
    enabled: true,
    component: Policies,
  },
  {
    key: 'admin',
    title: 'Admin',
    path: `${process.env.PUBLIC_URL}/admin`,
    enabled: true,
    component: Admin,
  },
  {
    key: '404',
    title: '404',
    path: '/404',
    enabled: true,
    component: ErrorPage,
  },
  {
    key: 'visitors',
    title: 'Visitors',
    path: `${process.env.PUBLIC_URL}/visitors`,
    enabled: true,
    component: Reception,
  },
  {
    key: 'cardSwipe',
    title: 'Card Swipe',
    path: `${process.env.PUBLIC_URL}/cardSwipe`,
    enabled: true,
    component: CardSwipeSystem,
  },
];
