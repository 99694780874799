import React, { useEffect, useState } from 'react';
import './teamLeaves.css';
import { Box, Button, Typography } from '@mui/material';
import {
  ApproveRejectLeave,
  FilterFormDetailsWithReportingTo,
  UpdateCompOffLeaveStatus,
} from '../../services/lmsService';
import { MYLEAVE_DETAILS_FORM_ID } from '../../constants/formConstants';
import { pushNotification } from '../../redux/actions';
import CONSTANTS from '../../constants/constants';
import { useDispatch } from 'react-redux';
import {
  capitalize,
  formatDate,
  getFullName,
} from '../../services/commonHelperService';
import ConfirmationModal from '../../components/common/ConfirmationModal/ConfirmationModal';
import { leaveTypeEnums } from '../uiElementsAccess';
import { GridColDef } from '@mui/x-data-grid';
import TsDatagrid from '../../components/TsDatagrid';
import dayjs from 'dayjs';
import { getSessionStorageItem } from '../../encrypt';

const TeamLeaves = ({ isWFH = false }) => {
  const dispatch = useDispatch();
  const emailId: any = getSessionStorageItem('user-email');
  const [teamLeavesData, setTeamLeavesData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState('');
  const [selectedDecision, setSelectedDecision] = useState('');

  const [isAcceptReject, setIsAcceptReject] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const openConfirmationDialog = (item, decision) => {
    setSelectedLeaveRequest(item);
    setSelectedDecision(decision);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationDialog = () => {
    setSelectedLeaveRequest('');
    setSelectedDecision('');
    setIsConfirmationModalOpen(false);
  };

  useEffect(() => {
    handleGetLeavesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetLeavesData = async () => {
    setIsLoading(true);
    const response = await FilterFormDetailsWithReportingTo(
      MYLEAVE_DETAILS_FORM_ID,
      emailId
    );
    if (response?.success && response?.data?.length > 0) {
      const teamData = response?.data?.map((item) => {
        const mapData = item.formData;
        return {
          id: item?.id,
          firstName: mapData?.firstName,
          lastName: mapData?.lastName,
          empId: mapData?.employeeId,
          officialEmail: mapData?.officialEmail,
          reportingTo: mapData?.reportingTo,
          fromDate: mapData?.fromDate,
          toDate: mapData?.toDate,
          totalDays: mapData?.totalDays,
          status: mapData?.status,
          comments: mapData?.reason,
          lop: mapData?.lopDays,
          leaveType: mapData?.leaveType,
          fullName: `${getFullName([
            mapData?.firstName,
            mapData?.lastName,
          ])} - (${mapData?.employeeId})`,
          appliedOn: item?.createdOn,
          approvedOn:
            Boolean(item?.formData?.approvedOn) &&
            item?.formData?.approvedOn !== ''
              ? item?.formData?.approvedOn
              : '-',
        };
      });

      setTeamLeavesData(teamData);
      setIsLoading(false);
    } else {
      setTeamLeavesData([]);
      setIsLoading(false);
    }
  };

  const handleAcceptReject = async (leaveRequest, decision) => {
    setIsAcceptReject(true);
    let response;
    try {
      if (leaveRequest.leaveType === leaveTypeEnums.COMP_OFF) {
        response = await UpdateCompOffLeaveStatus(leaveRequest.id, decision);
      } else {
        response = await ApproveRejectLeave(leaveRequest.id, decision);
      }
      if (response?.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setIsConfirmationModalOpen(false);
        setIsAcceptReject(false);
        await handleGetLeavesData();
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS.ERROR,
          })
        );
        setIsConfirmationModalOpen(false);
        setIsAcceptReject(false);
      }
    } catch (error) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: `Encountered an Error: ${error}`,
          type: CONSTANTS.ERROR,
        })
      );
      setIsConfirmationModalOpen(false);
      setIsAcceptReject(false);
    }
  };

  // const getConfirmationTitleForTeamLeaves = () => {
  //   return `${selectedDecision.toUpperCase()} Leave Request`;
  // };

  const getConfirmationMessageForTeamLeaves = () => {
    return `Do you want to ${selectedDecision} this Leave Request?`;
  };
  // const getConfirmationTitleForTeamWFHRequests = () => {
  //   return `${selectedDecision.toUpperCase()} WFH Request`;
  // };

  const getConfirmationMessageForTeamWFHRequests = () => {
    return `Do you want to ${selectedDecision} this WFH Request?`;
  };

  const filteredLeaveList = teamLeavesData.filter((leaveItem) => {
    if (isWFH) {
      return leaveItem.leaveType === leaveTypeEnums.WFH;
    } else {
      return leaveItem.leaveType !== leaveTypeEnums.WFH;
    }
  });

  const getTableHeader = (title) => {
    return (
      <Typography sx={dataGridStyles.tableHeaderStyle}>{title}</Typography>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'User',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'fromDate',
      headerName: 'From',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => formatDate(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'toDate',
      headerName: 'To',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => formatDate(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'totalDays',
      headerName: 'No.of.days',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => capitalize(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'leaveType',
      headerName: !isWFH ? 'Leave Type' : 'Request Type',
      flex: 1,
      editable: false,
      // hide: isWFH,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => capitalize(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'lop',
      headerName: 'Lop Days',
      flex: 1,
      editable: false,
      minWidth: 150,
      hide: isWFH,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'appliedOn',
      headerName: 'Applied On',
      editable: false,
      headerAlign: 'center',
      minWidth: 200,
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueFormatter: (row: any) => {
        const value = dayjs(new Date(row?.value))
          .format('DD/MM/YYYY - hh:mm a')
          .replace('am', 'AM')
          .replace('pm', 'PM');
        if (value === 'Invalid Date') return '-';
        else return value;
      },
      flex: 1,
    },
    {
      field: 'approvedOn',
      headerName: 'Approved on',
      minWidth: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      // valueFormatter: (row: any) => {
      //   const value = dayjs(new Date(row?.value)).format("DD/MM/YYYY");
      //   if (value === "Invalid Date") return "-";
      //   else return value;
      // },
      flex: 1,
    },
    {
      field: 'comments',
      headerName: 'Comments',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      editable: false,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (props) => {
        const item = props?.row;
        return (
          <Box display={'flex'} gap={1.5}>
            <Button
              id="Leave-Accept"
              variant="contained"
              size="small"
              sx={actionColumnStyles.acceptButtonStyle}
              onClick={() => {
                openConfirmationDialog(item, 'approve');
              }}
              disabled={item?.status !== 'pending'}
            >
              Accept
            </Button>
            <Button
              id="Leave-Reject"
              size="small"
              sx={actionColumnStyles.rejectButtonStyle}
              onClick={() => {
                openConfirmationDialog(item, 'reject');
              }}
              disabled={item?.status !== 'pending'}
            >
              Reject
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          id="confirmationModal"
          title={
            'Confirm Decision'
            // isWFH
            //   ? getConfirmationTitleForTeamWFHRequests()
            //   : getConfirmationMessageForTeamLeaves()
          }
          message={
            isWFH
              ? getConfirmationMessageForTeamWFHRequests()
              : getConfirmationMessageForTeamLeaves()
          }
          open={isConfirmationModalOpen}
          onClose={closeConfirmationDialog}
          onSubmit={() =>
            handleAcceptReject(selectedLeaveRequest, selectedDecision)
          }
          isSubmitButtonDisabled={isAcceptReject}
        />
      )}

      <div>
        <Box className="teamsContainer">
          <TsDatagrid
            rows={filteredLeaveList}
            columns={columns}
            pageSize={10}
            pageSizeArray={[10, 20, 30]}
            getSelectedRowsData={() => {
              // TODO: Implement function
            }}
            handlePageChange={() => {
              // TODO: Implement function
            }}
            handlePageSizeChange={() => {
              // TODO: Implement function
            }}
            isCheckboxSelection={false}
            totalElements={filteredLeaveList.length}
            sx={dataGridStyles.tableStyles}
            components={{
              NoRowsOverlay: () => (
                <Box sx={dataGridStyles.overlayStyle}>
                  {isLoading ? ' Loading Leaves' : 'No Requests Available'}
                </Box>
              ),
            }}
          />
        </Box>
      </div>
    </>
  );
};

export default TeamLeaves;

const actionColumnStyles = {
  acceptButtonStyle: {
    color: '#326B16',
    backgroundColor: '#EDEDED',
    textTransform: 'capitalize',
  },
  rejectButtonStyle: {
    color: '#C04A2B',
    width: '30px',
    textTransform: 'capitalize',
  },
};

const dataGridStyles = {
  tableStyles: {
    height: '500px',
  },
  overlayStyle: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableHeaderStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
};
