import {
  AnyOf7Events,
  DiscussionNote,
  EmployeeGrievance,
  EventType,
  EventTypes,
  PromotionWithHike,
  DesignationChange,
  QuarterlyVariablePay,
  SalaryHike,
  Violation,
  EventStatusConstants,
  UtilityConstants,
  QuartersTypes,
} from '../types/EventTypes';
import {
  convertDateTimeToISOString,
  convertISOStringToDayjs,
} from '../../../utils/DateOperations';
import dayjs from 'dayjs';
import {
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Box,
  FormLabel,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import { revertString } from '../utilFunctions/promotionUtils';
import {
  LocalizationProvider,
  DatePicker,
  ClearIcon,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FileUploader } from 'react-drag-drop-files';
import { FileDownloadOutlined } from '@mui/icons-material';
import { FileNotFoundIcon2 } from '../../../assets';

export const getExtraByType = (
  type: EventType,
  recentEvent: AnyOf7Events | null,
  userData: any
): AnyOf7Events => {
  const eventDetails = {
    [EventTypes?.PROMOTION_WITH_HIKE]: {
      eventType: EventTypes?.PROMOTION_WITH_HIKE,
      id: recentEvent ? recentEvent?.id + 1 : -1,
      previousDesignation: recentEvent
        ? (recentEvent as PromotionWithHike)?.currentDesignation
          ? (recentEvent as PromotionWithHike)?.currentDesignation
          : userData?.designation
        : userData?.designation,
      currentDesignation: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      previousDepartment: recentEvent
        ? (recentEvent as PromotionWithHike)?.currentDepartment
          ? (recentEvent as PromotionWithHike)?.currentDepartment
          : userData?.department
        : userData?.department,
      currentDepartment: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      revisionType: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      hikePercentage: recentEvent
        ? (recentEvent as PromotionWithHike)?.hikePercentage
          ? (recentEvent as PromotionWithHike)?.hikePercentage
          : 0
        : 0,
      effectiveDate: convertDateTimeToISOString(dayjs(new Date())),
      revisionDate: convertDateTimeToISOString(dayjs(new Date())),
      approvalStatus: EventStatusConstants?.APPROVED,
      approverName: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      comments: '',
      supportingDocuments: [],
      isNew: true,
      referenceDocs: [],
    } as PromotionWithHike,

    [EventTypes?.DESIGNATION_CHANGE]: {
      eventType: EventTypes?.DESIGNATION_CHANGE,
      id: recentEvent ? recentEvent?.id + 1 : -1,
      previousDesignation: recentEvent
        ? (recentEvent as DesignationChange)?.currentDesignation
          ? (recentEvent as DesignationChange)?.currentDesignation
          : userData?.designation
        : userData?.designation,
      currentDesignation: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      previousDepartment: recentEvent
        ? (recentEvent as DesignationChange)?.currentDepartment
          ? (recentEvent as DesignationChange)?.currentDepartment
          : userData?.department
        : userData?.department,
      currentDepartment: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      revisionType: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      effectiveDate: convertDateTimeToISOString(dayjs(new Date())),
      revisionDate: convertDateTimeToISOString(dayjs(new Date())),
      approvalStatus: EventStatusConstants?.APPROVED,
      approverName: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      comments: '',
      supportingDocuments: [],
      isNew: true,
      referenceDocs: [],
    } as DesignationChange,

    [EventTypes?.SALARY_HIKE]: {
      id: recentEvent ? recentEvent?.id + 1 : -1,
      eventType: EventTypes?.SALARY_HIKE,
      hikePercentage: 0,
      effectiveDate: convertDateTimeToISOString(dayjs(new Date())),
      revisionDate: convertDateTimeToISOString(dayjs(new Date())),
      approvalStatus: EventStatusConstants?.APPROVED,
      approverName: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      performanceScore: 0,
      supportingDocuments: [],
      referenceDocs: [],
      comments: '',
      isNew: true,
    } as SalaryHike,

    [EventTypes?.VIOLATION]: {
      id: recentEvent ? recentEvent?.id + 1 : -1,
      eventType: EventTypes?.VIOLATION,
      violationType: '',
      effectiveDate: convertDateTimeToISOString(dayjs(new Date())),
      actionTaken: '',
      supportingDocuments: [],
      referenceDocs: [],
      isNew: true,
    } as Violation,

    [EventTypes?.QUARTERLY_VARIABLE_PAY]: {
      id: recentEvent ? recentEvent?.id + 1 : -1,
      eventType: EventTypes?.QUARTERLY_VARIABLE_PAY,
      quarter: QuartersTypes?.Q1,
      quarterlyPayAmount: 0,
      performanceScore: 0,
      effectiveDate: convertDateTimeToISOString(dayjs(new Date())),
      supportingDocuments: [],
      referenceDocs: [],
      comments: '',
      isNew: true,
    } as QuarterlyVariablePay,

    [EventTypes?.GRIEVANCE]: {
      eventType: EventTypes?.GRIEVANCE,
      id: recentEvent ? recentEvent?.id + 1 : -1,
      grievanceType: '',
      grievanceDescription: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      preferredResolution: UtilityConstants?.EVENT_PROP_INITIAL_VALUE,
      effectiveDate: convertDateTimeToISOString(dayjs(new Date())),
      comments: '',
      supportingDocuments: [],
      referenceDocs: [],
      isNew: true,
    } as EmployeeGrievance,
    [EventTypes?.DISCUSSION_NOTE]: {
      eventType: EventTypes?.DISCUSSION_NOTE,
      id: recentEvent ? recentEvent?.id + 1 : -1,
      discussionType: '',
      participants: '',
      effectiveDate: convertDateTimeToISOString(dayjs(new Date())),
      comments: '',
      supportingDocuments: [],
      referenceDocs: [],
      isNew: true,
    } as DiscussionNote,
  };

  const eventDetail = eventDetails[type];

  if (eventDetail) {
    return eventDetail;
  }

  throw new Error(`Unknown event type: ${type}`);
};

export const getRenderHeading = (
  md: any,
  heading: any,
  value: any,
  styles: any
) => {
  return (
    <Grid item xs={12} md={md}>
      <Typography className={styles.heading}>{heading}</Typography>
      <Typography className={styles.content} title={value}>
        {value?.length > 30 ? `${value.substring(0, 30)}...` : value}
      </Typography>
    </Grid>
  );
};

export const getDropDownAsGridItem = (
  md: number,
  isEditing: boolean,
  label: string,
  fieldId: string,
  handleFieldChange: any,
  value: any,
  defaultValue: string,
  dropDownValues: any,
  error: any,
  helperText: any,
  styles: any
) => {
  return (
    <Grid item xs={12} md={md}>
      <FormControl fullWidth disabled={!isEditing} error={error}>
        <InputLabel shrink>{label}</InputLabel>
        <Select
          displayEmpty
          label={label}
          name={fieldId}
          value={value}
          onChange={(e) => {
            handleFieldChange(fieldId, e?.target?.value);
          }}
        >
          <MenuItem value="">{defaultValue}</MenuItem>
          {Object.values(dropDownValues)?.map((type: any) => (
            <MenuItem key={type} value={type}>
              {revertString(type)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText className={styles.helperText}>
        {helperText}
      </FormHelperText>
    </Grid>
  );
};
export const getCustomDropDownAsGridItem = (
  md: number,
  isEditing: boolean,
  label: string,
  fieldId: string,
  handleFieldChange: any,
  value: any,
  defaultValueFn: () => string,
  options: Array<{ value: string; label: string }>,
  error: any,
  helperText: any,
  styles: any
) => {
  return (
    <Grid item xs={12} md={md}>
      <FormControl fullWidth disabled={!isEditing} error={error}>
        <InputLabel shrink>{label}</InputLabel>
        <Select
          label={label}
          name={fieldId}
          value={
            value && options.map((option) => option.value).includes(value)
              ? value
              : defaultValueFn()
          }
          onChange={(e) => {
            handleFieldChange(fieldId, e?.target?.value);
          }}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option?.value}>
              {option?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText className={styles.helperText}>
        {helperText}
      </FormHelperText>
    </Grid>
  );
};

export const getDatePickerAsGridItem = (
  md: number,
  isEditing: boolean,
  label: string,
  fieldId: string,
  handleFieldChange: any,
  value: any,
  minDateValue: any,
  error: any,
  helperText: any,
  styles: any
) => {
  return (
    <Grid item xs={12} md={md}>
      <FormControl
        fullWidth
        variant="outlined"
        disabled={!isEditing}
        error={error}
      >
        <Box className={styles.dateBoxContainer}>
          <FormLabel component="legend" className={styles.dateBoxFormLabel}>
            <Typography className={styles.dateBoxHeading}>{label}</Typography>
          </FormLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={convertISOStringToDayjs(value ?? '')}
              disabled={!isEditing}
              onChange={(dateDayJs: any) => {
                try {
                  const formattedDate = convertDateTimeToISOString(dateDayJs);
                  handleFieldChange(fieldId, formattedDate);
                } catch (error: any) {
                  handleFieldChange(fieldId, '');
                }
              }}
              slotProps={{
                actionBar: { actions: ['clear', 'today'] },
                textField: { fullWidth: true },
              }}
            />
          </LocalizationProvider>
        </Box>
      </FormControl>
      <FormHelperText className={styles.helperText}>
        {helperText}
      </FormHelperText>
    </Grid>
  );
};

export const getSingleLinedTextFieldAsGridItem = (
  md: number,
  label: string,
  fieldId: string,
  handleFieldChange: any,
  value: any,
  isEditing: boolean,
  error: any,
  helperText: any,
  styles: any
) => {
  return (
    <Grid item xs={12} md={md}>
      <TextField
        label={label}
        name={fieldId}
        value={value}
        disabled={!isEditing}
        onChange={(e) => handleFieldChange(fieldId, e?.target?.value)}
        fullWidth
        className={styles.multilineText}
        error={error}
      />
      <FormHelperText className={styles.helperText}>
        {helperText}
      </FormHelperText>
    </Grid>
  );
};
export const getMultiLinedTextFieldAsGridItem = (
  md: number,
  label: string,
  fieldId: string,
  handleFieldChange: any,
  value: any,
  isEditing: boolean,
  error: any,
  helperText: any,
  styles: any
) => {
  return (
    <Grid item xs={12} md={md}>
      <TextField
        label={label}
        name={fieldId}
        value={value}
        disabled={!isEditing}
        onChange={(e) => handleFieldChange(fieldId, e?.target?.value)}
        fullWidth
        multiline
        maxRows={3}
        className={styles.multilineText}
        error={error}
      />
      <FormHelperText className={styles.helperText}>
        {helperText}
      </FormHelperText>
    </Grid>
  );
};

export const getDisabledFieldAsGridItem = (
  md: number,
  label: string,
  fieldId: string,
  handleFieldChange: any,
  value: any,
  styles: any,
  error: any,
  helperText: any
) => {
  return (
    <Grid item xs={12} md={md}>
      <TextField
        label={label}
        name={fieldId}
        value={value}
        disabled={value !== ''}
        onChange={(e) => {
          handleFieldChange(fieldId, e?.target?.value);
        }}
        fullWidth
        error={error}
      />
      <FormHelperText className={styles.helperText}>
        {helperText}
      </FormHelperText>
    </Grid>
  );
};

export const getNumberTextFieldAsGridItem = (
  md: number,
  label: string,
  fieldId: string,
  handleFieldChange: any,
  value: any,
  isEditing: boolean,
  error: any,
  helperText: any,
  styles: any
) => {
  return (
    <Grid item xs={12} md={md}>
      <TextField
        label={label}
        name={fieldId}
        value={value}
        onChange={(e) => {
          const inputValue = parseFloat(e?.target?.value);
          if (inputValue >= 0 && inputValue <= 100) {
            handleFieldChange(fieldId, inputValue);
          } else {
            const boundedValue = Math.max(0, Math.min(100, inputValue));
            handleFieldChange(fieldId, boundedValue);
          }
        }}
        disabled={!isEditing}
        type="number"
        fullWidth
        InputProps={{ inputProps: { min: 0, max: 100 } }}
        error={error}
      />
      <FormHelperText className={styles.helperText}>
        {helperText}
      </FormHelperText>
    </Grid>
  );
};

export const getDocumentsFieldAsGridItem = (
  heading: string,
  styles: any,
  isEditing: boolean,
  handleFileChange: any,
  fileTypes: any,
  currentAllDocs: any,
  downloadFiles: any,
  filesBuffer: any,
  deleteFromFilesBuffer
) => {
  return (
    <Grid item xs={12}>
      <Typography className={`${styles.heading} ${styles.disabledTextColor}`}>
        {heading}
      </Typography>
      <FormControl fullWidth disabled={!isEditing}>
        {isEditing && (
          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={2}
            flexWrap={'wrap'}
            marginTop={2}
          >
            <FileUploader
              multiple={true}
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
            />
          </Box>
        )}
        <Box className={styles.currentDocsContainer} gap={3}>
          {!isEditing
            ? currentAllDocs?.map((d: any, i: number) => (
                <Box key={d?.id || i} className={styles.documentsBox}>
                  <Box className={styles.currentAllDocsContainer}>
                    {(() => {
                      switch (d?.fileType) {
                        case 'image/png':
                          return (
                            <img
                              className={styles.icon}
                              src="https://cdn-icons-png.flaticon.com/512/337/337948.png"
                              alt="icon"
                            />
                          );
                        case 'application/pdf':
                          return (
                            <img
                              className={styles.icon}
                              src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
                              alt="icon"
                            />
                          );
                        case 'image/jpg':
                          return (
                            <img
                              className={styles.icon}
                              src="https://www.svgrepo.com/show/255826/jpg.svg"
                              alt="icon"
                            />
                          );
                        case 'doc':
                          return (
                            <img
                              className={styles.icon}
                              src="https://www.svgrepo.com/show/255810/doc.svg"
                              alt="icon"
                            />
                          );
                        case 'text/csv':
                          return (
                            <img
                              className={styles.icon}
                              src="https://cdn-icons-png.flaticon.com/512/172/172534.png"
                              alt="icon"
                            />
                          );
                        case 'image/jpeg':
                          return (
                            <img
                              className={styles.icon}
                              src="https://cdn-icons-png.flaticon.com/512/337/337940.png"
                              alt="icon"
                            />
                          );
                        case 'text/plain':
                          return (
                            <img
                              className={styles.icon}
                              src="https://cdn-icons-png.flaticon.com/512/2656/2656402.png"
                              alt="icon"
                            />
                          );
                        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                          return (
                            <img
                              className={styles.icon}
                              src="https://img.icons8.com/external-fauzidea-flat-fauzidea/256/external-docx-file-file-extension-fauzidea-flat-fauzidea.png"
                              alt="icon"
                            />
                          );
                        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                          return (
                            <img
                              className={styles.icon}
                              src="https://cdn-icons-png.flaticon.com/512/9496/9496502.png"
                              alt="icon"
                            />
                          );
                        case UtilityConstants?.UNKNOWN:
                          return (
                            <Box
                              component={'img'}
                              src={FileNotFoundIcon2}
                              className={styles.fileIconStyle}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </Box>
                  <Box
                    className={`${styles.fileNameBox} ${styles.disabledTextColor}`}
                  >
                    {d?.fileName || d?.name}
                  </Box>
                  {d?.fileType !== UtilityConstants?.UNKNOWN && (
                    <Box>
                      <IconButton
                        onClick={() =>
                          downloadFiles(d?.id, d?.fileName || d?.name)
                        }
                      >
                        <FileDownloadOutlined className={styles.downloadIcon} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              ))
            : filesBuffer.map((file: any, i: any) => {
                return (
                  <Box key={i} className={styles.filesBufferContainer}>
                    <Typography className={styles.filesBufferFileName}>
                      {file?.name}
                    </Typography>

                    <ClearIcon
                      onClick={() => {
                        deleteFromFilesBuffer(file, i);
                      }}
                      className={styles.filesBufferClearIcon}
                    />
                  </Box>
                );
              })}
        </Box>
      </FormControl>
    </Grid>
  );
};

const CancelButton = ({ isEditing, event, handleCancel, styles }: any) => {
  return (
    isEditing &&
    !event?.isNew && (
      <Button
        variant="contained"
        color="primary"
        onClick={handleCancel}
        className={styles.ctaCancelButton}
      >
        Cancel
      </Button>
    )
  );
};

const SaveEditButton = ({ isEditing, formOne, handleEdit }: any) => {
  return (
    <Button
      variant="contained"
      color={isEditing ? 'primary' : 'secondary'}
      onClick={isEditing ? formOne?.handleSubmit : handleEdit}
    >
      {isEditing ? 'Save' : 'Edit'}
    </Button>
  );
};

const DeleteButton = ({ isEditing, event, handleDelete, styles }: any) => {
  return (
    (!isEditing || event?.isNew) && (
      <Button
        variant="contained"
        className={styles.ctaDeleteButton}
        onClick={handleDelete}
      >
        {event?.isNew ? 'Remove' : 'Delete'}
      </Button>
    )
  );
};

export const AllButtonGroup = ({
  isEditing,
  event,
  handleCancel,
  handleEdit,
  handleDelete,
  formOne,
  styles,
}) => {
  return (
    <Box className={styles.ctaContainer}>
      <DeleteButton
        isEditing={isEditing}
        event={event}
        handleDelete={handleDelete}
        styles={styles}
      />
      <CancelButton
        isEditing={isEditing}
        event={event}
        handleCancel={handleCancel}
        styles={styles}
      />
      <SaveEditButton
        isEditing={isEditing}
        formOne={formOne}
        handleEdit={handleEdit}
      />
    </Box>
  );
};
