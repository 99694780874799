import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { FINANCE_FORM_ID } from '../../../../constants/formsConstants';
import { store } from '../../../../redux/store';
import {
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from '../../../../services/formService';
import {
  accountNumberMsg,
  bankNameMsg,
  branchMsg,
  ifscMsg,
  OptedForSodexoMealCouponsMsg,
} from './formConstants';
import CONSTANTS from '../../../../constants/constants';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../../../redux/actions';

const styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  sectionTitle: {
    color: 'red',
    width: '100%',
  },
  gridItem: {
    xs: 12,
    marginTop: '16px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: '300px',
  },
  submitButtonContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignContent: 'center',
  },
  submitButton: {
    backgroundColor: '#f15830',
    color: 'white',
  },
  subHeadText: {
    color: 'black',
  },
  errorText: {
    color: 'red',
  },
};

const Finance = (props: any) => {
  const { setOpenFormExitPopup } = props;
  const dispatch = useDispatch();
  const reduxState = store.getState();

  const [formId, setFormId] = React.useState('');

  // const [file, setFile] = React.useState(null);

  // const handleFileChange = async (file: any) => {
  //   setFile(file);
  //   const response: any = await uploadFileApi(file.name, file, "empMaster", {});

  //   let data = {
  //     fileName: file.name,
  //     fileType: file.type,
  //     fileSize: file.size,
  //     documentId: response.data.id,
  //   };
  // };

  const formOne = useFormik({
    initialValues: {
      bankName: '',
      accountNumber: '',
      ifsc: '',
      branch: '',
      optedForSodexoMealCoupons: false,
    },
    validationSchema: Yup.object({
      bankName: Yup.string().required(bankNameMsg),
      // .min(3, "Minimum 3 Characters"),
      accountNumber: Yup.string().required(accountNumberMsg),
      ifsc: Yup.string().required(ifscMsg),
      branch: Yup.string().required(branchMsg),
      optedForSodexoMealCoupons: Yup.boolean().required(
        OptedForSodexoMealCouponsMsg
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },

    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);
    postFormValues(values);
  };
  // const handleSubmission = (e) => {
  //   e.preventDefault();
  //   formOne.handleSubmit();
  //   let values = { ...formOne.values };
  // };

  // const handlePreviousTab = () => {
  //   setValue((prevState) => prevState - 1);
  // };

  const formPayload = (values: any) => ({
    formId: FINANCE_FORM_ID,
    formData: {
      reportingTo: reduxState.reportingToEmailId,
      officialEmail: reduxState.officialEmailId,
      bankName: values.bankName,
      accountNumber: values.accountNumber,
      financeStatus: 'Pending',
      ifsc: values.ifsc,
      branch: values.branch,
      optedForSodexoMealCoupons: values.optedForSodexoMealCoupons,
    },
  });
  const postFormValues = async (values: any) => {
    const postData = formPayload(values);

    if (reduxState.officialEmailId) {
      const payload = {
        formId: postData.formId,
        id: formId,
        formData: postData.formData,
      };
      const response = await postForm(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    } else {
      const response = await postForm(postData);

      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    }
  };

  useEffect(() => {
    const caller = async () => {
      await prefillData();
      // setFormInitialData(formOne.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState.officialEmailId]);

  useEffect(() => {
    setOpenFormExitPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOne.values]);

  const prefillData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      FINANCE_FORM_ID,
      reduxState.officialEmailId
    );

    if (response?.success && response?.data?.content?.length > 0) {
      const mapData: any = response?.data?.content?.[0]?.formData;
      setFormId(response?.data?.content?.[0]?.id);

      const finalData: any = {
        bankName: mapData?.bankName,
        accountNumber: mapData?.accountNumber,
        ifsc: mapData?.ifsc,
        branch: mapData?.branch,
        optedForSodexoMealCoupons: mapData?.optedForSodexoMealCoupons,
      };

      formOne.setValues(finalData);
    } else {
      console.error('Prefill Data Failed');
    }
  };

  return (
    <>
      <Box>
        <form onSubmit={formOne.handleSubmit}>
          <Grid container gap={2}>
            <Typography sx={styles.sectionTitle}>
              Bank Details <Divider />
            </Typography>
            <Grid xs={12}>
              <Box sx={styles.rowContainer}>
                <Box sx={styles.columnContainer}>
                  <label>Bank Name*</label>
                  <TextField
                    id="bank-Name"
                    name="bankName"
                    size="small"
                    helperText={
                      formOne.touched.bankName && formOne.errors.bankName
                    }
                    error={
                      formOne.touched.bankName &&
                      Boolean(formOne.errors.bankName)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.bankName}
                    sx={styles.textField}
                  />
                </Box>
                <Box sx={styles.columnContainer}>
                  <label>Account Number*</label>
                  <TextField
                    size="small"
                    id="account-number"
                    name="accountNumber"
                    helperText={
                      formOne.touched.accountNumber &&
                      formOne.errors.accountNumber
                    }
                    error={
                      formOne.touched.accountNumber &&
                      Boolean(formOne.errors.accountNumber)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.accountNumber}
                    sx={styles.textField}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box mt={3} sx={styles.rowContainer}>
                <Box display={'flex'} flexDirection={'column'}>
                  <label>IFSC*</label>
                  <TextField
                    id="ifsc"
                    name="ifsc"
                    size="small"
                    helperText={formOne.touched.ifsc && formOne.errors.ifsc}
                    error={formOne.touched.ifsc && Boolean(formOne.errors.ifsc)}
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.ifsc}
                    sx={styles.textField}
                  />
                </Box>
                <Box mt={3} sx={styles.columnContainer}>
                  <label>Branch*</label>
                  <TextField
                    id="branch"
                    name="branch"
                    size="small"
                    helperText={formOne.touched.branch && formOne.errors.branch}
                    error={
                      formOne.touched.branch && Boolean(formOne.errors.branch)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.branch}
                    sx={styles.textField}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box mt={3} sx={styles.rowContainer}>
                <Box sx={styles.columnContainer}></Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container gap={2} marginTop={3}>
            <Typography sx={styles.sectionTitle}>
              Tax Benefits
              <Divider />
            </Typography>
            <Box sx={styles.rowContainer}>
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={styles.subHeadText}
                >
                  Opted for Sodexo Meal Coupons *
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="optedForSodexoMealCoupons"
                  id="Sodexo-meal"
                  onBlur={formOne.handleBlur}
                  onChange={(e) => {
                    if (e.target.value === 'true') {
                      formOne.setFieldValue('optedForSodexoMealCoupons', true);
                    } else {
                      formOne.setFieldValue('optedForSodexoMealCoupons', false);
                    }
                  }}
                  value={formOne.values.optedForSodexoMealCoupons}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                <FormHelperText sx={styles.errorText}>
                  {formOne.touched.optedForSodexoMealCoupons &&
                    formOne.errors.optedForSodexoMealCoupons}
                </FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Box sx={styles.submitButtonContainer}>
            <Button variant="contained" sx={styles.submitButton} type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Finance;
