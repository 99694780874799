import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@mui/styles';
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from 'moment';
import { ReactComponent as BloodGroup } from '../../../../utils/images/bloodGroup.svg';
import CONSTANTS, {
  AADHAR_NO,
  EMAIL_REGEX,
  GENERAL_FORMID,
  PAN_NO,
  phoneNumberRegex,
} from '../../../../constants/constants';
import { useDispatch } from 'react-redux';
import {
  pushNotification,
  setLoader,
  setReportingToEmailId,
} from '../../../../redux/actions';
import { store } from '../../../../redux/store';
import {
  FilterFormDetailsWithOfficalEmail,
  postFormGeneral,
} from '../../../../services/formService';
import {
  aadharNumberMsg,
  bloodGroupMsg,
  departmentRequiredMsg,
  designationRequriedMsg,
  dobAsPerCertificateMsg,
  dobAsPerCertificateMsg1,
  dobAsPerCertificateMsg2,
  dobAsPerCertificateMsg3,
  empIdMsg1,
  empIdMsg2,
  empTypeMsg,
  firstNameMsg,
  firstNameMsg1,
  genderMsg,
  joiningDateMsg,
  lastNameMsg,
  lastNameMsg1,
  lopDaysRequiredMsg,
  officialEmailMsg,
  officialEmailMsg1,
  panNumberMsg,
  phoneNumberMsg1,
  probationRequiredMsg,
  reportingToMsg,
  reportingToMsg1,
  statusRequriedMsg,
} from './formConstants';
import { LEAVE_DETAILS_FORM_ID } from '../../../../constants/formConstants';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDatePicker from '../../../forms/CustomDatePicker';
import { convertISOStringToDayjs } from '../../../../utils/DateOperations';

const StatusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
];

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: '#f15830',
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '15px',
  },
  addBtn: {
    marginTop: '15px',
  },
  createEmployeeBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  datePickerStyles: {
    color: 'inherit',
    height: '36px',
    border: '1px solid grey !important',
    borderRadius: '5px',
    '&::placeholder': {
      textAlign: 'center',
    },
    textAlign: 'center',
    fontSize: 'inherit',
  },
  phoneInputStyles: {
    width: '100% !important',

    '@media (min-width: 320px )': {
      width: '100% !important',
    },
    '@media (min-width: 375px)': {
      width: '85% !important',
    },
    '@media (min-width: 425px)': {
      width: '85% !important',
    },
    '@media (min-width: 600px)': {
      width: '100% !important',
    },
    '@media (min-width: 768px)': {
      width: '100% !important',
    },
    '@media (min-width: 1440px)': {
      width: '100% !important',
    },
    '@media (min-width: 2560px)': {
      width: '100% !important',
    },
  },
  errorText: {
    color: 'red',
    marginTop: '5px',
  },
  helperText: {
    color: 'red',
  },
  menuItem: {
    height: '18px',
  },
}));

const PersonalForm = (props: any) => {
  const { setOpenFormExitPopup } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const reduxState = store.getState();
  const [formId, setFormId] = useState<any>('');

  const bloodTypes = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];
  const [updatedFields, setUpdatedFields] = useState({});

  const [countryCode, setCountryCode] = useState('');
  // const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [countryRegex, setCountryRegex] = useState(
    phoneNumberRegex[countryCode]
  );
  const handlePhoneChange = async (value, country) => {
    if (country && 'dialCode' in country) {
      // const countryCode = `+${country?.dialCode}`;
      // const phoneNumber = value?.slice(country?.dialCode?.length);
      // const formattedNumber = `${countryCode}-${phoneNumber}`;
      // setFormattedPhoneNumber(formattedNumber);
    }
    setCountryCode(country?.countryCode);
    setCountryRegex(phoneNumberRegex[country?.countryCode]);
  };

  const formOne = useFormik({
    initialValues: {
      empId: '',
      employeeType: '',
      officialEmail: '',
      firstName: '',
      lastName: '',
      gender: '',
      phoneNumber: '',
      dobAsPerCertificate: '',
      bloodGroup: '',
      panNumber: '',
      aadharNumber: '',
      reportingTo: '',
      dateOfJoining: '',
      lastRevisionDate: '',
      designation: '',
      department: '',
      lopDays: '',
      probation: '',
      status: '',
    },
    validationSchema: Yup.object({
      empId: Yup.string().required(empIdMsg1).min(3, empIdMsg2),
      employeeType: Yup.string().required(empTypeMsg),
      officialEmail: Yup.string()
        .required(officialEmailMsg)
        .matches(EMAIL_REGEX, officialEmailMsg1),
      firstName: Yup.string().required(firstNameMsg).min(1, firstNameMsg1),
      lastName: Yup.string().required(lastNameMsg).min(1, lastNameMsg1),
      gender: Yup.string().required(genderMsg),
      phoneNumber: Yup.string().matches(countryRegex, phoneNumberMsg1),
      //   .required(phoneNumberMsg)
      //   .matches(PHONE_REGEX, phoneNumberMsg1)
      //   .length(10),
      dobAsPerCertificate: Yup.string()
        .required(dobAsPerCertificateMsg)
        .test(
          dobAsPerCertificateMsg1,
          dobAsPerCertificateMsg2,
          (date) => moment().diff(moment(date), dobAsPerCertificateMsg3) >= 18
        ),

      bloodGroup: Yup.string().required(bloodGroupMsg),

      panNumber: Yup.string()
        .required(panNumberMsg)
        .max(10)
        .matches(PAN_NO)
        .length(10),
      aadharNumber: Yup.string()
        .required(aadharNumberMsg)
        .matches(AADHAR_NO, 'Please enter a valid Aadhaar Number')
        .length(12),

      reportingTo: Yup.string()
        .required(reportingToMsg)
        .min(3, reportingToMsg1),

      dateOfJoining: Yup.string().required(joiningDateMsg),
      designation: Yup.string().required(designationRequriedMsg),
      department: Yup.string().required(departmentRequiredMsg),
      probation: Yup.string().required(probationRequiredMsg),
      lopDays: Yup.number().required(lopDaysRequiredMsg).min(0),
      status: Yup.string().required(statusRequriedMsg),
      // .test(
      //   joiningDateMsg1,
      //   joiningDateMsg2
      //   // (date) => date === moment()
      // ),
    }),

    onSubmit: (values, { setSubmitting }) => {
      if (values?.phoneNumber?.length === 10)
        values.phoneNumber = defaultCountryCode + values?.phoneNumber;
      // values.phoneNumber = formattedPhoneNumber;
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleDateChange = (dateString: string) => {
    formOne.handleChange(dateString);
    formOne.setFieldValue('lastRevisionDate', dateString, true);
  };

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);

    postFormValues(values);
  };

  const formPayload = (values: any) => ({
    formId: GENERAL_FORMID,
    formData: {
      aadharNumber: values.aadharNumber,
      bloodGroup: values.bloodGroup,
      dateOfJoining: values.dateOfJoining,
      dob: values.dobAsPerCertificate,
      empId: values.empId,
      employeeType: values.employeeType,
      firstName: values.firstName,
      gender: values.gender,
      lastName: values.lastName,
      officialEmail: values.officialEmail,
      panNumber: values.panNumber,
      phone: values.phoneNumber,
      reportingTo: values.reportingTo,
      status: values.status,
      designation: values.designation,
      department: values.department,
      probation: values.probation,
      lopDays: values.lopDays,
    },
    updatedFields: {
      ...updatedFields,
    },
  });

  useEffect(() => {
    prefillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState.officialEmailId]);

  useEffect(() => {
    const caller = async () => {
      await prefillData();
      // setFormInitialData(formOne.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpenFormExitPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOne.values]);

  const prefillData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      GENERAL_FORMID,
      reduxState.officialEmailId
    );
    const response1 = await FilterFormDetailsWithOfficalEmail(
      LEAVE_DETAILS_FORM_ID,
      reduxState.officialEmailId
    );
    if (
      response.success &&
      response?.data?.content?.length > 0 &&
      response1.success &&
      response1?.data?.content?.length > 0
    ) {
      const mapData: any = response?.data?.content[0]?.formData;
      const mapData1: any = response1?.data?.content[0]?.formData;
      setFormId(response?.data?.content[0]?.id);
      dispatch(
        setReportingToEmailId(mapData?.reportingTo || mapData1?.reportingTo)
      );

      const finalData: any = {
        empId: mapData?.empId,
        employeeType: mapData?.employeeType,
        firstName: mapData?.firstName,
        lastName: mapData?.lastName,
        gender: mapData?.gender,
        phoneNumber: mapData?.phone,
        dobAsPerCertificate: mapData?.dob,
        bloodGroup: mapData?.bloodGroup,
        panNumber: mapData?.panNumber,
        aadharNumber: mapData?.aadharNumber,
        officialEmail: mapData?.officialEmail,
        reportingTo: mapData?.reportingTo,
        dateOfJoining: mapData?.dateOfJoining,
        designation: mapData?.designation,
        department: mapData?.department,
        probation: mapData1?.probation,
        lopDays: mapData1?.lopDays,
        status: mapData?.status,
        lastRevisionDate: mapData?.lastRevisionDate,
      };

      formOne.setValues(finalData);
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  const postFormValues = async (values: any) => {
    dispatch(setLoader(true));
    const postData = formPayload(values);

    if (formId) {
      const payload = {
        formId: postData.formId,
        id: formId,
        formData: postData.formData,
        updatedFields: postData.updatedFields,
      };
      const response: any = await postFormGeneral(payload);
      if (response.success) {
        dispatch(setLoader(false));
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setOpenFormExitPopup(false);
      } else {
        dispatch(setLoader(false));
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    } else {
      const response = await postFormGeneral(postData);

      if (response.success) {
        dispatch(setLoader(false));
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setOpenFormExitPopup(false);
      } else {
        dispatch(setLoader(false));
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    }
  };

  const handleUpdatedFields = (key, value) => {
    const fields = { ...updatedFields };
    fields[key] = value;
    setUpdatedFields(fields);
  };

  const defaultCountryCode = '+91';
  return (
    <>
      <Box>
        <form onSubmit={formOne.handleSubmit}>
          <Grid container gap={2}>
            <Typography color={'#f15830'} width="100%">
              General
              <Divider />
            </Typography>
            <Grid xs={12}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={2}
                flexWrap={'wrap'}
              >
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Employee *</label>
                  <FormControl sx={{ width: '200px' }} size="small">
                    <Select
                      labelId="employeeType"
                      id="employee-type"
                      value={formOne.values.employeeType}
                      onChange={(e) => {
                        formOne.setFieldValue(
                          'employeeType',
                          e.target.value,
                          true
                        );
                      }}
                      error={
                        formOne?.touched?.employeeType &&
                        Boolean(formOne?.errors?.employeeType)
                      }
                    >
                      <MenuItem value={'FTE'}>FTE</MenuItem>
                      <MenuItem value={'CE'}>CE</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>
                      {formOne?.touched?.employeeType &&
                        formOne?.errors?.employeeType}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Emp ID *</label>
                  <TextField
                    id="emp-id"
                    name="empId"
                    size="small"
                    helperText={
                      formOne?.touched?.empId && formOne?.errors?.empId
                    }
                    error={
                      formOne?.touched?.empId && Boolean(formOne?.errors?.empId)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      handleUpdatedFields('empId', event.target.value);
                    }}
                    value={formOne.values.empId}
                    sx={{ width: '240px' }}
                    disabled
                  />
                </Box>

                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Official Email *</label>
                  <TextField
                    size="small"
                    id="officialEmail"
                    name="officialEmail"
                    helperText={
                      formOne?.touched?.officialEmail &&
                      formOne?.errors?.officialEmail
                    }
                    error={
                      formOne?.touched?.officialEmail &&
                      Boolean(formOne?.errors?.officialEmail)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      handleUpdatedFields('officialEmail', event.target.value);
                    }}
                    value={formOne.values.officialEmail}
                    sx={{ width: '240px' }}
                    disabled
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={2}
                flexWrap={'wrap'}
              >
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}> Designation*</label>
                  <TextField
                    id="Designation"
                    name="designation"
                    size="small"
                    helperText={
                      formOne?.touched?.designation &&
                      formOne?.errors?.designation
                    }
                    error={
                      formOne?.touched?.designation &&
                      Boolean(formOne?.errors?.designation)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.designation}
                    sx={{ width: '240px' }}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>
                    {' '}
                    Reporting Manager Email*
                  </label>
                  <TextField
                    id="reporting-to"
                    name="reportingTo"
                    size="small"
                    helperText={
                      formOne?.touched?.reportingTo &&
                      formOne?.errors?.reportingTo
                    }
                    error={
                      formOne?.touched?.reportingTo &&
                      Boolean(formOne?.errors?.reportingTo)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      handleUpdatedFields('reportingTo', event.target.value);
                    }}
                    value={formOne.values.reportingTo}
                    sx={{ width: '240px' }}
                  />
                </Box>
                {/* <Box display={"flex"} flexDirection={"column"} >
                  <label className={classes.label}>Joining Date*</label>
                  <TextField
                    id="joining-date"
                    name="joiningDate"
                    size="small"
                    helperText={
                       formOne?.touched?.joiningDate && formOne?.errors?.joiningDate
                    }
                    error={
                       formOne?.touched?.joiningDate &&
                       Boolean(formOne?.errors?.joiningDate)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.joiningDate}
                    sx={{ width: "345px"}}
                  />
                </Box> */}
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Joining Date*</label>
                  <CustomDatePicker
                    value={formOne.values.dateOfJoining}
                    handleDateChange={(dateString) => {
                      formOne.handleChange(dateString);
                      handleUpdatedFields('dateOfJoining', dateString);
                      formOne.setFieldValue('dateOfJoining', dateString, true);
                    }}
                    errorText={
                      formOne?.touched?.dateOfJoining &&
                      formOne?.errors?.dateOfJoining
                    }
                    isError={
                      formOne?.touched?.dateOfJoining &&
                      Boolean(formOne?.errors?.dateOfJoining)
                    }
                    textFieldDisabled={false}
                    datePickerdisabled={false}
                    padding={'17.9px 14px'}
                    rest={{}}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={2}
                flexWrap={'wrap'}
              >
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}> Department*</label>
                  <TextField
                    id="Department"
                    name="department"
                    size="small"
                    helperText={
                      formOne?.touched?.department &&
                      formOne?.errors?.department
                    }
                    error={
                      formOne?.touched?.department &&
                      Boolean(formOne?.errors?.department)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.department}
                    sx={{ width: '240px' }}
                  />
                </Box>

                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>On Probation*</label>
                  <FormControl sx={{ width: '200px' }} size="small">
                    <Select
                      labelId="probation"
                      id="probation"
                      value={formOne.values.probation}
                      onChange={(e) => {
                        formOne.setFieldValue(
                          'probation',
                          e.target.value,
                          true
                        );
                        handleUpdatedFields('probation', e.target.value);
                      }}
                      error={
                        formOne?.touched?.probation &&
                        Boolean(formOne?.errors?.probation)
                      }
                    >
                      <MenuItem value={'applicable'}>Yes</MenuItem>
                      <MenuItem value={'completed'}>No</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>
                      {formOne?.touched?.probation &&
                        formOne?.errors?.probation}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Last Revision Date</label>

                  <CustomDatePicker
                    value={convertISOStringToDayjs(
                      formOne?.values?.lastRevisionDate
                    )}
                    errorText={
                      formOne?.touched?.lastRevisionDate &&
                      formOne?.errors?.lastRevisionDate
                    }
                    isError={
                      formOne?.touched?.lastRevisionDate &&
                      Boolean(formOne?.errors?.lastRevisionDate)
                    }
                    handleDateChange={handleDateChange}
                    datePickerdisabled={true}
                    textFieldDisabled={true}
                    rest={{}}
                    padding={'17.8px 14px'}
                  />
                </Box>

                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>LOP Days*</label>
                  <TextField
                    id="LOPDays"
                    name="lopDays"
                    size="small"
                    helperText={
                      formOne?.touched?.lopDays && formOne?.errors?.lopDays
                    }
                    error={
                      formOne?.touched?.lopDays &&
                      Boolean(formOne?.errors?.lopDays)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      handleUpdatedFields('lopDays', event.target.value);
                    }}
                    value={formOne.values.lopDays}
                    type={'number'}
                    sx={{ width: '240px' }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={2}
                flexWrap={'wrap'}
              >
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>First Name*</label>
                  <TextField
                    id="first-name"
                    name="firstName"
                    size="small"
                    helperText={
                      formOne?.touched?.firstName && formOne?.errors?.firstName
                    }
                    error={
                      formOne?.touched?.firstName &&
                      Boolean(formOne?.errors?.firstName)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      handleUpdatedFields('firstName', event.target.value);
                    }}
                    value={formOne.values.firstName}
                    sx={{ width: '210px' }}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Last Name*</label>
                  <TextField
                    size="small"
                    id="last-name"
                    name="lastName"
                    helperText={
                      formOne?.touched?.lastName && formOne?.errors?.lastName
                    }
                    error={
                      formOne?.touched?.lastName &&
                      Boolean(formOne?.errors?.lastName)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      handleUpdatedFields('lastName', event.target.value);
                    }}
                    value={formOne.values.lastName}
                    sx={{ width: '210px' }}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{
                        color: 'black',
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                      }}
                    >
                      Gender *
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="gender"
                      id="gender-radio"
                      onBlur={formOne.handleBlur}
                      onChange={formOne.handleChange}
                      value={formOne.values.gender}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          height: 15,
                          width: 15,
                        },
                      }}
                    >
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label={
                          <Typography
                            sx={{
                              fontSize: '16px',
                            }}
                          >
                            Male
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        label={
                          <Typography
                            sx={{
                              fontSize: '16px',
                            }}
                          >
                            Female
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label={
                          <Typography
                            sx={{
                              fontSize: '16px',
                            }}
                          >
                            Other
                          </Typography>
                        }
                      />
                    </RadioGroup>
                    <FormHelperText sx={{ color: 'red' }}>
                      {formOne?.touched?.gender && formOne?.errors?.gender}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box mt={1} display={'flex'} flexDirection={'row'} gap={2}>
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Phone*</label>
                  <PhoneInput
                    inputClass={classes.phoneInputStyles}
                    country={'in'}
                    onlyCountries={['in']}
                    inputProps={{
                      id: 'phone-number',
                      name: 'phoneNumber',
                    }}
                    onBlur={formOne.handleBlur}
                    onChange={(value, country) => {
                      handlePhoneChange(value, country);
                      formOne.handleChange({
                        target: {
                          name: 'phoneNumber',
                          value: value,
                        },
                      });
                    }}
                    isValid={!formOne?.errors?.phoneNumber}
                    value={formOne?.values?.phoneNumber}
                  />
                  {formOne?.errors?.phoneNumber && (
                    <div className={classes.errorText}>
                      {formOne?.errors?.phoneNumber}
                    </div>
                  )}
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>
                    DOB (as per Certificate)*
                  </label>
                  <CustomDatePicker
                    value={formOne.values.dobAsPerCertificate}
                    handleDateChange={(dateString) => {
                      formOne.setFieldValue(
                        'dobAsPerCertificate',
                        dateString,
                        true
                      );
                      handleUpdatedFields('dobAsPerCertificate', dateString);
                    }}
                    errorText={
                      formOne?.touched?.dobAsPerCertificate &&
                      formOne?.errors?.dobAsPerCertificate
                    }
                    isError={
                      formOne?.touched?.dobAsPerCertificate &&
                      Boolean(formOne?.errors?.dobAsPerCertificate)
                    }
                    textFieldDisabled={false}
                    datePickerdisabled={false}
                    padding={'17.9px 14px'}
                    rest={{}}
                  />
                </Box>

                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Blood Group *</label>
                  <FormControl sx={{ minWidth: 190 }} size="small">
                    <Select
                      labelId="blood-group"
                      id="bloodGroup"
                      value={formOne.values.bloodGroup}
                      onChange={(e) => {
                        formOne.setFieldValue(
                          'bloodGroup',
                          e.target.value,
                          true
                        );
                      }}
                      error={
                        formOne?.touched?.bloodGroup &&
                        Boolean(formOne?.errors?.bloodGroup)
                      }
                    >
                      {bloodTypes?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item} <BloodGroup className={classes.menuItem} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className={classes.helperText}>
                      {formOne?.touched?.bloodGroup &&
                        formOne?.errors?.bloodGroup}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>
            </Grid>

            <Grid xs={12}>
              <Box mt={1} display={'flex'} flexDirection={'row'} gap={2}>
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Status*</label>
                  <FormControl sx={{ width: '200px' }} size="small">
                    <Select
                      labelId="status"
                      id="status-type"
                      disabled
                      value={formOne.values.status}
                      onChange={(e) => {
                        formOne.setFieldValue('status', e.target.value, true);
                      }}
                      error={
                        formOne?.touched?.status &&
                        Boolean(formOne?.errors?.status)
                      }
                    >
                      {StatusOptions.map((status) => (
                        <MenuItem key={status?.value} value={status?.value}>
                          {status?.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className={classes.helperText}>
                      {formOne?.touched?.status && formOne?.errors?.status}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>PAN Number *</label>
                  <TextField
                    id="pan-Number"
                    name="panNumber"
                    size="small"
                    helperText={
                      formOne?.touched?.panNumber && formOne?.errors?.panNumber
                    }
                    error={
                      formOne?.touched?.panNumber &&
                      Boolean(formOne?.errors?.panNumber)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.panNumber}
                    sx={{ width: '200px' }}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Aadhaar Number *</label>
                  <TextField
                    id="aadhar-Number"
                    name="aadharNumber"
                    size="small"
                    helperText={
                      formOne?.touched?.aadharNumber &&
                      formOne?.errors?.aadharNumber
                    }
                    error={
                      formOne?.touched?.aadharNumber &&
                      Boolean(formOne?.errors?.aadharNumber)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.aadharNumber}
                    sx={{ width: '300px' }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box className={classes.createEmployeeBtn}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#f15830',
                color: 'white',
              }}
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default PersonalForm;
