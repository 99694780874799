import { useFormik } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { Button, FormHelperText, TextField } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import DatePicker from 'react-datepicker';
import { getDate } from 'date-fns';
import CONSTANTS from '../../../constants/constants';
import moment from 'moment/moment';
import { getDaysCount } from '../../../services/commonHelperService';
import CompOffRequestDialog from './compOffRequestDialog';
import { leaveTypeEnums } from '../../uiElementsAccess';
import { ApplyCompOffRequest } from '../../../services/lmsService';
import { pushNotification } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import { ERROR_MESSAGES } from '../../../constants/common';

const CompOffRequestForm = (props) => {
  const { empId, closeModal } = props;
  const classes = useStyles();
  const initialFormValues = {
    empId: empId,
    startDate: null,
    endDate: null,
    numberOfDays: 0,
    reason: '',
  };

  const [finalFormData, setFinalFormData] = useState<any>(initialFormValues);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);
  const dispatch = useDispatch();

  const compOffRequestForm = useFormik({
    initialValues: initialFormValues,
    validationSchema: Yup.object({
      startDate: Yup.date()
        .typeError('')
        .required(ERROR_MESSAGES.REQUIRED_START_DATE)
        .nullable(),
      endDate: Yup.date()
        .typeError('')
        .required(ERROR_MESSAGES.REQUIRED_END_DATE)
        .nullable()
        .test(
          'endDate',
          ERROR_MESSAGES.END_DATE_BEFORE_START,
          function (endDate) {
            const startDate = this.resolve(Yup.ref('startDate'));
            if (!endDate || !startDate) return true; // Allow if endDate or startDate is not set
            return endDate >= startDate;
          }
        ),
      numberOfDays: Yup.number(),
      reason: Yup.string().required(ERROR_MESSAGES.REQUIRED_REASON),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(true);
    constructRequestBody(values);
  };

  const constructRequestBody = (values: any) => {
    const requestBody = {
      fromDate: moment(values?.startDate.toISOString()).format('YYYY-MM-DD'),
      toDate: moment(values?.endDate.toISOString()).format('YYYY-MM-DD'),
      employeeId: [compOffRequestForm?.values?.empId?.toString()],
      leaveCount: 0,
      reason: compOffRequestForm?.values?.reason,
      leaveType: leaveTypeEnums.COMP_OFF,
    };
    requestBody.leaveCount = getDaysCount(
      new Date(requestBody.fromDate),
      new Date(requestBody.toDate)
    );
    setFinalFormData(requestBody);
    setIsConfirmationDialogOpen(true);
  };

  const renderDayContents = (day, date) => {
    return <span>{getDate(date)}</span>;
  };

  const handleDateChange = async (fieldName, date) => {
    await compOffRequestForm.setFieldValue(fieldName, date);
  };

  const handleApply = async () => {
    setIsActionButtonDisabled(true);

    const response = await ApplyCompOffRequest(finalFormData);

    if (response.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      setIsConfirmationDialogOpen(false);
      closeModal();
      setIsActionButtonDisabled(false);
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
      setIsActionButtonDisabled(false);
    }
  };

  const handleClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const disableFutureDates = (date: any) => {
    return date <= new Date();
  };

  return (
    <>
      {isConfirmationDialogOpen ? (
        <CompOffRequestDialog
          onApply={handleApply}
          onClose={handleClose}
          requestData={finalFormData}
          isDialogOpen={isConfirmationDialogOpen}
          isActionButtonDisabled={isActionButtonDisabled}
        />
      ) : null}
      <form onSubmit={compOffRequestForm.handleSubmit}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          padding={'1%'}
          gap={2.5}
          sx={{
            '@media (min-width: 2000px)': {
              fontSize: 'calc(12px + 1.6rem)',
              fontWeight: 'bold',
            },
            '@media (min-width: 1500px) and (max-width: 2000px)': {
              fontSize: 'calc(12px + 0.8rem)',
              fontWeight: 'bold',
            },
            '@media (min-width: 1000px) and (max-width: 1500px)': {
              fontSize: 'calc(12px + 0.04rem)',
              fontWeight: 'bold',
            },
            '@media (min-width: 600px) and (max-width: 1000px)': {
              fontSize: 'calc(12px + 0.004rem)',
              fontWeight: 'bold',
            },
            '@media (max-width: 600px)': {
              fontSize: 'calc(12px + 0.0006rem) !important',
              fontWeight: 'bold !important',
            },
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            flexWrap={'wrap'}
          >
            <Box display={'flex'} flexDirection={'column'}>
              <label className="applyLeaveHeader">Emp ID</label>
              <TextField
                id="Emp-ID"
                name="empId"
                value={compOffRequestForm.values.empId}
                disabled
                InputProps={{
                  inputProps: {
                    className: classes.inputStyles,
                  },
                }}
                sx={{
                  width: '80px',
                  '@media (min-width: 2000px)': {
                    width: '120px !important',
                  },
                  '@media (min-width: 1500px) and (max-width: 2000px)': {
                    width: '100px !important',
                  },
                  '@media (min-width: 1000px) and (max-width: 1500px)': {
                    width: '80px !important',
                  },
                  '@media (min-width: 600px) and (max-width: 1000px)': {
                    width: '60px !important',
                  },
                  '@media (max-width: 600px)': {
                    width: '60px !important',
                  },
                }}
                onKeyDown={(e) => e.preventDefault()}
                // disabled
                size="small"
              />
            </Box>

            <Box display={'flex'} flexDirection={'column'}>
              <label className={classes.lmsLabel}>Select Date</label>

              <Box padding={'0 5px'}>
                <Box border={'1px solid grey'}>
                  <Box
                    padding={1}
                    display={'flex'}
                    flexDirection={'row'}
                    gap={2}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                    sx={{
                      '@media (max-width: 800px)': {
                        // Apply styles for screens up to 960px width
                        flexDirection: 'column',
                      },
                    }}
                  >
                    <Box display={'flex'} flexDirection={'column'}>
                      <DatePicker
                        id="datePicker-startDate"
                        className={classes.datePickerStyles}
                        selected={compOffRequestForm.values.startDate}
                        onChange={(date) => handleDateChange('startDate', date)}
                        placeholderText="Select Start Date"
                        onKeyDown={(e) => e.preventDefault()}
                        renderDayContents={renderDayContents}
                        filterDate={disableFutureDates}
                        autoComplete="off"
                      />
                    </Box>

                    <Box mt={0.5}>-</Box>
                    <Box display={'flex'} flexDirection={'column'}>
                      <DatePicker
                        id="datePicker-endDate"
                        className={classes.datePickerStyles}
                        required
                        selected={compOffRequestForm.values.endDate}
                        onChange={(date) => handleDateChange('endDate', date)}
                        placeholderText="Select End Date"
                        onKeyDown={(e) => e.preventDefault()}
                        renderDayContents={renderDayContents}
                        filterDate={disableFutureDates}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <FormHelperText sx={{ color: 'red' }}>
                    {compOffRequestForm.errors.startDate ||
                      compOffRequestForm.errors.endDate}
                  </FormHelperText>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <label
              style={{
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              May I know the Reason?
            </label>
            <TextField
              id="reason-leave"
              name="reason"
              helperText={
                compOffRequestForm.touched.reason &&
                compOffRequestForm.errors.reason
              }
              error={
                compOffRequestForm.touched.reason &&
                Boolean(compOffRequestForm.errors.reason)
              }
              onBlur={compOffRequestForm.handleBlur}
              onChange={compOffRequestForm.handleChange}
              value={compOffRequestForm.values.reason}
              multiline
              rows={3}
            />
          </Box>

          <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            <Box display={'flex'} flexDirection={'column'}>
              <Button
                className={classes.lmsSubmitBtn}
                variant="contained"
                type="submit"
                sx={{
                  '@media (min-width: 2000px)': {
                    fontSize: 'calc(12px + 1.6rem)',
                    width: '250px',
                  },
                  '@media (min-width: 1500px) and (max-width: 2000px)': {
                    fontSize: 'calc(12px + 0.8rem)',
                    width: '220px',
                  },
                  '@media (min-width: 1000px) and (max-width: 1500px)': {
                    fontSize: 'calc(12px + 0.04rem)',
                    width: '200px',
                  },
                  '@media (min-width: 600px) and (max-width: 1000px)': {
                    fontSize: 'calc(12px + 0.004rem)',
                  },
                  '@media (max-width: 600px)': {
                    fontSize: 'calc(12px + 0.0006rem)',
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CompOffRequestForm;

const useStyles = makeStyles(() => ({
  lmsLabel: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  lmsSubmitBtn: {
    backgroundColor: '#162952 !important',
    borderRadius: '35px !important',
    width: '137px',
  },
  inputStyles: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem) !important',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem) !important',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem) !important',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem) !important',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
    },
  },
  datePickerStyles: {
    height: '36px',
    border: '1px solid grey !important',
    borderRadius: '5px',
    '&::placeholder': {
      textAlign: 'center',
    },
    textAlign: 'center',
    '@media (min-width: 1441px)': {
      height: 'unset',
    },
  },
}));
