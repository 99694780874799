import React, { useState, useRef } from 'react';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CommonPopup from '../CommonPopup';

interface RedirectLink {
  name: string;
  url: string;
}

interface QuickAccessPopupProps {
  redirectLinks: RedirectLink[];
}

const QuickAccessPopupConstants = {
  TARGET: '_blank',
  REL: 'noopener noreferrer',
  URL_NOT_FOUND: 'URL not Found',
  CLOSE_QUICK_ACCESS: 'Close Quick Access',
  OPEN_QUICK_ACCESS: 'Open Quick Access',
  HEADING: 'This Feature is not available',
  CONTENT: 'Please Contact your Admin to know more.',
};

const useStyles = (isRotated: boolean) => {
  return makeStyles(() => {
    return {
      chatbot: {
        position: 'fixed' as const,
        right: '35px',
        bottom: '90px',
        width: '360px',
        background: '#fff',
        borderRadius: '15px',
        overflow: 'hidden',
        transformOrigin: 'bottom right',
        boxShadow:
          '0 0 128px 0 rgba(0, 0, 0, 0.1), 0 32px 64px -48px rgba(0, 0, 0, 0.5)',
        transition: 'all 0.3s ease',
      },
      chatbotHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '3rem',
        textAlign: 'center' as const,
        background: '#162952',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      },
      headerText: {
        color: '#fff',
        alignSelf: 'center',
      },
      chatbox: {
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        padding: '20px',
      },
      listItemContainer: {
        borderRadius: '20px !important',
        backgroundColor: '#162952 !important',
        display: 'flex !important',
        height: '40px !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        padding: '0 12px !important',
        '&:hover': {
          backgroundColor: '#243f78 !important',
        },
      },
      linkText: {
        margin: 0,
        textAlign: 'center',
        color: '#fff',
        textTransform: 'capitalize',
        fontWeight: 500,
      },
      toggleIcon: {
        color: '#fff',
        borderRadius: '50%',
        backgroundColor: '#162952',
        position: 'fixed' as const,
        right: '35px',
        bottom: '35px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '44px',
        height: '44px',
        transition: 'transform 0.3s ease',
        transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
      },
    };
  });
};

const QuickAccessPopup: React.FC<QuickAccessPopupProps> = ({
  redirectLinks,
}) => {
  const [showQuickAccessPopup, setShowQuickAccessPopup] =
    useState<boolean>(false);
  const [isRotated, setIsRotated] = useState<boolean>(false);
  const quickAccessRef = useRef<HTMLUListElement | null>(null);
  const [isShowCommonPopup, setIsShowCommonPopup] = useState(false);
  const styles = useStyles(isRotated)();

  const toggleQuickAccessPopup = () => {
    setShowQuickAccessPopup((prevState) => !prevState);
    setIsRotated((prev) => !prev);
  };

  const handleOnClick = (url: string) => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.target = QuickAccessPopupConstants.TARGET;
      link.rel = QuickAccessPopupConstants.REL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setIsShowCommonPopup(true);
    }
  };

  return (
    <>
      <CommonPopup
        isShowCommonPopup={isShowCommonPopup}
        setIsShowCommonPopup={setIsShowCommonPopup}
        heading={QuickAccessPopupConstants.HEADING}
        content={QuickAccessPopupConstants.CONTENT}
      />
      {showQuickAccessPopup && (
        <Box className={styles.chatbot}>
          <Box className={styles.chatbotHeader}>
            <Typography className={styles.headerText}>Quick Access</Typography>
          </Box>
          <Box className={styles.chatbox} ref={quickAccessRef}>
            {redirectLinks?.map((link, index) => (
              <Button
                variant="contained"
                size="small"
                className={styles.listItemContainer}
                onClick={() => handleOnClick(link.url)}
                key={index}
              >
                <p className={styles.linkText}>{link.name}</p>
              </Button>
            ))}
          </Box>
        </Box>
      )}
      <Box className={styles.toggleIcon} onClick={toggleQuickAccessPopup}>
        {showQuickAccessPopup ? (
          <Tooltip
            title={QuickAccessPopupConstants.CLOSE_QUICK_ACCESS}
            arrow
            enterDelay={1200}
          >
            <CloseIcon />
          </Tooltip>
        ) : (
          <Tooltip
            title={QuickAccessPopupConstants.OPEN_QUICK_ACCESS}
            arrow
            enterDelay={1200}
          >
            <ElectricBoltIcon />
          </Tooltip>
        )}
      </Box>
    </>
  );
};

export default QuickAccessPopup;
