import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { Divider, Typography } from '@mui/material';
import { FilterFormDetailsWithOfficalEmail } from '../../../services/formService';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { SUMMARY_FORM_ID } from '../../../constants/formConstants';
import { EmployeeTenureCount } from '../../../services/commonHelperService';

const useStyles = makeStyles(() => ({
  dialogBox1: {
    wordBreak: 'break-word',
    width: '160px',
    marginRight: '20px',
    fontWeight: 'bold',
    color: 'black',
    whiteSpace: 'nowrap',
  },
  dialogBox2: {
    wordBreak: 'break-word',
    width: '200px',
    marginLeft: '30px',
  },
}));

const style = {
  boxStyles: {
    containerMain: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '90%',
      minWidth: '700px',
      bgcolor: '#fff',
      boxShadow: 24,

      display: 'block',
      // border: "1px solid #f15830",
      borderRadius: '18px',
    },
    contentMain: {
      backgroundColor: '#f8e7e3',
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '3px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '24px',
        background:
          'linear-gradient(0deg, rgba(214, 95, 52, 0.596) 0%, rgba(0, 0, 0, 0.342) 100%);',
        opacity: '0.5',
      },
    },
  },

  typography: {
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
    },
  },

  icons: {
    closeIcon: {
      position: 'absolute',
      right: '4px',
      fontSize: '28px',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0 0 4px black',
        transition: 'all 0.3s',
      },
      borderRadius: '50%',
      transition: 'all 0.3s',
    },
  },
  dividers: {
    headingDivider: {
      background:
        'linear-gradient(90deg, rgba(255, 92, 33, 0.795) 0%, rgba(172, 172, 172, 0.603) 100%);',
      marginBottom: '12px',
      marginTop: '6px',
      height: '1px',
      width: '60%',
      borderRadius: '20%',
      opacity: '0.5',
    },
  },
};

const ViewEmployeeModal = (props) => {
  const { show, setShow } = props;
  const [linkedin, setLinkedin] = useState<any>('');
  const { userData } = useSelector((state: any) => ({
    userData: state.userData,
  }));

  const classes = useStyles();

  const getRowWithTitleAndData = (title: any, value: any) => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={1}
        justifyContent={'space-around'}
      >
        <Box className={classes.dialogBox1}>{title}</Box> :
        <Box flexGrow={1} textAlign={'justify'} className={classes.dialogBox2}>
          {value}
        </Box>
      </Box>
    );
  };

  const handleLinkedin = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      SUMMARY_FORM_ID,
      userData?.officialEmail
    );
    if (response.success && response?.data?.content?.length > 0) {
      setLinkedin(response?.data?.content[0]?.formData?.linkedinUrl);
    } else {
      setLinkedin('NA');
    }
  };

  useEffect(() => {
    handleLinkedin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <Modal
      open={show}
      onClose={() => {
        setShow(false);
        setLinkedin('NA');
      }}
    >
      <Box sx={style.boxStyles.containerMain}>
        <Box padding={2} minHeight={350}>
          <Box>
            <Typography
              variant="h5"
              color={'#f15830'}
              width="100%"
              sx={style.typography.heading}
            >
              <CloseOutlinedIcon
                sx={style.icons.closeIcon}
                onClick={() => {
                  setShow(false);
                  setLinkedin('NA');
                }}
              />
              Employee Details
            </Typography>
            <Divider sx={style.dividers.headingDivider} />

            <Box
              display={'flex'}
              flexDirection={'column'}
              mt={1}
              gap={2}
              overflow={'auto'}
              maxHeight={550}
              sx={style.boxStyles.contentMain}
              padding="16px"
            >
              {getRowWithTitleAndData(
                'Name',
                `${userData?.firstName} ${userData?.lastName}`
              )}
              {getRowWithTitleAndData('Designation', userData?.designation)}
              {getRowWithTitleAndData(
                'Employee Tenure',
                `${
                  EmployeeTenureCount(
                    userData?.dateOfJoining,
                    userData?.lwd ? userData.lwd : null
                  ).years
                } Years ${
                  EmployeeTenureCount(
                    userData?.dateOfJoining,
                    userData?.lwd ? userData.lwd : null
                  ).months
                } Months ${
                  EmployeeTenureCount(
                    userData?.dateOfJoining,
                    userData?.lwd ? userData.lwd : null
                  ).days
                } Days`
              )}
              {getRowWithTitleAndData(
                'Last Working Day',
                userData?.lwd ? userData?.lwd : 'NA'
              )}
              {getRowWithTitleAndData(
                'Departure Reason',
                userData?.departureReason ? userData?.departureReason : 'NA'
              )}
              {getRowWithTitleAndData(
                'Performance Summary',
                userData?.performanceSummary
                  ? userData?.performanceSummary
                  : 'NA'
              )}
              {getRowWithTitleAndData(
                'Departure Strengths',
                userData?.departureStrengths
                  ? userData?.departureStrengths
                  : 'NA'
              )}
              {getRowWithTitleAndData(
                'Areas Improvement',
                userData?.areasImprovement ? userData?.areasImprovement : 'NA'
              )}
              {getRowWithTitleAndData(
                'Additional Comments',
                userData?.additionalComments
                  ? userData?.additionalComments
                  : 'NA'
              )}

              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-around'}
              >
                <Box className={classes.dialogBox1}>Linkedin</Box> :
                <Box
                  flexGrow={1}
                  textAlign={'justify'}
                  className={classes.dialogBox2}
                >
                  {linkedin !== 'NA' ? (
                    <a
                      href={linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {linkedin}
                    </a>
                  ) : (
                    linkedin
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default ViewEmployeeModal;
