import { request, ResponseProps } from '../services/request';
import {} from '../constants/constants';
import axios from 'axios';
import { getSessionStorageItem } from '../encrypt';
import {
  DELETE_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  COMMON_BACKEND_ENDPOINT,
  EMPLOYEE_LIST_DOWNLOAD_PATH,
  EMPLOYEE_LIST_UPLOAD_PATH,
  UPLOAD_HOLIDAYS_PATH,
} from '../constants/endpoints';

export const uploadDocument = async (url, data) => {
  const r: ResponseProps = (await request.postForm(
    `${getSessionStorageItem('gatewayURL')}${url}`,
    data
  )) as ResponseProps;

  if (r.success) {
    return { success: r.success, message: r.message, data: r.data };
  }

  return { success: false };
};

export const deleteDocument = async (id) => {
  const r: ResponseProps = (await request.delete(
    `${getSessionStorageItem('gatewayURL')}${DELETE_DOCUMENT}${id}`
  )) as ResponseProps;

  if (r.success) {
    return { success: r.success, message: r.message, data: r.data };
  }

  return { success: false };
};

export const downloadDocument = async (id: string) => {
  const token = getSessionStorageItem('react-token');

  const response = await axios.get(
    `${getSessionStorageItem('gatewayURL')}${DOWNLOAD_DOCUMENT}${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,

        responseType: 'blob', // VERY IMPORTANT 'arrayBuffer'
      },
    }
  );

  return response;
};

export const openFile = async (id: string) => {
  const Token = getSessionStorageItem('react-token');
  return fetch(
    `${getSessionStorageItem('gatewayURL')}${DOWNLOAD_DOCUMENT}${id}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: `Bearer ${Token}`,
      }),
    }
  )
    .then((res) => res.blob())
    .then((blobData) => {
      const fileURL = URL.createObjectURL(blobData);
      return fileURL;
    });
};

export const downloadFile = async (id: string, fileName: string) => {
  const Token = getSessionStorageItem('react-token');
  fetch(`${getSessionStorageItem('gatewayURL')}${DOWNLOAD_DOCUMENT}${id}`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${Token}`,
    }),
  })
    .then((res) => res.blob())
    .then((blobData) => {
      const fileURL = URL.createObjectURL(blobData);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', fileName); // or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    });
};

export const bulkUpload = async (file) => {
  const formData = new FormData();

  formData.append('file', file);

  formData.append('documentName', file.name);

  return axios.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}${EMPLOYEE_LIST_UPLOAD_PATH}`,
    formData,
    {
      responseType: 'blob',

      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: `Bearer ${getSessionStorageItem('react-token')}`,
      },
    }
  );
};

export const bulkDownload = async () => {
  try {
    const Token = getSessionStorageItem('react-token');
    const response = await fetch(
      `${getSessionStorageItem(
        'gatewayURL'
      )}${COMMON_BACKEND_ENDPOINT}${EMPLOYEE_LIST_DOWNLOAD_PATH}`,
      {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${Token}`,
        }),
      }
    );
    const blobData = await response.blob();
    const fileURL = URL.createObjectURL(blobData);
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', 'BulkDownload.csv'); // or any other extension
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(fileURL);
    return {
      success: true,
      message: 'Master Data has been downloaded successfully !',
    };
  } catch (error) {
    return { success: false, message: 'Master Data Download Failed' };
  }
};

export const HolidaysUpload = async (file) => {
  const formData = new FormData();

  formData.append('file', file);

  return axios.post(
    `${getSessionStorageItem(
      'gatewayURL'
    )}${COMMON_BACKEND_ENDPOINT}${UPLOAD_HOLIDAYS_PATH}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: `Bearer ${getSessionStorageItem('react-token')}`,
      },
    }
  );
};
