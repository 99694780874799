import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import 'react-circular-progressbar/dist/styles.css';

import '../../home.css';
import {
  ASSETS_FORM_ID,
  GENERAL_FORM_ID,
  PERSONAL_FORM_ID,
  SUMMARY_FORM_ID,
} from '../../../constants/formConstants';
import { FilterFormDetailsWithOfficalEmail } from '../../../services/formService';
import { getFullName } from '../../../services/commonHelperService';
import HobbiesCard from '../../HobbiesCard';
import ProfileDetails from '../../LeaveManagementSystem/ProfileDetails/profileDetails';
import UserOverview from '../../LeaveManagementSystem/UserOverview';
import SummaryCard from '../../SummaryCard';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CONSTANTS from '../../../constants/constants';

interface QuickViewModelProps {
  userData: any;
  setModalShow: any;
}


const style = {
  containerMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem',
  },
  typography: {
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      margin: '0px 0px 12px 8px',
    },
  },

  icons: {
    closeIcon: {
      color: '#f15830 !important',
      position: 'absolute',
      right: '8px',
      fontSize: '28px',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0 0 4px black',
        transition: 'all 0.3s',
      },
      borderRadius: '50%',
      transition: 'all 0.3s',
    },
  },
  dividerHorizontal: {
    marginTop: '8px',
  },
  dividerVertical: {
    margin: '10px',
  },
};

const QuickViewModel = ({ userData, setModalShow }: QuickViewModelProps) => {
  const [profileDetails, setProfileDetails] = useState<any>({});
  const [assetsData, setAssetsData] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [linkedin, setLinkedin] = useState<any>('');
  useState(false);
  const [refresh] = useState(false);

  useEffect(() => {
    fetchSummaryDetail(SUMMARY_FORM_ID, userData?.officialEmail);
    fetchOtherDetails(GENERAL_FORM_ID, userData?.officialEmail);
    fetchAssetsDetail(ASSETS_FORM_ID, userData?.officialEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const fetchSummaryDetail = async (
    formId: string,
    emailId: any
  ): Promise<{ success: boolean; data?: any; message?: string }> => {
    const response = await FilterFormDetailsWithOfficalEmail(formId, emailId);
    if (response?.success && response?.data?.content?.length > 0) {
      const linkedinUrl = Object.prototype.hasOwnProperty.call(
        response?.data?.content?.[0]?.formData,
        'linkedinUrl'
      )
        ? response?.data?.content?.[0]?.formData?.linkedinUrl
        : '';
      const urlParts = linkedinUrl?.split('linkedin.com');
      setLinkedin(urlParts?.[urlParts[1] ? 1 : 0]);
    } else {
      setLinkedin('');
    }
    return { success: false };
  };

  const fetchOtherDetails = async (_formId, email) => {
    setIsLoading(true);

    const personalInfoResponse = await FilterFormDetailsWithOfficalEmail(
      PERSONAL_FORM_ID,
      email
    );

    let result1 = {};
    let result2 = {};
    if (userData) {
      setIsLoading(false);
      const profilePicUrl = userData?.profilePicUrl;

      result1 = {
        profileImage: profilePicUrl
          ? `data:image/jpeg;base64,${profilePicUrl}`
          : CONSTANTS.DEFAULT_USER_IMAGE,

        reportingTo: userData?.reportingTo || '...',
        empId: userData?.empId || '...',
        role: userData?.designation || '...',
        dob: userData?.dob || '...',
        department: userData?.department || '...',
        fullName:
          `${getFullName([userData?.firstName, userData?.lastName])}` || '...',
        firstName: userData?.firstName || '...',
        liveLocation:
          userData?.liveLocation !== undefined
            ? userData?.liveLocation
            : 'No Location found',
        lastName: userData?.lastName || '...',
        bloodGroup: userData?.bloodGroup || '...',
        emailId: userData?.officialEmail || '...',
        phone: `${userData?.phone || '...'}`,
        dateOfJoining: userData?.dateOfJoining || '...',
      };
    } else {
      setIsLoading(false);
      result1 = {
        profileImage: CONSTANTS.DEFAULT_USER_IMAGE,
        reportingTo: '...',
        empId: '...',
        role: '...',
        dob: '...',
        fullName: '...',
        firstName: '...',
        lastName: '...',
        bloodGroup: '...',
        emailId: '...',
        phone: '...',
        department: '...',
      };
    }

    if (personalInfoResponse?.success) {
      setIsLoading(false);
      const mapData2 = personalInfoResponse?.data?.content?.[0]?.formData;

      result2 = {
        maritalStatus: mapData2?.family?.marriedStatus
          ? mapData2.family.marriedStatus.charAt(0).toUpperCase() +
            mapData2.family.marriedStatus.slice(1).toLowerCase()
          : '...',

        location: mapData2?.currentAddress || '...',

        emergencyNo: `${
          mapData2?.emergencyDetails?.emergencyDetails1?.phone || '...'
        }`,
      };
    } else {
      setIsLoading(false);
      result2 = {
        maritalStatus: '...',
        location: '...',
        emergencyNo: '...',
        department: '...',
      };
    }

    setIsLoading(false);
    setProfileDetails({ ...result1, ...result2 });
  };

  const fetchAssetsDetail = async (formId, email) => {
    const response: any = await FilterFormDetailsWithOfficalEmail(
      formId,
      email
    );

    if (response?.success && response?.data?.content?.length > 0) {
      setAssetsData(response?.data?.content?.[0]?.formData?.assets);
    } else {
      setAssetsData([]);
    }
  };

  const handleLinkedinData = (e) => {
    setLinkedin(e.target.value);
  };

  return (
    <Box sx={style.containerMain}>
      <Box>
        <Typography
          variant="h5"
          color={'#162952'}
          width="100%"
          sx={style.typography.heading}
        >
          <CloseOutlinedIcon
            sx={style.icons.closeIcon}
            onClick={() => {
              setModalShow(false);
            }}
          />
          Employee Details
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        className={'home-grid-container home-grid-container-quick-view'}
      >
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <ProfileDetails
            profileDetails={profileDetails}
            isLoading={isLoading}
            isEmployeeModalOpen={show}
            setIsEmployeeModalOpen={setShow}
            menuAnchorElement={null}
            handleLinkedinData={handleLinkedinData}
            linkedinUrl={linkedin}
            isQuickView={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={8} xl={9}>
          <div className="withoutProfileCard">
            <UserOverview
              userEmail={userData?.officialEmail}
              updated={refresh}
              isQuickView={true}
            />

            {assetsData?.length > 0 && assetsData?.[0]?.serialNumber !== '' && (
              <div className="assets">
                <p className="summaryTitleAsset">Assets</p>

                {assetsData?.length > 0 ? (
                  <div className="summaryContents">
                    {assetsData?.map((item, index) => {
                      return (
                        <p className="summaryContent" key={item.id || index}>
                          {`${index + 1}.`} {item?.asset} | {item?.brand} |{' '}
                          {item?.operatingSystem} | {item?.serialNumber}
                          {assetsData?.length > index + 1 ? (
                            <Divider style={style.dividerHorizontal}></Divider>
                          ) : null}
                        </p>
                      );
                    })}
                  </div>
                ) : (
                  <p>No Asset Found</p>
                )}
              </div>
            )}

            <div className="assets summaryDiv">
              <SummaryCard
                isQuickView={true}
                userEmail={userData?.officialEmail}
              />

              <Divider
                orientation="vertical"
                flexItem
                style={style.dividerVertical}
              />

              <HobbiesCard
                isQuickView={true}
                userEmail={userData?.officialEmail}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuickViewModel;
