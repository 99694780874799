import './myCompOffs.css';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { ChangeCompOffStatus } from '../../../services/lmsService';
import { MY_COMP_OFFS_FORM_ID } from '../../../constants/formConstants';
import { pushNotification } from '../../../redux/actions';
import CONSTANTS from '../../../constants/constants';
import { Box, Button, Typography } from '@mui/material';
import { FilterFormDetailsWithEmployeeId } from '../../../services/formService';
import { capitalize, formatDate } from '../../../services/commonHelperService';
import ConfirmationModal from '../../../components/common/ConfirmationModal/ConfirmationModal';
import ApplyLeaveModal from '../applyLeaveModal';
import { leaveTypeEnums } from '../../uiElementsAccess';
import { GridColDef } from '@mui/x-data-grid';
import TsDatagrid from '../../../components/TsDatagrid';
import dayjs from 'dayjs';

const MyCompOffs = ({ empId }) => {
  const dispatch = useDispatch();
  const [selectedRowId, setSelectedRowId] = useState('');
  const [isCancelButtonDisabled, setIsCancelButtonDisabled] = useState(false);
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
    useState(false);

  const [compOffList, setCompOffList] = useState([]);
  const [isApplyLeaveModalOpen, setIsApplyLeaveModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = (id) => {
    setSelectedRowId(id);
    setIsCancelConfirmationModalOpen(true);
  };
  const handleClose = () => {
    setIsCancelConfirmationModalOpen(false);
    setSelectedRowId('');
  };

  useEffect(() => {
    fetchCompOffs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isApplyLeaveModalOpen) {
      fetchCompOffs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplyLeaveModalOpen]);

  const fetchCompOffs = async () => {
    setIsLoading(true);
    const response: any = await FilterFormDetailsWithEmployeeId(
      MY_COMP_OFFS_FORM_ID,
      empId
    );
    // setCompOffList(response?.data?.content);
    if (response.success) {
      const finalData = response?.data?.content?.map((item) => {
        return {
          id: item?.id,
          fromDate: item?.formData?.fromDate,
          toDate: item?.formData?.toDate,
          totalDays: item?.formData?.totalCompOffCount,
          status: item?.formData?.status,
          comments: item?.formData?.reason,
          consumed: item?.formData?.consumedCount,
          expired: item?.formData?.expiredCount,
          appliedOn: item?.createdOn,
          approvedOn:
            Boolean(item?.formData?.approvedOn) &&
            item?.formData?.approvedOn !== ''
              ? item?.formData?.approvedOn
              : '-',
        };
      });
      setCompOffList(finalData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleCancelLeave = async (id) => {
    setIsCancelButtonDisabled(true);
    const response = await ChangeCompOffStatus(id, 'cancel');
    if (response.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      setIsCancelConfirmationModalOpen(false);
      setIsCancelButtonDisabled(false);
      setSelectedRowId('');
      await fetchCompOffs();
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
      setIsCancelButtonDisabled(false);
      setSelectedRowId('');
    }
  };

  const showApplyCompOff = (data) => {
    const available = data.totalDays - data.consumed - data.expired;
    return (
      (data.status === 'approved' || data.status === 'Active') && available > 0
    );
  };

  const isCancelDisabled = (compOffListItem) => {
    return (
      compOffListItem.status === 'cancelled' ||
      compOffListItem.status === 'approved' ||
      compOffListItem.status === 'rejected'
    );
  };

  const openApplyLeaveModal = (id) => {
    setSelectedRowId(id);
    setIsApplyLeaveModalOpen(true);
  };

  const getTableHeader = (title) => {
    return (
      <Typography sx={dataGridStyles.tableHeaderStyle}>{title}</Typography>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'fromDate',
      headerName: 'From',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => formatDate(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'toDate',
      headerName: 'To',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => formatDate(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'totalDays',
      headerName: 'No.of.days',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'consumed',
      headerName: 'Consumed',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'appliedOn',
      headerName: 'Applied On',
      editable: false,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueFormatter: (row: any) => {
        const value = dayjs(new Date(row?.value))
          .format('DD/MM/YYYY - hh:mm a')
          .replace('am', 'AM')
          .replace('pm', 'PM');
        if (value === 'Invalid Date') return '-';
        else return value;
      },
      flex: 1,
    },
    {
      field: 'approvedOn',
      headerName: 'Approved on',
      minWidth: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      // valueFormatter: (row: any) => {
      //   const value = dayjs(new Date(row?.value)).format("DD/MM/YYYY");
      //   if (value === "Invalid Date") return "-";
      //   else return value;
      // },
      flex: 1,
    },
    {
      field: 'expired',
      headerName: 'Expired',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => capitalize(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'comments',
      headerName: 'Comments',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      editable: false,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (props) => {
        // const id = props.id;
        const compOffListItem = props?.row;
        return (
          <Box gap={1} sx={actionColumnStyles.container}>
            {showApplyCompOff(compOffListItem) ? (
              <Button
                variant="outlined"
                size="small"
                className={'action-button-class apply-comp-off-button'}
                onClick={() => openApplyLeaveModal(compOffListItem.id)}
                sx={actionColumnStyles.buttonApply}
              >
                Apply Comp Off
              </Button>
            ) : null}

            {compOffListItem?.status ? (
              <Button
                variant="contained"
                size="small"
                className={'action-button-class cancel-button'}
                onClick={() => handleOpen(compOffListItem.id)}
                disabled={isCancelDisabled(compOffListItem)}
                sx={actionColumnStyles.buttonCancel}
              >
                Cancel
              </Button>
            ) : null}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {isCancelConfirmationModalOpen && (
        <ConfirmationModal
          id="confirmationModal"
          title="Cancel Comp Off"
          message="Do you want to cancel this Comp Off?"
          open={isCancelConfirmationModalOpen}
          onClose={handleClose}
          onSubmit={() => handleCancelLeave(selectedRowId)}
          isSubmitButtonDisabled={isCancelButtonDisabled}
        />
      )}

      {isApplyLeaveModalOpen ? (
        <ApplyLeaveModal
          show={isApplyLeaveModalOpen}
          setShow={setIsApplyLeaveModalOpen}
          leaveChoice={leaveTypeEnums.COMP_OFF}
          compOffId={selectedRowId}
        />
      ) : null}

      <div>
        <Box className="myLeavescontainer">
          <TsDatagrid
            rows={compOffList}
            columns={columns}
            pageSize={10}
            pageSizeArray={[10, 20, 30]}
            getSelectedRowsData={() => {
              // TODO: Implement function
            }}
            handlePageChange={() => {
              // TODO: Implement function
            }}
            handlePageSizeChange={() => {
              // TODO: Implement function
            }}
            isCheckboxSelection={false}
            totalElements={compOffList.length}
            sx={dataGridStyles.tableStyle}
            components={{
              NoRowsOverlay: () => (
                <Box sx={dataGridStyles.overlayStyle}>
                  {isLoading ? ' Loading Leaves' : 'No Comp Offs'}
                </Box>
              ),
            }}
          />
        </Box>
      </div>
    </>
  );
};

export default MyCompOffs;

const actionColumnStyles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  buttonApply: {
    textTransform: 'capitalize',
  },
  buttonCancel: {
    textTransform: 'capitalize',
  },
};

const dataGridStyles = {
  tableStyle: {
    height: '500px',
  },
  overlayStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  tableHeaderStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
};
