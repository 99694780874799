import { getSessionStorageItem } from '../encrypt';

const KEYCLOAK_TOKEN_PATH = '/protocol/openid-connect/token';

export const TEMPLATE_UPLOAD =
  '/dms/v1/documents?documentTypeId=920997426618875904';
export const TEMPLATE_DOWNLOAD = '/dms/v1/documents';
export const GENERATE_OTP = '';
export const VERIFY_OTP = '';

export const KEYCLOAK_TOKEN =
  'auth/realms/' + getSessionStorageItem('realm') + KEYCLOAK_TOKEN_PATH;

export const EMPLOYEE_LIST_UPLOAD_PATH = '/upload?isCreatingUser=true';
export const EMPLOYEE_LIST_DOWNLOAD_PATH = '/employee-list/download';
export const UPLOAD_HOLIDAYS_PATH = '/uploadHolidays';
export const USER_INFO_PATH = '/protocol/openid-connect/userinfo';
export const USER_PREFERENCES_PATH = '/accounts/v1/users/preferences';

export const DELETE_DOCUMENT = '/dms/v1/documents?id=';
export const DOWNLOAD_DOCUMENT = '/dms/v1/documents/download?id=';

export const FORM_RUNTIME_BASE_PATH = '/form-runtime/v1';
export const FORM_ENDPOINT = '/form-modeler/v1/forms';
export const RUNTIME_FORM_ENDPOINT = '/form-runtime/v1/form-data';
export const GENERAL_FORM_ENDPOINT = '/emp360-backend/v1/generalFormSave';

export const DMS_ENDPOINT = '/dms/v1/document-types';
export const WORKFLOW_ENDPOINT = '/workflow/v1/process/start';
export const GETUSERS_ENDPOINT = '/accounts/v1/users';
export const USER_PREFERENCE = '/accounts/v1/users/preferences';
export const MENU = '/accounts/v1/roles/menu';
export const MENU_ROLE = '/accounts/v1/menurole';
export const ALL_MENUS = '/accounts/v1/menus';

export const CREATE_VISITOR_END_POINT = '/visitors/create';
export const FILTER_VISITORS_END_POINT = '/visitors/filter';
export const CHECK_IN_VISITORS_END_POINT = '/visitors/checkin';
export const CHECK_OUT_VISITORS_END_POINT = '/visitors/checkout';
export const APPROVE_REJECT_VISITORS_END_POINT = '/visitors/approve-reject';
export const DOWNLOAD_ALL_VISITORS_END_POINT = '/visitors/download/all';
export const COMMON_BACKEND_ENDPOINT = '/emp360-backend/v1';
