import React from 'react';
import ContentLoader from 'react-content-loader';

interface PROPS {
  xDirection?: number;
  yDirection?: number;
  size?: number;
  viewbox?: string;
}

const CircularLoader = ({
  xDirection = 220,
  yDirection = 70,
  size = 65,
  viewbox = '0 0 500 140',
}: PROPS) => {
  return (
    <ContentLoader viewBox={viewbox}>
      {/* Only SVG shapes */}
      <circle cx={xDirection} cy={yDirection} r={size} />
    </ContentLoader>
  );
};

export default CircularLoader;
