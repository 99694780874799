import { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  commentsLengthMsg,
  descriptionRequiredMsg,
  effectiveDateRequiredMsg,
  grievanceRequiredMessage,
  preferredResolutionRequiredMessage,
} from '../../ModalViews/HrAdmin/forms/formConstants';

import { convertISOStringToDDMMYYYY } from '../../../utils/DateOperations';
import {
  getOrDefaultFormik,
  getOrDefaultRender,
  revertString,
} from '../utilFunctions/promotionUtils';
import {
  EmployeeGrievance,
  EventAccordionProps,
  EventTypes,
  fileTypes,
  FormikConstants,
  GrievanceTypes,
  UtilityConstants,
} from '../types/EventTypes';
import { useEventStyles } from '../styles';
import {
  AllButtonGroup,
  getDatePickerAsGridItem,
  getDocumentsFieldAsGridItem,
  getDropDownAsGridItem,
  getMultiLinedTextFieldAsGridItem,
  getRenderHeading,
} from './formUtils';

interface EventEmployeeGrievanceProps extends EventAccordionProps {
  event: EmployeeGrievance;
}

const EventEmployeeGrievance = ({
  event,
  onSave,
  updatePromotion,
  downloadFiles,
  deletePromotion,
  isAdmin,
}: EventEmployeeGrievanceProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [filesBuffer, setFilesBuffer] = useState<any[]>([]);
  const [currentAllDocs, setCurrentAllDocs] = useState<any[]>([]);
  const [currentDocsTempBuffer, setCurrentDocsTempBuffer] = useState<any[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [deletableBuffer, setDeletableBuffer] = useState<any[]>([]);
  const handleEdit = () => setIsEditing(!isEditing);
  const handleCancel = () => setIsEditing((prev) => !prev);

  const styles = useEventStyles(isEditing)();

  const initialValues = {
    grievanceType: event?.grievanceType,
    grievanceDescription: getOrDefaultFormik(event?.grievanceDescription),
    preferredResolution: getOrDefaultFormik(event?.preferredResolution),
    effectiveDate: event?.effectiveDate,
    comments: getOrDefaultFormik(event?.comments),
  };

  const formOne = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      grievanceType: Yup.string().required(grievanceRequiredMessage),
      grievanceDescription: Yup.string().required(descriptionRequiredMsg),
      preferredResolution: Yup.string().required(
        preferredResolutionRequiredMessage
      ),
      effectiveDate: Yup.string().required(effectiveDateRequiredMsg),
      comments: Yup.string().max(500, commentsLengthMsg),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const payload: any = {
        data: {
          ...values,
          id: event?.id !== undefined ? event?.id : -1,
          eventType: EventTypes?.GRIEVANCE,
        },
        filesBuffer: filesBuffer,
        deletableBuffer: deletableBuffer,
        supportingDocuments: event?.supportingDocuments || [],
      };
      if (event?.isNew || event?.id === -1) {
        delete payload?.deletableBuffer;
        onSave(payload);
      } else {
        updatePromotion(payload);
        setDeletableBuffer([]);
      }
      setSubmitting(false);
      setIsEditing(!isEditing);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (event?.referenceDocs?.length === 0) return;
    const tempBuffer =
      event?.referenceDocs?.map((mfile: any) => ({
        name: mfile?.fileName,
        id: mfile?.documentId,
        fileType: mfile?.fileType,
      })) || [];

    setCurrentDocsTempBuffer([...tempBuffer]);
  }, [event?.referenceDocs, event]);

  useEffect(() => {
    setCurrentAllDocs([...(event?.referenceDocs ? event?.referenceDocs : [])]);
    if (currentDocsTempBuffer.length !== event?.supportingDocuments?.length) {
      const currentIds = currentDocsTempBuffer?.map((file) => file?.id);
      const supportingDocuments = event?.supportingDocuments || [];
      const missingDocs = supportingDocuments
        ?.filter((docId) => !currentIds?.includes(docId))
        ?.map((docId) => ({
          id: docId,
          name: UtilityConstants?.FILE_MISSING,
          fileType: UtilityConstants?.UNKNOWN,
        }));

      currentDocsTempBuffer?.push(...missingDocs);
    }

    setFilesBuffer([...currentDocsTempBuffer]);
    setCurrentAllDocs([...currentDocsTempBuffer]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocsTempBuffer]);

  useEffect(() => {
    if (event?.isNew) setIsEditing(event?.isNew);
  }, [event]);

  const handleFieldChange = (field: any, value: any) => {
    formOne?.handleChange({
      target: {
        name: field,
        value: value,
      },
    });
  };

  const handleFileChange = async (file) => {
    setFilesBuffer([...filesBuffer, ...file]);
  };

  const deleteFromFilesBuffer = (file: any, i: any) => {
    const newFileBuffer = filesBuffer?.filter((_mfile, index) => index !== i);
    setFilesBuffer([...newFileBuffer]);

    if (file?.id) {
      setDeletableBuffer((prevBuffer) => [...prevBuffer, file]);
    }
  };

  const handleDeletePromotion = () => {
    const payload = {
      eventType: event?.eventType,
      eventId: event?.id,
    };
    deletePromotion(payload, event?.supportingDocuments, event?.isNew);
  };

  useEffect(() => {
    if (event?.isNew) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [event]);

  const handleAccordionChange = (_event, isExpanded) => {
    setExpanded(isExpanded);
  };
  return (
    <Accordion
      className={styles.main}
      expanded={expanded}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={styles.employeeGrievanceStyle}
      >
        <Grid container spacing={1.5}>
          {getRenderHeading(
            3.5,
            FormikConstants?.eventType?.LABEL1,
            revertString(getOrDefaultRender(event?.eventType)),
            styles
          )}
          {getRenderHeading(
            5,
            FormikConstants?.grievanceType?.LABEL,
            revertString(getOrDefaultRender(formOne?.values?.grievanceType)),
            styles
          )}
          {getRenderHeading(
            2,
            FormikConstants?.effectiveDate?.LABEL2,
            convertISOStringToDDMMYYYY(formOne?.values?.effectiveDate ?? ''),
            styles
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Paper
          elevation={1}
          className={`${styles.paper} ${styles.paperEditingModeBackground}`}
        >
          <Grid container spacing={2}>
            {getDropDownAsGridItem(
              4,
              isEditing,
              FormikConstants?.grievanceType?.LABEL,
              FormikConstants?.grievanceType?.ID,
              handleFieldChange,
              formOne?.values?.grievanceType,
              FormikConstants?.grievanceType?.DEFAULT_VALUE,
              GrievanceTypes,
              formOne?.touched?.grievanceType &&
                Boolean(formOne?.errors?.grievanceType),
              formOne?.touched?.grievanceType && formOne?.errors?.grievanceType,
              styles
            )}
            {getDatePickerAsGridItem(
              4,
              isEditing,
              FormikConstants?.effectiveDate?.LABEL2,
              FormikConstants?.effectiveDate?.ID,
              handleFieldChange,
              formOne?.values?.effectiveDate,
              formOne?.values?.effectiveDate,
              formOne?.touched?.effectiveDate &&
                Boolean(formOne?.errors?.effectiveDate),
              formOne?.touched?.effectiveDate && formOne?.errors?.effectiveDate,
              styles
            )}
            {getMultiLinedTextFieldAsGridItem(
              12,
              FormikConstants?.grievanceDescription?.LABEL,
              FormikConstants?.grievanceDescription?.ID,
              handleFieldChange,
              formOne?.values?.grievanceDescription,
              isEditing,
              formOne?.touched?.grievanceDescription &&
                Boolean(formOne?.errors?.grievanceDescription),
              formOne?.touched?.grievanceDescription &&
                formOne?.errors?.grievanceDescription,
              styles
            )}
            {getMultiLinedTextFieldAsGridItem(
              12,
              FormikConstants?.preferredResolution?.LABEL,
              FormikConstants?.preferredResolution?.ID,
              handleFieldChange,
              formOne?.values?.preferredResolution,
              isEditing,
              formOne?.touched?.preferredResolution &&
                Boolean(formOne?.errors?.preferredResolution),
              formOne?.touched?.preferredResolution &&
                formOne?.errors?.preferredResolution,
              styles
            )}
            {getMultiLinedTextFieldAsGridItem(
              12,
              FormikConstants?.comments?.LABEL,
              FormikConstants?.comments?.ID,
              handleFieldChange,
              formOne?.values?.comments,
              isEditing,
              formOne?.touched?.comments && Boolean(formOne?.errors?.comments),
              formOne?.touched?.comments && formOne?.errors?.comments,
              styles
            )}
            {getDocumentsFieldAsGridItem(
              FormikConstants?.supportingDocuments?.LABEL,
              styles,
              isEditing,
              handleFileChange,
              fileTypes,
              currentAllDocs,
              downloadFiles,
              filesBuffer,
              deleteFromFilesBuffer
            )}
          </Grid>
        </Paper>

        {isAdmin && (
          <AllButtonGroup
            isEditing={isEditing}
            event={event}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            handleDelete={handleDeletePromotion}
            formOne={formOne}
            styles={styles}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default EventEmployeeGrievance;
