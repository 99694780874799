import wretch from 'wretch';
import { ResponseChain } from 'wretch/dist/resolver';
import CONSTANTS from '../constants/constants';
import { getSessionStorageItem } from '../encrypt';

export interface ResponseProps {
  success?: boolean;
  data?: unknown;
  message?: string;
}

type ApiResponse = unknown;

const callAPI = async (w: ResponseChain): Promise<ApiResponse> => {
  return w
    .unauthorized(() => {
      window.location.reload();
      return {
        success: false,
        message: 'Please login...',
      };
    })
    .internalError((error) => {
      const { message } = JSON.parse(error.message);
      return {
        success: false,
        message: message,
      };
    })
    .json((response) => response)
    .catch((error) => {
      console.error(error);
      return {
        success: false,
        message: 'Error communicating with server',
      };
    });
};

const callBlobAPI = async (wr: ResponseChain): Promise<ApiResponse> => {
  return wr
    .unauthorized(() => {
      window.location.reload();
      return {
        success: false,
        message: 'Please login again...',
      };
    })
    .blob((response) => {
      return { success: true, data: response };
    })
    .catch((_error) => {
      console.error(_error);
      return {
        success: false,
        message: 'Error communicating with server',
      };
    });
};

export interface ResponseProps {
  success?: boolean;
  data?: unknown;
  message?: string;
}

export const request = {
  get: (url: string): Promise<ApiResponse> => {
    return callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .headers({ 'content-type': 'application/json' })
        .get()
    );
  },
  getBlob: (url: string): Promise<ApiResponse> =>
    callBlobAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .get()
    ),

  post: (url: string, body: unknown): Promise<ApiResponse> =>
    callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .post(body)
    ),
  put: (url: string, body: unknown): Promise<ApiResponse> =>
    callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .put(body)
    ),
  delete: (url: string): Promise<ApiResponse> =>
    callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .delete()
    ),
  postFormForToken: (
    url: string,
    params: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    controller?: AbortController
  ): Promise<ApiResponse> =>
    callAPI(
      wretch(url)
        .headers({ 'content-type': 'application/x-www-form-urlencoded' })
        .post(params)
    ),
  postForm: (
    url: string,
    params: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    controller?: AbortController
  ): Promise<ApiResponse> =>
    callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .formData(params)
        .post()
    ),
};
