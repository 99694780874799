import { iSwipeData } from '../types/types';

const rows: Array<iSwipeData> = [
  {
    date: '1st Jan',
    day: 'Sunday',
    project: 'Project 1',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '2nd Jan',
    day: 'Monday',
    project: 'Project 2',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '3rd Jan',
    day: 'Tuesday',
    project: 'Project 3',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '4th Jan',
    day: 'Wednesday',
    project: 'Project 4',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '5th Jan',
    day: 'Thursday',
    project: 'Project 5',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '6th Jan',
    day: 'Friday',
    project: 'Project 6',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '7th Jan',
    day: 'Saturday',
    project: 'Project 7',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '8th Jan',
    day: 'Sunday',
    project: 'Project 8',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '9th Jan',
    day: 'Monday',
    project: 'Project 9',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '10th Jan',
    day: 'Tuesday',
    project: 'Project 10',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '11th Jan',
    day: 'Wednesday',
    project: 'Project 11',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '12th Jan',
    day: 'Thursday',
    project: 'Project 12',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '13th Jan',
    day: 'Friday',
    project: 'Project 13',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '14th Jan',
    day: 'Saturday',
    project: 'Project 14',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '15th Jan',
    day: 'Sunday',
    project: 'Project 15',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '16th Jan',
    day: 'Monday',
    project: 'Project 16',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '1st Jan',
    day: 'Sunday',
    project: 'Project 1',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '2nd Jan',
    day: 'Monday',
    project: 'Project 2',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '3rd Jan',
    day: 'Tuesday',
    project: 'Project 3',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '4th Jan',
    day: 'Wednesday',
    project: 'Project 4',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '5th Jan',
    day: 'Thursday',
    project: 'Project 5',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '6th Jan',
    day: 'Friday',
    project: 'Project 6',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '7th Jan',
    day: 'Saturday',
    project: 'Project 7',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '8th Jan',
    day: 'Sunday',
    project: 'Project 8',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '9th Jan',
    day: 'Monday',
    project: 'Project 9',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '10th Jan',
    day: 'Tuesday',
    project: 'Project 10',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '11th Jan',
    day: 'Wednesday',
    project: 'Project 11',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '12th Jan',
    day: 'Thursday',
    project: 'Project 12',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '13th Jan',
    day: 'Friday',
    project: 'Project 13',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '14th Jan',
    day: 'Saturday',
    project: 'Project 14',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '15th Jan',
    day: 'Sunday',
    project: 'Project 15',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '16th Jan',
    day: 'Monday',
    project: 'Project 16',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '1st Jan',
    day: 'Sunday',
    project: 'Project 1',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '2nd Jan',
    day: 'Monday',
    project: 'Project 2',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '3rd Jan',
    day: 'Tuesday',
    project: 'Project 3',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '4th Jan',
    day: 'Wednesday',
    project: 'Project 4',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '5th Jan',
    day: 'Thursday',
    project: 'Project 5',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '6th Jan',
    day: 'Friday',
    project: 'Project 6',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '7th Jan',
    day: 'Saturday',
    project: 'Project 7',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '8th Jan',
    day: 'Sunday',
    project: 'Project 8',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '9th Jan',
    day: 'Monday',
    project: 'Project 9',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '10th Jan',
    day: 'Tuesday',
    project: 'Project 10',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '11th Jan',
    day: 'Wednesday',
    project: 'Project 11',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '12th Jan',
    day: 'Thursday',
    project: 'Project 12',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '13th Jan',
    day: 'Friday',
    project: 'Project 13',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '14th Jan',
    day: 'Saturday',
    project: 'Project 14',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '15th Jan',
    day: 'Sunday',
    project: 'Project 15',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '16th Jan',
    day: 'Monday',
    project: 'Project 16',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '1st Jan',
    day: 'Sunday',
    project: 'Project 1',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '2nd Jan',
    day: 'Monday',
    project: 'Project 2',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '3rd Jan',
    day: 'Tuesday',
    project: 'Project 3',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '4th Jan',
    day: 'Wednesday',
    project: 'Project 4',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '5th Jan',
    day: 'Thursday',
    project: 'Project 5',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '6th Jan',
    day: 'Friday',
    project: 'Project 6',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '7th Jan',
    day: 'Saturday',
    project: 'Project 7',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '8th Jan',
    day: 'Sunday',
    project: 'Project 8',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '9th Jan',
    day: 'Monday',
    project: 'Project 9',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '10th Jan',
    day: 'Tuesday',
    project: 'Project 10',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '11th Jan',
    day: 'Wednesday',
    project: 'Project 11',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '12th Jan',
    day: 'Thursday',
    project: 'Project 12',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '13th Jan',
    day: 'Friday',
    project: 'Project 13',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '14th Jan',
    day: 'Saturday',
    project: 'Project 14',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
  {
    date: '15th Jan',
    day: 'Sunday',
    project: 'Project 15',
    clockIn: '09:00 AM',
    clockOut: '06:00 PM',
    total: '9hr',
    swipeHistory: [
      {
        clockIn: '09:00 AM',
        clockOut: '11:00 AM',
      },
      {
        clockIn: '11:15 AM',
        clockOut: '01:00 PM',
      },
      {
        clockIn: '02:00 PM',
        clockOut: '06:00 PM',
      },
    ],
  },
  {
    date: '16th Jan',
    day: 'Monday',
    project: 'Project 16',
    clockIn: '09:00 AM',
    clockOut: '05:00 PM',
    total: '8hr',
    swipeHistory: [
      {
        clockIn: '10:00 AM',
        clockOut: '10:10 AM',
      },
      {
        clockIn: '10:15 AM',
        clockOut: '02:00 PM',
      },
      {
        clockIn: '03:00 PM',
        clockOut: '07:00 PM',
      },
    ],
  },
];

export default rows;
