// types.ts
export enum StatusConstants {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  REJECTED = 'REJECTED',
}

export enum ButtonConstants {
  APPROVE = 'Approve',
  REJECT = 'Reject',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export interface HostDetails {
  name: string;
  mobile: string;
}

export interface VisitorModel {
  id: any;
  name: any;
  email: any;
  photoUrl: any;
  scheduleVisitTime: any;
  purpose: any;
  status: StatusConstants;
  hostName: any;
  checkin: any;
  checkout: any;
}

export interface State {
  items: Record<any, VisitorModel>;
}

export const SET_ITEMS = 'SET_ITEMS';
export const UPDATE_ITEM = 'UPDATE_ITEM';

interface SetVisitorModelAction {
  type: typeof SET_ITEMS;
  payload: { items: Record<any, VisitorModel> };
}

interface UpdateVisitorModelAction {
  type: typeof UPDATE_ITEM;
  payload: { id: any; data: Partial<VisitorModel> };
}

export type ActionTypes = SetVisitorModelAction | UpdateVisitorModelAction;
