import { createTheme, Theme } from '@mui/material';

const primary = '#025e5e';
const primaryLight = '#95CBF7';
const secondary = '#fff';
const error = '#f17b7b';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module '@mui/system' {
  interface DefaultTheme extends Theme {}
}
/* eslint-disable @typescript-eslint/no-empty-interface */

export const INITIAL_THEME = createTheme({
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       borderRadius: SIZE[9],
  //     },
  //   },
  //   MuiTab: {
  //     root: {
  //       textTransform: "capitalize",
  //     },
  //   },
  //   MuiOutlinedInput: {
  //     notchedOutline: {
  //       borderColor: primary,
  //       borderRadius: SIZE[9],
  //     },
  //   },
  //   MuiInputAdornment: {
  //     root: {
  //       color: primary,
  //       borderRight: "1px solid #ccc",
  //       paddingRight: "10px",
  //     },
  //   },
  //   MuiCheckbox: {
  //     root: {
  //       color: primary,
  //     },
  //   },
  // },
  palette: {
    primary: {
      main: primary,
      light: primaryLight,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: error,
    },
  },
});
