import React from 'react';
import './index.scss';
import KeycloakWrapper from './KeycloakWrapper';

const App = (props) => {
  const { config } = props;

  return (
    <div className="app-wrapper">
      <KeycloakWrapper config={config} />
    </div>
  );
};

export default App;
