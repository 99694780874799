import {
  TEMPLATE_UPLOAD,
  TEMPLATE_DOWNLOAD,
  DELETE_DOCUMENT,
} from '../constants/endpoints';
import { ResponseProps, request } from './request';
import { MetaInfo } from '../types/types';
import axios from 'axios';
import { getSessionStorageItem } from '../encrypt';

export const uploadFileApi = async (
  fileName: string,
  file: File,
  documentPath: string,
  meta?: MetaInfo
): Promise<{ success: boolean; message?: string; data?: any }> => {
  const blob = new Blob([file]);
  const fileOfBlob = new File([blob], `${fileName}`);
  const params = {
    documentName: fileName,
    file: fileOfBlob,
    documentPath: documentPath,
    documentDescription: '',
    metaInfo: JSON.stringify(meta),
  };

  const r: ResponseProps = (await request.postForm(
    `${getSessionStorageItem('gatewayURL')}${TEMPLATE_UPLOAD}`,
    params
  )) as ResponseProps;
  if (r && r.success) {
    return { success: true, data: r.data, message: r.message };
  }
  return { success: false, data: {}, message: '' };
};

export const downloadTemplateApi = async (
  id: string
): Promise<{ success: boolean; message?: string; blobFile?: any }> => {
  const response = (await request.getBlob(
    `${getSessionStorageItem('gatewayURL')}${TEMPLATE_DOWNLOAD}/${id}`
  )) as ResponseProps;

  if (response && response.success) {
    return { success: true, blobFile: response.data };
  }

  return { success: false };
};

export const deleteDMSDocument = async (id: string) => {
  const apiEndpoint = `${getSessionStorageItem(
    'gatewayURL'
  )}${DELETE_DOCUMENT}${id}`;
  axios
    .delete(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${getSessionStorageItem('react-token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};
