import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import QuickViewModel from './QuickViewModel';

interface QuickViewProps {
  show: any;
  setShow: any;
  userData: any;
}

const useStyles = makeStyles(() => ({
  modalRoot: {
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    width: '80vw',
    borderRadius: '18px',
    backgroundColor: '#E3E7EF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box',
  },
  modalInnerContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
}));

const QuickView = ({ show, setShow, userData }: QuickViewProps) => {
  const classes = useStyles();
  const [isEmailReady, setIsEmailReady] = useState(false);
  useEffect(() => {
    setIsEmailReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.officialEmail]);

  return (
    <Modal
      open={isEmailReady && show}
      onClose={() => {
        setShow(false);
      }}
    >
      <div className={`${classes.modalRoot} modal-container`}>
        <Box className={classes.modalInnerContainer}>
          <QuickViewModel userData={userData} setModalShow={setShow} />
        </Box>
      </div>
    </Modal>
  );
};

export default QuickView;
